import { SucursalModel } from "../types";
import { BaseService } from "./BaseService";


export class SucursalServices extends BaseService {

    /**
     * @param { string } token - Auth token for getting access to API Backend.
     */
    constructor(token: string) {
        super(token, "/sucursal")
    }

    /**
     * Get a list of all sucursales.
     * 
     * @returns { Promise<Array<SucursalModel>> } - List of SucursalModel.
     */
    public async getSucursales(): Promise<Array<SucursalModel>> {
        const response = await this.axios.get<Array<SucursalModel>>("");
        const { data } = response;
        return data;
    }

    /**
     * Get a sucursal by its id.
     * 
     * @param { string | undefined } id - Sucursal's id.
     * @returns - A sucursal data 
     */
    public async getSucursalById(id: string | undefined): Promise<SucursalModel> {
        const response = await this.axios.get<SucursalModel>(`/${id}`)
        const { data } = response;
        return data;
    }

    /**
     * Save a new sucursal in database.
     * 
     * @param { SucursalModel } data - New sucursal data. 
     * @returns 
     */
    public async saveSucursal(data: SucursalModel) {
        return await this.axios.post("", {
            smalldir: data.smallDir, 
            direccion: data.direccion 
        });
    }

    /**
     * Update a sucursal.
     * 
     * @param { string | undefined } id - Sucursal's id.
     * @param { SucursalModel } sucursalUpdate - New sucursal data.
     * @returns 
     */
    public async upadteSucursal(id: string | undefined, sucursalUpdate: SucursalModel) {
        return await this.axios.put(`/${id}`, sucursalUpdate);
    }

    /**
     * Delete a sucursal.
     * 
     * @param { string | undefined } id - Sucursal's id. 
     * @returns 
     */
    public async deleteSucursal(id: string | undefined) {
        return await this.axios.delete(`/${id}`);
    }
}