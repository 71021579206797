import { MantenimientoHomeConsulta } from "./MantenimientoHomeConsulta";


export function MantenimientoConsultas() {
    return (
        <div>
            <h2 className="text-3xl my-4 font-semibold">Consulta de Mantenimientos</h2>
            <MantenimientoHomeConsulta 
                filtrosShowed={{
                    fechas: true, 
                    categoria: true,
                    estado: false,
                    sucursal: true,
                    responsable: true
                }}/>
        </div>
    );
}