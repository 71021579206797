import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { useUser } from "../../../hooks/useUser";
import { CategoryModel, EquipoModel, FiltrosMetricsModel, SubcategoryModel, SucursalModel, UsuarioModel } from "../../../types";
import { CategoriaServices, EquipoServices, SubcategoryServices, SucursalServices, UserServices } from "../../../services";

interface Props {
    filtros: FiltrosMetricsModel
    setFiltros: Dispatch<SetStateAction<FiltrosMetricsModel>>
}

export function FiltrosMantenimiento({ filtros, setFiltros }: Props) {
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const categoriaService = new CategoriaServices(token);
    const equipoService = new EquipoServices(token);
    const subcategoryService = new SubcategoryServices(token);
    const sucursalService = new SucursalServices(token);
    const userService = new UserServices(token);

    const [sucursales, setSucursales] = useState<Array<SucursalModel>>([]); 
    const [categories, setCategoires] = useState<Array<CategoryModel>>([]);
    const [subcategories, setSubcategories] = useState<Array<SubcategoryModel>>([]); 
    const [responsables, setResponsables] = useState<Array<UsuarioModel>>([]);
    const [equipos, setEquipo] = useState<Array<EquipoModel>>([]);

    const [categorySelected, setCategorySelected] = useState<number | null>(null);
    const [subcategorySelected, setSubcategorySelected] = useState<number | null>(null);

    const onFilterChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFiltros({
            ...filtros,
            [event.target.name]: event.target.value
        });

        if(event.target.name === "sucursalId"){
            setFiltros({
                ...filtros,
                sucursalId: parseInt(event.target.value),
                categoriaId: 0,
                subcategoriaId: 0,
                equipoId: 0,
            });
        }

        if(event.target.name === "categoriaId"){
            setCategorySelected(parseInt(event.target.value));
            setFiltros({
                ...filtros,
                categoriaId: parseInt(event.target.value),
                subcategoriaId: 0,
                equipoId: 0,
            });
        }

        if(event.target.name === "subcategoriaId") {
            setSubcategorySelected(parseInt(event.target.value));
            setFiltros({
                ...filtros,
                subcategoriaId: parseInt(event.target.value),
                equipoId: 0,
            });
        }
    }

    // subcategoria y equipo dependiend 
    let SubcategorySelected: Array<SubcategoryModel> | undefined;
    if(categorySelected != null){
        SubcategorySelected = subcategories.filter((subcategory) => subcategory.categoriaId === categorySelected);
    }

    useEffect(() => {
        if(subcategorySelected != null) {
            equipoService.getEquipos(filtros.sucursalId, filtros.subcategoriaId)
                .then(data => setEquipo(data))
        }
    }, [filtros]);

    useEffect(() => {
        categoriaService.getCategories()
            .then(data => setCategoires(data));

        sucursalService.getSucursales()
            .then(data => setSucursales(data));

        subcategoryService.getSubcategories()
            .then(data => setSubcategories(data));

        userService.getUsersByRole("Mantenimiento")
            .then(data => setResponsables(data));
    }, []);

    return (
        <div>
            <form className="grid grid-cols-4 w-full gap-y-3 sm:gap-y-2 gap-x-3 text-left">

                <div className="col-span-2 flex flex-col sm:flex-row">
                    <label className="w-full sm:w-1/3" htmlFor="fechaInicio">Fecha inicial:</label>
                    <input 
                        className="inputdate w-11/12 sm:w-2/3"
                        id="fechaInicio"
                        type="date"
                        name="fechaInicial"
                        onChange={onFilterChange}
                        value={filtros.fechaInicial}
                        color="primary"/>
                </div>

                <div className="col-span-2 flex flex-col sm:flex-row">
                    <label className="w-full sm:w-1/3" htmlFor="fechaFinal">Fecha final:</label>
                    <input 
                        className="inputdate w-11/12 sm:w-2/3"
                        id="fechaFinal"
                        type="date"
                        onChange={onFilterChange}
                        value={filtros.fechaFinal}
                        name="fechaFinal"
                        color="primary"/>
                </div>

                <div className="col-span-2 flex flex-col sm:flex-row">
                    <label className="w-full sm:w-1/3">Sucursal:</label>
                    <select className="selectfilter w-11/12 sm:w-2/3"
                            name="sucursalId"
                            onChange={onFilterChange}
                            value={filtros.sucursalId}
                            >
                            <option value={0} defaultChecked>Todos las sucursales</option>
                            {userContext.auth?.user?.tipoUsuario === "General" 
                                ? (
                                <option value={userContext.auth.user.sucursalId}>{userContext.auth.user.sucursalNombre}</option>
                                )
                                :sucursales.map((sucursal, index) => (
                                <option key={index} value={sucursal.id}>{sucursal.smallDir}</option>
                                ))
                            }
                    </select>
                </div>

                <div className="col-span-2 flex flex-col sm:flex-row">
                    <label className="w-full sm:w-1/3">Equipo:</label>
                    <select className="selectfilter w-11/12 sm:w-2/3"
                            name="equipoId"
                            onChange={onFilterChange}
                            value={filtros.equipoId}
                            >
                            <option value={0} defaultChecked>Todas los equipos</option>
                            {equipos.map((equipo, index) => (
                                <option key={index} value={equipo.id}>{equipo.nombre}</option>
                            ))}
                    </select>
                </div>

                <div className="col-span-2 flex flex-col sm:flex-row">
                    <label className="w-full sm:w-1/3">Categoria:</label>
                    <select className="selectfilter w-11/12 sm:w-2/3"
                            name="categoriaId"
                            onChange={onFilterChange}
                            value={filtros.categoriaId}
                            >
                            <option value={0} defaultChecked>Todas las categorias</option>
                            {categories.map((category, index) => (
                                <option key={index} value={category.id}>{category.nombreCategoria}</option>
                            ))}
                    </select>
                </div>

                <div className="col-span-2 flex flex-col sm:flex-row">
                    <label className="w-full sm:w-1/3">Responsable:</label>
                    <select className="selectfilter w-11/12 sm:w-2/3"
                            name="responsableId"
                            onChange={onFilterChange}
                            >
                            <option value={0} defaultChecked>Todas los responsables</option>
                            {responsables.map((user, index) => (
                                <option key={index} value={user.id}>{user.nombre}</option>
                            ))}
                    </select>
                </div>

                <div className="col-span-2 flex flex-col sm:flex-row">
                    <label className="w-full sm:w-1/3">Subcategoría:</label>
                    <select className="selectfilter w-11/12 sm:w-2/3"
                            name="subcategoriaId"
                            onChange={onFilterChange}
                            value={filtros.subcategoriaId}
                            >
                            <option value={0} defaultChecked>Todas las subcategoras</option>
                            {SubcategorySelected?.map((subcategory, index) => (
                                <option key={index} value={subcategory.id}>{subcategory.nombreSubcategoria}</option>
                            ))}
                    </select>
                </div>

            </form>
        </div>
    );
}