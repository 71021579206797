import { Button } from "@mui/joy";
import { Dispatch, Fragment, SetStateAction, useState } from "react";
import { EquipoRevision } from "../../types/checklist";
import { useUser } from "../../hooks/useUser";
import { CreateTicket } from "../../types/ticket";
import { TicketServices } from "../../services";
import { RowCreateTicketFromChecklist } from "./RowCreateTicketFromChecklist";
import { ErrorMessage, LoadingRequest } from "../UtilsComponents";
import { isAxiosError } from "axios";
import { ErrorRequest } from "../../types";

interface Props {
    openModal: boolean;
    setOpenModal: Dispatch<SetStateAction<boolean>>;
    equiposConProblemas: Array<EquipoRevision[]>
    setEquiposConProblemas: Dispatch<SetStateAction<EquipoRevision[][]>>;
    saveRevisionFunction(): Promise<void>;
}

export function CreateTicketFromChecklist({openModal, setOpenModal, equiposConProblemas, setEquiposConProblemas, saveRevisionFunction}: Props) {
    const [ticketToCreate, setTicketToCreate] = useState<CreateTicket[]>([]);
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

   const ticketService = new TicketServices(token); 

    const finishRevision = async () => {
        try {
            setSaveLoading(true);
            await ticketService.bulkSaveTickets(ticketToCreate);
            await saveRevisionFunction();

            setSaveLoading(false);
            setOpenModal(false);

        } catch(error) {
            setSaveLoading(false);
            setHasError(true);

            if(isAxiosError<ErrorRequest, Record<string, unknown>>(error)) {
                const message = error.response?.status == 500 
                    ? "Intente más tarde."
                    : error.response?.data.problemDetails.error[0].description;
                setErrorMessage(message ?? "No message");
            }
        }
    }

    return (
        <div className="w-3/5 h-4/5 bg-white rounded-md transition duration-300">
            <div className="m-5 h-full">

                <p className="text-xl my-4 font-semibold text-center">Se encontraron revisiones con problemas</p>
                <p className="font-medium my-4">Seleccione los equipos que necesitan un ticket de mantenimiento:</p>

                <div className="max-h-full h-4/6 overflow-auto">
                    {equiposConProblemas.map((equipo, index) => (
                        <Fragment key={index}>
                            {equipo.map((revision, index) => (
                                <Fragment key={index}>
                                    <RowCreateTicketFromChecklist
                                        sucursalId={userContext.auth?.user?.sucursalId ?? 0}
                                        informadorId={userContext.auth?.user?.id ?? 0}
                                        setTicketToCreate={setTicketToCreate}
                                        ticketToCreate={ticketToCreate}
                                        revision={revision}/>
                                </Fragment>
                            ))}
                        </Fragment>
                    ))
                    }
                </div>

                <div className="h-[42px] my-2">
                    {hasError && (
                        <ErrorMessage 
                            errorMessage={errorMessage}/>
                    )}
                </div>

                <div className="flex justify-center gap-2 my-4">
                    {saveLoading
                    ? <LoadingRequest />
                    :
                    <Button 
                        size="sm"
                        color="success"
                        className="w-20"
                        onClick={finishRevision}
                        >Terminar Revision</Button>
                    }

                    <Button 
                        size="sm"
                        color="neutral"
                        className="w-20"
                        onClick={() => setOpenModal(!openModal)}>
                        Cerrar
                    </Button>
                </div>

            </div>

        </div>
    );
}
