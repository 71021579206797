import { SolicitudHome } from "./SolicitudHome";
import { Link } from "react-router-dom";
import { Button } from "@mui/joy";
import CreateIcon from '@mui/icons-material/Create';
import { useUser } from "../../hooks/useUser";
import { ShowFiltros } from "../../types";

export function SolicitudDashboard(){
    const { userContext } = useUser();

    const showFilters: ShowFiltros = {
        fechas: true,
        categoria: true,
        estado: true,
        sucursal: true,
        urgencia: true,
        responsable: true,
        checklist: false,
    }

    return (
        <div className="w-full">
            <h2 className="text-3xl my-4 font-semibold">Dashboard - Compras</h2>
            { userContext.auth?.user?.tipoUsuario !== "Compras" 
                ? <Link to={"/compras/create"}>
                    <Button startDecorator={<CreateIcon />} 
                            size='sm'
                            color='success'
                            variant='solid'>
                            Crear Solicitud de Compra
                    </Button>
                </Link>
                : <div className="h-[28px] py-[2px]"></div>
            }
            <SolicitudHome
                titulo={true}
                onlyNonTerminados={true}
                filtrosShowed={showFilters}/>
        </div>
    );
}