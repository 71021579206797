import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button } from "@mui/joy";
import { SucursalModel} from "../../types";
import { SucursalServices } from "../../services";
import { useUser } from "../../hooks/useUser";
import { LoadingRequest, ErrorMessage } from "../UtilsComponents";
import { errorParser } from "../../utilities";


export function SucursalCreateForm() {
    const navigate = useNavigate();

    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [requestLoading, setRequestLoading] = useState<boolean>(false);

    const sucursalService = new SucursalServices(token);

    const { 
        register, 
        handleSubmit,
        formState: { errors } 
    } = useForm<SucursalModel>();

    const saveSucursal = handleSubmit(async (data: SucursalModel) => {
        try {
            setHasError(false);
            setRequestLoading(true);

            const response = await sucursalService.saveSucursal(data)

            if(response.status === 201) {
                setRequestLoading(false);
                navigate("/sucursales")
            }

        } catch(error) {
            const message = errorParser(error);
            setRequestLoading(false);
            setErrorMessage(message);
            setHasError(true);
        }
    });

    return (
        <div>
            <h2 className="text-3xl my-4 font-semibold">Nueva Sucursal</h2>
            <form className="grid grid-cols-2"
                  onSubmit={saveSucursal}>
                
                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Nombre de la sucursal</label>
                    <input className="inputform"
                        {...register("smallDir", {
                                required: "Nombre de sucursal es requerido",
                                maxLength: 40
                        })}
                        aria-invalid={errors.smallDir ? "true" : "false"}
                        placeholder="Sucursal"/>
                    {errors.smallDir?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.smallDir.message}</p>
                    )}
                    {errors.smallDir?.type === "maxLength" && (
                        <p className="col-start-2 valErrorText" role="alert">Maximo numero de caracteres es 40</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Direccion</label>
                    <input className="h-7 my-2 border font-normal text-cyan-800 border-cyan-500 hover:border-cyan-800 hover:border-2 rounded-lg bg-transparent px-2 focus:outline-none focus:border-cyan-800 focus:ring-cyan-800"
                        {...register("direccion", {
                            required: "La direccion es requerida",
                            maxLength: 100
                        })}
                        placeholder="Direccion"
                        name="direccion"/>
                    {errors.direccion?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.direccion.message}</p>
                    )}
                    {errors.direccion?.type === "maxLength" && (
                        <p className="col-start-2 valErrorText" role="alert">Maximo numero de caracteres es 100</p>
                    )}
                </div>

                <div className="flex justify-end col-span-2 m-2 gap-2">
                    {requestLoading 
                    ? <LoadingRequest />
                    : <Button size="sm" color="success" className="w-20" type="submit">Registrar</Button>
                    }
                    <Link to="/sucursales">
                        <Button size="sm" color="neutral" className="w-20 mx-2">Cancelar</Button>
                    </Link>
                </div>

                {hasError && (
                    <ErrorMessage
                        errorMessage={errorMessage} />
                )}

            </form>
        </div>
    );
}