import { Table } from "@mui/joy";
import { CodePrefixResponse } from "../../../types";
import { useEffect, useState } from "react";
import { EditButton } from "../../../utilities/UtilsComponents/EditButton";
import { useUser } from "../../../hooks/useUser";
import { EquipoServices } from "../../../services/equipos";
import { AddButon } from "../../../utilities/UtilsComponents/AddButon";
import { LoadingComponent } from "../../UtilsComponents/LoadingComponent";
import { SearchFilter } from "../../../utilities/UtilsComponents/SearchFilter";
import { FiltroServices } from "../../../services";


export function CodeEquipoHome() {

    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const equipoService = new EquipoServices(token);

    const [codeList, setCodeList] = useState<Array<CodePrefixResponse>>([])
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [search, setSearch] = useState<string>("")

    useEffect(() => {
        equipoService.getCodePrefixList()
            .then(data => {
                setCodeList(data);
                setDataLoading(false);
            });
    }, []);

    const codeListFiltered = FiltroServices.filtrosCodeEquipos(codeList, search);

    return (
        <div>
            <h2 className="text-3xl my-4 font-semibold">Códigos de los Equipos</h2>

            <AddButon url="/equipos/codes/create"/>

            <SearchFilter
                searchValue={search}
                setSearchValue={setSearch}/>

            {dataLoading
            ? <LoadingComponent />
            :
            <div className="overflow-auto max-h-[600px] rounded-lg border border-slate-200 my-4">
                <Table
                    stickyHeader
                    sx={(theme) => ({
                        "th": {bgcolor: "#111827", color: "#ffffff"}
                    })}>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Prefijo</th>
                            <th>Significado</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {codeListFiltered.map((code, index) => (
                            <tr key={index} className="hover:bg-slate-100">
                                <td>{code.id}</td>
                                <td>{code.prefijo}</td>
                                <td>{code.significado}</td>
                                <td>
                                    <EditButton url={`/equipos/codes/${code.id}`} previousUrl={""}/>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            }
        </div>
    );
}