import { ChangeEvent, Dispatch, MouseEvent, SetStateAction, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ComentarioServices, TicketServices } from "../../services";
//import { updateTicketState } from "../../services/tickets";
import { useUser } from "../../hooks/useUser";

interface Props {
    cancelar: boolean
    setCancelar: Dispatch<SetStateAction<boolean>>
}

export function RechazarTicket({cancelar, setCancelar }: Props) {
    const { id } = useParams();
    const navigate = useNavigate();
    const [comentario, setComentario] = useState<string>("");
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const comentarioService = new ComentarioServices(token);
    const ticketService = new TicketServices(token);

    const onChangeComentario = (event: ChangeEvent<HTMLInputElement>) => {
        setComentario(event.target.value);
    }

    const onSubmitRechazar = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        ticketService.updateTicketState(id, "En Proceso")
            .then(response => {
                if(response.status === 200){
                    comentarioService.saveComentarioInTicket({
                        id: id,
                        texto: comentario,
                        usuarioId: userContext.auth?.user?.id ?? 0
                    })
                    .then(response => {
                        if(response.status === 201)
                            navigate("/");
                    })
                    .catch(error => console.error(error));
                }
            })
            .catch(error => console.error(error));
   }

    return (
        <div className="p-4 w-96 border rounded-md shadow-md bg-white">

            <div className="grid grid-cols-2" >
                <p className="mb-2 font-medium col-span-2">Para Rechazar el ticket debe escribir el por qué:</p>
                <input required={true}
                        minLength={0}
                        min={0}
                        value={comentario}
                        onChange={onChangeComentario}
                        className="border border-gray-900 rounded-md col-span-2"
                        type="text"/>

                <div className="flex justify-end col-span-2 m-2 gap-2">
                    <button className="py-1 text-sm font-medium bg-red-700 text-gray-50 w-20 rounded-md disabled:bg-red-300"
                            onClick={onSubmitRechazar}
                            disabled={comentario?.length <= 0}
                            >
                            Rechazar
                    </button>
                    <button className="py-1 text-sm font-medium bg-gray-700 text-gray-50 w-20 rounded-md"
                            onClick={() => setCancelar(!cancelar)}>Cancelar</button>
                </div>
            </div>

        </div>
    );
}