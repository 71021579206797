import { useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { EquipoModel, SubcategoryModel, SucursalModel } from "../../types";
import { Button } from "@mui/joy";
import { useUser } from "../../hooks/useUser";
import { CodeInput } from "./CodeInput";
import { ROLES } from "../../utilities/StateEnums";
import { EquipoServices, SubcategoryServices, SucursalServices } from "../../services";
import { ModalContainer } from "../UtilsComponents/ModalContainer";
import { ChangeEquipoStatus } from "./ChangeEquipoStatus";
import { EquipoCodeParser } from "../../utilities/equipoCodeParser";
import { LoadingScreen } from "../UtilsComponents/LoadingScreen";
import { LoadingRequest, ErrorMessage } from "../UtilsComponents";
import { errorParser } from "../../utilities";

export function EquipoDetail() {
    const navigate = useNavigate();
    const { id } = useParams(); 
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";
    
    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [requestLoading, setRequestLoading] = useState<boolean>(false);

    const [subcategories, setSubcategories] = useState<Array<SubcategoryModel>>([]);
    const [sucursales, setSucursales] = useState<Array<SucursalModel>>([]);
    const [showChangeStatusEquipo, setShowChangeStatusEquipo] = useState(false);
    const [dataLoading, setDataLoading] = useState<boolean>(true)

    const equipoService = new EquipoServices(token);
    const subcategoryService = new SubcategoryServices(token);
    const sucursalService = new SucursalServices(token);

    const { register,
            watch,
            handleSubmit,
            setValue,
            formState: { 
                errors,
                isDirty,
            }
        } = useForm<EquipoModel>({
        defaultValues:  async () => {
            const equipos = await equipoService.getEquipoById(id);

            const subcategories = subcategoryService.getSubcategories()
                .then(data => setSubcategories(data));
            
            const sucursales = sucursalService.getSucursales()
                .then(data => setSucursales(data));

            Promise.all([equipos, subcategories, sucursales])
                .then(_ => setDataLoading(false));

            return equipos;
        }
    });

    const estado = watch("activo", "Activo")
    const activo = estado === "Activo";
    const code = watch("codigo", "");

    const oldEquipoCode = EquipoCodeParser.deserializate(code ?? null)
    const [prefix, setPrefix] = useState<string>(oldEquipoCode?.prefix ?? ""); 
    const [number, setNumber] = useState<string>(oldEquipoCode?.number ?? "");

    const submitUpdate = handleSubmit(async (data: EquipoModel) => {
        try {
            setHasError(false);
            setRequestLoading(true);

            const response = await equipoService.updateEquipo({
                id: data.id,
                nombre: data.nombre,
                numeroSerie: data.numeroSerie,
                departamento: data.departamento,
                subcategoriaId: data.subcategoria.id,
                sucursalId: data.sucursal.id,
                frecuenciaMan: data.frecuenciaMan,
                ultimoMan: data.ultimoMan,
                codigo: data.codigo
            })
            if(response.status === 200) {
                setRequestLoading(false);
                navigate("/equipos");
            }
        } catch(error) {
            const message = errorParser(error);
            setRequestLoading(false);
            setErrorMessage(message);
            setHasError(true);

        }
    })

    const submitDeleteEquipo = async (id: string | undefined) => {
        if(id === undefined)
            return;
        equipoService.deleteEquipo(parseInt(id))
            .then(response =>  {
                if(response.status === 204)
                    navigate("/equipos");
            })
            .catch(error => console.error(error));
    }

    const reactivateEquipo = async (id: string | undefined) => {
        if(id === undefined)
            return;

        equipoService.reactivateEquipo(parseInt(id))
            .then(response => {
                if(response.status === 200) {
                    navigate("/equipos");
                }
            })
            .catch(error => console.error(error));
    }

    return (
        <>
        {dataLoading
        ? <LoadingScreen />
        :
        <div >
            <h2 className="text-3xl my-4 font-semibold">Detalles {!activo && (<span className="text-red-400">-Equipo Desactivado-</span>)}</h2>
            <form onSubmit={submitUpdate} className="grid grid-cols-2" autoComplete="off">

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Nombre</label>
                    <input {...register("nombre", {
                            required: "El equipo debe tener un nombre",
                            maxLength: 50
                        })}
                        aria-invalid={errors.nombre ? "true" : "false"}
                        className="inputform"
                        disabled={!activo}
                        placeholder="Nombre"/>
                    {errors.nombre?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.nombre.message}</p>
                    )}
                    {errors.nombre?.type === "maxLength" && (
                        <p className="col-start-2 valErrorText" role="alert">Maximo numero de caracteres es 50</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Numero de Serie</label>
                    <input {...register("numeroSerie", {
                                required: "El equipo debe tener un numero de serie",
                                maxLength: 100
                            })}
                        aria-invalid={errors.numeroSerie ? "true" : "false"}
                        className="inputform"
                        disabled={!activo}
                        placeholder="Numero de Serie"/>
                    {errors.numeroSerie?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.numeroSerie.message}</p>
                    )}
                    {errors.numeroSerie?.type === "maxLength" && (
                        <p className="col-start-2 valErrorText" role="alert">Maximo numero de caracteres es 100</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Código</label>
                    <input {...register("codigo", {
                                required: "El equipo debe tener un código único de identificación",
                                maxLength: 15
                            })}
                        aria-invalid={errors.codigo ? "true" : "false"}
                        className="inputform"
                        disabled
                        placeholder="Código de Identificación"/>
                    {errors.codigo?.type === "required" && (
                        <p className="valErrorText col-start-2" role="alert">{errors.codigo.message}</p>
                    )}
                    {errors.codigo?.type === "maxLength" && (
                        <p className="valErrorText col-start-2" role="alert">Maximo numero de caracteres es 15</p>
                    )}
                </div>

                {activo && (
                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Código Nuevo</label>
                    <CodeInput
                        setValue={setValue}
                        prefix={prefix}
                        number={number}/>
                </div>
                )}

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label id="suc_id" className="font-medium">Sucursal</label>
                    <select {...register("sucursal.id", {
                                required: "Debe seleccionar una sucursal"
                            })}
                            aria-invalid={errors.sucursal?.id ? "true" : "false"}
                            className="selectform"
                            disabled={!activo}
                            value={watch("sucursal.id")}
                            id="suc_id">
                        {sucursales.map((sucursal, index) => (
                            <option key={index} value={sucursal.id}>{sucursal.smallDir}</option>
                        ))}
                    </select>
                    {errors.sucursal?.id?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.sucursal.id.message}</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" id="subcat_id">Categoria</label>
                    <input {...register("nombreCategoria")}
                        className="inputform"
                        disabled={true}
                        placeholder="Código de Identificación"/>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label id="subcat_id" className="font-medium">Subcategoria</label>
                    <select {...register("subcategoria.id", {
                                required: "Debe seleccionar una subcategoría"
                            })}
                            aria-invalid={errors.subcategoria?.id ? "true" : "false"}
                            className="selectform"
                            disabled={!activo}
                            value={watch("subcategoria.id")}
                            id="subcat_id">
                        {subcategories.map((subcategory, index) => {
                            return (
                                <option key={index} value={subcategory.id}>{subcategory.nombreSubcategoria}</option>
                                )
                            })}
                    </select>
                    {errors.subcategoria?.id?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.subcategoria.id.message}</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Departamento</label>
                    <input {...register("departamento", {
                            required: "El equipo debe tener un departamento",
                            maxLength: 30
                        })}
                        aria-invalid={errors.departamento ? "true" : "false"}
                        disabled={!activo}
                        className="inputform"
                        placeholder="Departamento"/>
                    {errors.departamento?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.departamento.message}</p>
                    )}
                    {errors.departamento?.type === "maxLength" && (
                        <p className="col-start-2 valErrorText" role="alert">Maximo numero de caracteres es 30</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Estado:</label>
                    <input {...register("activo")}
                        type="text"
                        disabled={true}
                        className="inputform" />
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Frecuencia de Mantenimiento</label>
                    <input {...register("frecuenciaMan", {
                                //required: "El equipo debe tener un numero de serie",
                                maxLength: 100
                            })}
                        type="number"
                        aria-invalid={errors.frecuenciaMan ? "true" : "false"}
                        disabled={!activo}
                        className="inputform"
                        placeholder="Frecuencia de mantenimiento"/>
                    {errors.frecuenciaMan?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{"Campo Requerido"}</p>
                    )}
                    {errors.frecuenciaMan?.type === "maxLength" && (
                        <p className="col-start-2 valErrorText" role="alert">Maximo numero de caracteres es 100</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Fecha del Ultimo Mantenimiento</label>
                    <input {...register("ultimoMan")}
                        type="date"
                        disabled={!activo}
                        className="inputform" />
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Fecha del Proximo Mantenimento</label>
                    <input {...register("proximoMan")}
                        type="date"
                        disabled={true}
                        className="inputform"/>
                </div>

                <div className="flex justify-end col-span-2 m-2 gap-2">
                    {activo && (
                        <>
                        {requestLoading
                        ? <LoadingRequest />
                        : <Button 
                            size="sm" 
                            color="success" 
                            className="w-20" 
                            disabled={!isDirty}
                            type="submit">
                            Actualizar
                        </Button>
                        }
                        </>
                    )}

                    {[ROLES.ADMIN, ROLES.RESPONSABLE_MAN]
                        .find( role => role === userContext.auth?.user?.tipoUsuario) && (
                            <>
                            {activo 
                            ? <Button 
                                size="sm" 
                                color="danger" 
                                className="w-20 mx-2" 
                                onClick={() => setShowChangeStatusEquipo(!showChangeStatusEquipo)}>
                                Desactivar
                            </Button>
                            : <Button 
                                size="sm" 
                                color="primary" 
                                className="w-20 mx-2" 
                                onClick={() => setShowChangeStatusEquipo(!showChangeStatusEquipo)}>
                                Activar
                            </Button>
                            }
                            </>
                    )}
                    
                    <Link to="/equipos">
                        <Button size="sm" color="neutral" className="w-20 mx-2">Cancelar</Button>
                    </Link>
                </div>

                {hasError && (
                    <ErrorMessage 
                        errorMessage={errorMessage} />
                )}

            </form>
            { showChangeStatusEquipo && (
                <ModalContainer>
                    <ChangeEquipoStatus
                        showModal={showChangeStatusEquipo}
                        setShowModal={setShowChangeStatusEquipo}
                        state={activo}
                        idEquipo={id}
                        deactivateFunction={submitDeleteEquipo}
                        reactivateFunction={reactivateEquipo}/>
                </ModalContainer>
            )}
        </div>
        }
        </>
    );
}