import { TicketResponse } from "../types";
import { CreateTicket, CreateTicketRequest, TicketExportFilters } from "../types/ticket";
import { BaseService } from "./BaseService";

export class TicketServices extends BaseService {

    /**
     * @param { string } token - Auth token for getting access to API Backend.
     */
    constructor(token: string) {
        super(token, "/api/ticket")
    }

    /**
     * Save a new Ticket in database.
     * 
     * @param { CreateTicketRequest } ticket - new ticket data.
     * @returns 
     */
    public async saveTicket(ticket: CreateTicketRequest) {
        const response = await this.axios.post("", {
            estado: ticket.estado,
            urgencia: ticket.urgencia,
            prioridad: ticket.prioridad,
            informado: ticket.informado,
            categoriaId: ticket.categoriaId,
            subcategoriaId: ticket.subcategoriaId,
            equipoId: ticket.equipoId,
            sucursalId: ticket.sucursalId,
            titulo: ticket.titulo,
            descripcion: ticket.descripcion,
            imagen: ticket.imagen
        }, { headers: {'Content-Type': 'multipart/form-data'}})
        const { data } = response;
        return data;
    }

    /**
     * Get list of Tickets.
     * 
     * @param { string } [fechaInicial] - Start date.
     * @param { string } [fechaFinal] - Finish date. 
     * @returns { Promise<Array<TicketResponse>> } - List of Tickets.
     */
    public async getTickets(fechaInicial?: string, fechaFinal?: string, onlyOpen?: boolean) : Promise<Array<TicketResponse>> {
        const response = await this.axios.get<Array<TicketResponse>>("", { params: { fechaInicial, fechaFinal, onlyOpen } })
        const { data } = response;
        return data;
    } 

    /**
     * Get list of tickts by list of id.
     * 
     * @param { Array<number> } ticketIdList - List of tickets id.
     * @returns { Promise<Array<TicketResponse>> } - List of Tickets.
     */
    public async getTicketsByIds(ticketIdList: Array<number>): Promise<Array<TicketResponse>> {
        const response = await this.axios.post<Array<TicketResponse>>("by-ids", { ticketIdList })
        const { data } = response;
        return data;
    }

    /**
     * Get a Tickets by its id.
     * 
     * @param { string | undefined } id - Ticket id. 
     * @returns { Promise<TicketResponse> } - Ticket data.
     */
    public async getTicketById(id: string | undefined) : Promise<TicketResponse> {
        const response = await this.axios.get<TicketResponse>(`/${id}`)
        const { data } = response;
        return data;
    }

    /**
     * Update ticket's state.
     * 
     * @param { string | undefined } id - Ticket id. 
     * @param { string } estadoNuevo - New state.
     * @returns 
     */
    public async updateTicketState(id: string | undefined, estadoNuevo: string) {
        return await this.axios.post(`/${id}/update-estado`, { estadoNuevo });
    }

    /**
     * Validata a ticket.
     * 
     * @param { string | undefined } id - Ticket id.
     * @returns 
     */
    public async validateTicket(id: string | undefined) {
        return await this.axios.post(`/${id}/validate`);
    }

    /**
     * Update ticket data.
     * 
     * @param { string | undefined } id - Ticket id.
     * @param { TicketResponse } data - New ticket data.
     * @returns 
     */
    public async updateTicket(id: string | undefined, data: TicketResponse) {
        return await this.axios.put(`/${id}`, data);
    }

    /**
     * Create tickets in bulk
     * 
     * @param { CreateTicket[] } tickets - List of ticket that will be created
     * @returns 
     */
    public async bulkSaveTickets(tickets: CreateTicket[]) {
        return await this.axios.post("/bulk", { tickets }, { headers: {"Content-Type": "application/json"} });
    }

    /**
     * Export ticket data
     * 
     * @param { TicketExportFilters } filters - Filters.
     * @returns 
     */
    public async exportTicketData(filters: TicketExportFilters) {
        return await this.axios("/export", {
            params: {
                categoriaId: filters.categoriaId,
                sucursalId: filters.sucursalId,
                responsableId: filters.responsableId,
                urgencia: filters.urgencia,
                estado: filters.estado,
                fechaInicial: filters.fechaInicial,
                fechaFinal: filters.fechaFinal
            }
        })
    }
}