import axios from "axios";
import { BaseService } from "./BaseService";
import { ChangePasswordRequest } from "../types/user";

const baseUrl = "/api/auth";

interface Credential {
    username: string
    password: string
}

//export const loginUser = async ( credentials: Credential ) => {
//    return axios.post(`${baseUrl}/login`,{
//        username: credentials.username,
//        password: credentials.password
//    }).then(response => {
//        if(response.status != 200){
//            throw new Error("invalid credentials");
//        }
//       const { data } = response;
//
//        return data;
//    });
//} 

//export const loginFake = async ( {username, password}: Credential ) => {
//    if(password == "password" && username == "admin"){
//        return {
//            username,
//            password
//        }
//    }else{
//        throw new Error("Credenciales invalidas");
//    }
//} 


export class AuthServices extends BaseService {

    /**
     * @param { string } token - Auth token for getting access to API Backend.
     */
    constructor(token: string) {
        super(token, "/api/auth");
    }

    /**
     * Real function for login
     * 
     * @param { Credential} credentials - User's Credentials
     * @returns - User's data for authentication
     */
    public static async loginUser( credentials: Credential ) {
        return axios.post(`${baseUrl}/login`,{
            username: credentials.username,
            password: credentials.password
        }).then(response => {
            if(response.status != 200){
                throw new Error("invalid credentials");
            }
            const { data } = response;

            return data;
        });
    } 

    /**
     * 
     * @param { number } userId 
     * @param { ChangePasswordRequest } passwords 
     * @returns 
     */
    public async changePassword(userId: number, passwords: ChangePasswordRequest) {
        return await this.axios.post(`/${userId}/change-password`, passwords)
    }

    /**
     * Fake function for login
     * 
     * @param { Credential } credentials - User's credentials 
     * @returns - User's data for authentication
     */
    public static async loginFake({ username, password }: Credential ) {
        if(password == "password" && username == "admin"){
            return {
                username,
                password
            }
        }else{
            throw new Error("Credenciales invalidas");
        }
    } 
}