import { ErrorRequest } from "../types";
import { isAxiosError } from "axios";

export function errorParser(error: unknown): string {

    if(isAxiosError<ErrorRequest, Record<string, unknown>>(error)) {
        const message = error.response?.status == 500 
            ? "Intente más tarde."
            : error.response?.data.problemDetails.error[0].description;
       return message ?? "No message";
    }

    return "No message"
}