import { ChangeEvent, useEffect, useState } from "react";
import { useUser } from "../../hooks/useUser";
import { MetricsServices } from "../../services/metrics";
import { CategoryModel, FiltrosMetricsModel, Metrics, MetricsFilter, MetricsResponse } from "../../types";
import { CategoriaServices } from "../../services/categorias";
import { CircularProgress } from "@mui/joy";
import { FiltrosMantenimiento } from "../UtilsComponents/FiltrosMetricas/FiltrosMantenimiento";
import { formatDate } from "../../utilities/showData";
import { BarChartContainer } from "./GraphicsComponents/BarComponents/BarChartContainer";
import { BarStackedChartContainer } from "./GraphicsComponents/BarComponents/BarStackedChartContainer";

export function MetricsMantenimiento() {

    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const categoriaService = new CategoriaServices(token);
    const metricsService = new MetricsServices(token);

    const [dataMetrics, setDataMetrics] = useState<MetricsResponse>({
        ticketCerrados: new Map<string, Metrics>([]),
        ticketCreados: new Map<string, Metrics>([]),
        ticketVencidos: new Map<string, Metrics>([]),
        ticketTiempoPromedioCierre: { labels: [], data: [], total: [], listIds: [] },
        ticketTiempoPromedioAtencion: { labels: [], data: [], total: [], listIds: [] },
        ticketData: { ticketsCreados: 0, ticketVencidos: 0, ticketNoVencidos: 0, ticketCerrados: 0 }
    });

    const [categorias, setCategorias] = useState<Array<CategoryModel>>([]);
    const [filters, setFilters] = useState<MetricsFilter>({
        categoriaId: 0,
        subcategoriaId: 0,
    });
    const [loading, setLoading] = useState(true);

    const onChangeFilter = (event: ChangeEvent<HTMLSelectElement>) => {
        setFilters({
            ...filters,
            [event.target.name]: parseInt(event.target.value)
        });
    }
    const [filtros, setFiltros] = useState<FiltrosMetricsModel>({
        fechaInicial: formatDate(new Date(new Date().setDate(1))),
        fechaFinal: formatDate(new Date()),
        sucursalId: 0,
        responsableId: 0,
        categoriaId: 0,
        subcategoriaId: 0,
        equipoId: 0
    });

    useEffect(() => {

        categoriaService.getCategories()
            .then(data => setCategorias(data));

        metricsService.getMetricsTicket(filtros)
            .then(dataResponse => {
                if(dataResponse !== undefined) {
                    setDataMetrics(dataResponse);
                    setLoading(false);
                }
            });

    }, []);

    useEffect(() => {
        setLoading(true)

        metricsService.getMetricsTicket(filtros)
            .then(dataResponse => {
                if(dataResponse !== undefined) {
                    setDataMetrics(dataResponse);
                    setLoading(false);
                }
            });

    }, [filtros])

    return (
        <div className="w-full">
            <h2 className="text-3xl my-4 font-semibold">Metricas Mantenimiento</h2>
            <div>
                <FiltrosMantenimiento
                    filtros={filtros}
                    setFiltros={setFiltros} />
                <div>
                    <div className="flex flex-row">

                        <div className="flex gap-4">
                            <p className="rounded-lg p-2 m-2 bg-white shadow-lg font-semibold">Total de Tickets {dataMetrics.ticketData.ticketsCreados}</p>
                            <p className="rounded-lg p-2 m-2 bg-white shadow-lg font-semibold">Total Vencidos {dataMetrics.ticketData.ticketVencidos}</p>
                            <p className="rounded-lg p-2 m-2 bg-white shadow-lg font-semibold">Total No Vencidos {dataMetrics.ticketData.ticketNoVencidos}</p>
                        </div>
                    </div>

                </div>

            </div>
            <div className="flex flex-row flex-wrap">

                <div className="rounded-lg p-2 m-2 bg-white shadow-lg">
                    <h2 className="text-xl my-4 font-semibold">Tickets Creados (Total: {dataMetrics.ticketData.ticketsCreados})</h2>
                    {!loading 
                        ?
                        <div>
                            <BarChartContainer 
                                metricType="Ticket"
                                color="DefaultColor"
                                width={400} 
                                height={300} 
                                showGraphics={{
                                    sucursal: true,
                                    categoria: true,
                                    subcategoria: true,
                                    equipo: true,
                                    responsable: false
                                }}
                                numberType="Integer"
                                dataMetrics={(new Map<string, Metrics>(Object.entries(dataMetrics.ticketCreados)))}/>
                        </div>
                        : <div className="w-[400px] h-[300px] flex justify-center items-center"><CircularProgress /></div>
                    }
                </div>

                <div className="rounded-lg p-2 m-2 bg-white shadow-lg">
                    <h2 className="text-xl my-4 font-semibold">Tickets Cerrados (Total: {dataMetrics.ticketData.ticketCerrados})</h2>
                    {!loading 
                        ? dataMetrics.ticketCerrados !== undefined 
                            ? <BarChartContainer 
                                metricType="Ticket"
                                color="DefaultColor"
                                width={400} 
                                height={300} 
                                showGraphics={{
                                    sucursal: true,
                                    categoria: true,
                                    subcategoria: true,
                                    equipo: true,
                                    responsable: true
                                }}
                                numberType="Integer"
                                dataMetrics={(new Map<string, Metrics>(Object.entries(dataMetrics.ticketCerrados)))}/>
                            : <div className="flex items-center justify-center w-[400px] h-[260px]">
                                <p className="text-lg my-4 font-semibold text-center items-center">Sin Datos</p>
                            </div> 
                        : <div className="w-[400px] h-[300px] flex justify-center items-center"><CircularProgress /></div>
                    }
                </div>

                <div className="rounded-lg p-2 m-2 bg-white shadow-lg">
                    <h2 className="text-xl my-4 font-semibold w-[400px]">Horas Promedio de Cierre de Tickets</h2>
                    {!loading 
                        ?   <BarChartContainer 
                                metricType="Ticket"
                                color="DefaultColor"
                                width={400} 
                                height={300} 
                                showGraphics={{
                                    sucursal: false,
                                    categoria: false,
                                    subcategoria: false,
                                    equipo: false,
                                    responsable: true
                                }}
                                numberType="Float"
                                dataMetrics={(new Map<string, Metrics>(Object.entries(dataMetrics.ticketTiempoPromedioCierre)))}/>
                            : <div className="flex items-center justify-center w-[400px] h-[260px]">
                                <p className="text-lg my-4 font-semibold text-center items-center">Sin Datos</p>
                            </div> 
                    }
                </div>

                <div className="rounded-lg p-2 m-2 bg-white shadow-lg">
                    <h2 className="text-xl my-4 font-semibold w-[400px]">Horas Promedio de Atencion de Tickets</h2>
                    {!loading 
                            ? <BarChartContainer
                                metricType="Ticket"
                                color="DefaultColor"
                                width={400}
                                height={300} 
                                showGraphics={{
                                    sucursal: false,
                                    categoria: false,
                                    subcategoria: false,
                                    equipo: false,
                                    responsable: true
                                }}
                                numberType="Float"
                                dataMetrics={(new Map<string, Metrics>(Object.entries(dataMetrics.ticketTiempoPromedioAtencion)))}/>
                            : <div className="flex items-center justify-center w-[400px] h-[260px]">
                                <p className="text-lg my-4 font-semibold text-center items-center">Sin Datos</p>
                            </div> 
                    }
                </div>

                <div className="rounded-lg p-2 m-2 bg-white shadow-lg">
                    <h2 className="text-xl my-4 font-semibold w-[400px]">Porcentaje de Ticket Vencidos (Total: {dataMetrics.ticketData.ticketVencidos})</h2>
                    {!loading 
                        ? dataMetrics.ticketVencidos !== undefined 
                        ?
                        <BarStackedChartContainer
                            metricType="Ticket"
                            color="VencidosColor"
                            width={400} 
                            height={300} 
                            showGraphics={{
                                sucursal: true,
                                categoria: true,
                                subcategoria: true,
                                equipo: true,
                                responsable: true
                            }}
                            numberType="Porcent"
                            dataMetrics={(new Map<string, Metrics>(Object.entries(dataMetrics.ticketVencidos)))}/> 
                            : <div className="flex items-center justify-center w-[400px] h-[260px]">
                                <p className="text-lg my-4 font-semibold text-center items-center">Sin Datos</p>
                            </div> 
                        : <div className="w-[400px] h-[300px] flex justify-center items-center"><CircularProgress /></div>
                    }
                </div>

            </div>

        </div>
    );
}