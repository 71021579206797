import { ChangeEvent } from "react";
import { useForm } from "react-hook-form";
import { Button, CircularProgress } from "@mui/joy";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CategoryModel, SolicitudRequest, SucursalModel, TicketResponse, UsuarioModel } from "../../types";
import { useUser } from "../../hooks/useUser";
import { urgenciaList, prioridadList, Estados, Urgencia, Prioridad } from "../../utilities/StateEnums";
import { useEffect, useState } from "react";
import { CategoriaServices, ComprasServices, SucursalServices, UserServices } from "../../services";
import { prioridadDefault } from "../../utilities/prioridadDefault";
import { LoadingRequest, ErrorMessage } from "../UtilsComponents";
import { errorParser } from "../../utilities";
import { EmailInput } from "../UtilsComponents/EmailInput";

export function SolicitudCreateForm() {
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const categoriaService = new CategoriaServices(token);
    const comprasService = new ComprasServices(token);
    const sucursalService = new SucursalServices(token);
    const userService = new UserServices(token);

    const navigate = useNavigate();
    const location = useLocation();
    const ticketState: TicketResponse | undefined = location.state?.ticketStatus;
    //const from = location.state?.from?.pathname || "/compras"; 
    const from = "/compras"; 

    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [requestLoading, setRequestLoading] = useState<boolean>(false);
    const [showEmailInput, setShowEmailInput] = useState<boolean>(false);

    let categorySelected: CategoryModel | undefined;
    const [sucursalList, setSucursalList] = useState<Array<SucursalModel>>([]);
    const [categoriaList, setCategoriaList] = useState<Array<CategoryModel>>([]);
    const [listUserResponsable, setListUserResponsable] = useState<Array<UsuarioModel>>([]);
    const [idCategorySelected, setIdCategorySelected] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState(true)

    const { 
            register,
            handleSubmit,
            resetField,
            setValue,
            getValues,
            formState: { errors }
        } = useForm<SolicitudRequest>({
        defaultValues: {
            creadorId: userContext.auth?.user?.id,
            estado: Estados.Abierto,
            urgencia: Urgencia.TresDias,
            prioridad: Prioridad.Media,
            sucursalId: ticketState?.sucursalId ?? 0,
            categoriaId: ticketState?.categoriaId ?? 0,
            subcategoriaId: ticketState?.subcategoriaId ?? 0,
            ticketId: location.state?.ticketId
        }
    });

    const ticketId = getValues("ticketId");

    if(ticketState && idCategorySelected === null) {
        setIdCategorySelected(getValues("categoriaId"));
    }

    const submitSaveSolicitud = handleSubmit(async (data: SolicitudRequest) => {
        try {
            setHasError(false);
            setRequestLoading(true);

            const response = await comprasService.saveSolicitud(data)

            if(response.status === 201) {
                setRequestLoading(false);
                navigate("/compras");
            }

        } catch(error) {
            const message = errorParser(error);
            setRequestLoading(false);
            setErrorMessage(message);
            setHasError(true);
        }
    });

    const handleChanges = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        if(event.target.name === "categoryId") {
            setIdCategorySelected(parseInt(event.target.value));
            resetField("subcategoriaId");
            setValue("categoriaId", parseInt(event.target.value), { shouldDirty: true, shouldTouch: true, shouldValidate: true });
        }

        if(event.target.name === "urgencia") {
            setValue("prioridad", prioridadDefault(event.target.value), { shouldDirty: true, shouldTouch: true, shouldValidate: true });
        }
    }

    if(idCategorySelected != null) {
        categorySelected = categoriaList.find((category) => category.id === idCategorySelected);
    }

    useEffect(() => {
        sucursalService.getSucursales()
            .then(data => setSucursalList(data));

        categoriaService.getCategories()
            .then(data => setCategoriaList(data));
        
        userService.getUsersByRole("Compras")
            .then(data => setListUserResponsable(data));
        
    }, []);

    useEffect(() => {
        if(categoriaList.length > 0 && sucursalList.length > 0) {
            setIsLoading(false);
        }
    }, [categoriaList, sucursalList])

    return (
        <div>
            { !isLoading ?
            <>
            <h2 className="text-3xl my-4 font-semibold">Nueva solicitud de compra {location.state?.ticketId ? `para el ticket ${ticketId}` : ""}</h2>
            <form className="grid grid-cols-2" onSubmit={submitSaveSolicitud} autoComplete="off">

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="estado">Estado</label>
                    <input {...register("estado")}
                            disabled={true}
                            id="estado"
                            className="inputform"/>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="urgencia">Urgencia</label>
                    <select {...register("urgencia", {
                                required: "La urgencia es requerida",
                            })}
                            aria-invalid={errors.urgencia ? "true" : "false"}
                            onChange={handleChanges}
                            name="urgencia"
                            id="urgencia"
                            className="inputform">
                            <option defaultChecked value={""} hidden></option>
                            {urgenciaList.map((value, index) => (
                                <option key={index} value={value}>{value}</option>
                            ))}
                    </select>
                    {errors.urgencia?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.urgencia.message}</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="prioridad">Prioridad</label>
                    <select {...register("prioridad")}
                            id="prioridad"
                            disabled={true}
                            className="inputform">
                            <option defaultChecked value={""} hidden></option>
                            {prioridadList.map((value, index) => (
                                <option key={index} value={value}>{value}</option>
                            ))}
                    </select>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="creado">Creado por</label>
                    <input value={userContext.auth?.user?.nombre}
                            id="creado"
                            disabled={true}
                            className="inputform"/>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="sucursal">Sucursal</label>
                    <select {...register("sucursalId", {
                                required: "La sucursal es requerida",
                                min: 1
                            })}
                            aria-invalid={errors.sucursalId ? "true" : "false"}
                            id="sucursal"
                            className="inputform">
                            {userContext.auth?.user?.tipoUsuario === "General" 
                                ? (
                                    <option 
                                        defaultChecked 
                                        value={userContext.auth.user.sucursalId}>
                                        {userContext.auth.user.sucursalNombre}
                                    </option>
                                )
                                : (
                                <>
                                    <option value={0} hidden></option>
                                    {sucursalList.map((sucursal, index) => (
                                        <option key={index} value={sucursal.id}>{sucursal.smallDir}</option>
                                    ))}
                                </>
                                )
                            }
                    </select>
                    {errors.sucursalId?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.sucursalId.message}</p>
                    )}
                    {errors.sucursalId?.type === "min" && (
                        <p className="col-start-2 valErrorText" role="alert">Tiene que seleccionar una sucursal</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="categoria">Categoria</label>
                    <select {...register("categoriaId", {
                                required: "La categoria es requerida",
                                min: 1
                            })}
                            aria-invalid={errors.categoriaId ? "true" : "false"}
                            onChange={handleChanges}
                            id="categoria"
                            name="categoryId"
                            className="inputform">
                            <option defaultChecked value={0} hidden></option>
                            {categoriaList.map((category, index) => (
                                <option key={index} value={category.id}>{category.nombreCategoria}</option>
                            ))}
                    </select>
                    {errors.categoriaId?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.categoriaId.message}</p>
                    )}
                    {errors.categoriaId?.type === "min" && (
                        <p className="col-start-2 valErrorText" role="alert">Tiene que seleccionar una categoría</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="subcategoria">Subcategoria</label>
                    <select {...register("subcategoriaId", {
                                required: "La subcategoría es requerida",
                                min: 1
                            })}
                            aria-invalid={errors.subcategoriaId ? "true" : "false"}
                            id="subcategoria"
                            className="inputform">
                            <option defaultChecked value={0} hidden></option>
                            {categorySelected?.subcategoria?.map((subcategory, index) => (
                                <option key={index} value={subcategory.id}>{subcategory.nombreSubcategoria}</option>
                            ))}
                    </select>
                    {errors.subcategoriaId?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.subcategoriaId.message}</p>
                    )}
                    {errors.subcategoriaId?.type === "min" && (
                        <p className="col-start-2 valErrorText" role="alert">Tiene que seleccionar una subcategoría</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="responsable">Responsable</label>
                    <select {...register("responsableId", {
                                required: "El responsable es requerido",
                                min: 1
                            })}
                            aria-invalid={errors.responsableId ? "true" : "false"}
                            id="responsable"
                            className="inputform">
                            <option defaultChecked value={0} hidden></option>
                            {listUserResponsable.map((user, index) => (
                                <option key={index} value={user.id}>{user.nombre}</option>
                            ))}
                    </select>
                    {errors.responsableId?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.responsableId.message}</p>
                    )}
                    {errors.responsableId?.type === "min" && (
                        <p className="col-start-2 valErrorText" role="alert">Tiene que seleccionar un responsable</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="titulo">Titulo</label>
                    <input {...register("titulo", {
                                required: "El titulo es requerido",
                                maxLength: 150
                            })}
                            aria-invalid={errors.titulo ? "true" : "false"}
                            id="titulo"
                            className="inputform"/>
                    {errors.titulo?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.titulo.message}</p>
                    )}
                    {errors.titulo?.type === "maxLength" && (
                        <p className="col-start-2 valErrorText" role="alert">Número de caracteres permitidos: 150</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="descripcion">Descripción</label>
                    <textarea {...register("descripcion", {
                                required: "La descripcion es requerida"
                            })}
                            className="textareaform"
                            aria-invalid={errors.descripcion ? "true" : "false"}
                            id="descripcion"
                            rows={5}/>
                    {errors.descripcion?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.descripcion.message}</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="descripcion">Archivo</label>
                    <input 
                        className="inputform"
                        onChange={(event) => {
                            if(event.target.files !== null) {
                                setValue("imagen", event.target.files[0])
                            }
                        }}
                        type="file"
                        name="imagen"/>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                {showEmailInput
                ? <>
                    <label className="font-medium" htmlFor="descripcion">Adjuntas Correos</label>
                    <EmailInput 
                        setValue={setValue}/>
                </>
                : <>
                   <div className="w-full"></div> 
                   <div className="w-fit">
                        <p className="italic cursor-pointer no-underline hover:underline hover:text-blue-900"
                            onClick={() => setShowEmailInput(!showEmailInput)}>Agregar más correos</p>
                   </div>

                </>
                }
                </div>

                <div className="flex justify-end col-span-2 m-2 gap-2">
                    {requestLoading
                    ? <LoadingRequest />
                    : <Button size="sm" color="success" className="w-20" type="submit">Registrar</Button>
                    }
                    <Button 
                        size="sm"
                        color="neutral"
                        className="w-20 mx-2"
                        onClick={() => navigate(-1)}>
                        Cancelar
                    </Button>
                </div>

                {hasError && (
                    <ErrorMessage
                        errorMessage={errorMessage} />
                )}

            </form>
            </>
        : <CircularProgress/> }
        </div>
    );
}