import { ReactNode, useState } from "react";
import { DropMenuItem } from "./DropMenuItem";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export interface Items {
    component: ReactNode;
    url: string;
}

interface Props {
    children: ReactNode;
    items: Array<Items>;
}

export function DropMenuContainer({ children, items }: Props) {
    const [open, setOpen] = useState(false)

    return (
        <>
            <div className="w-full mb-2 sm:mb-0">

                <div onClick={() => setOpen(!open)} className={`flex flex-col sm:flex-row cursor-pointer w-full sm:h-10 px-3 sm:pl-0 sm:pr-0 sm:mt-2 rounded text-gray-400 sm:hover:bg-gray-700 hover:text-gray-300`}>
                    <div className="flex flex-col sm:flex-row items-center w-full justify-between">
                        {children}
                        <div className={`block transform transition-transform duration-300 ${open ? 'rotate-90' : '-rotate-90 sm:rotate-0'}`}>
                            <KeyboardArrowRightIcon sx={{
                                width: "20px",
                                height: "20px"
                            }} />
                        </div>
                    </div>
                </div>

                <div className={`w-full ${open ? "sm:h-auto" : "sm:h-0"} sm:transition-all sm:duration-300 sm:ease-out text-center`}>
                    <ul className={`${open ? "mb-10" : ""} sm:m-0`}>
                        {items.map((item, index) => (
                            <DropMenuItem key={index} open={open} item={item.component} url={item.url}/>
                        ))}
                    </ul>
                </div>

            </div>

        </>

    );
}