interface Props {
    errorMessage: string;
    customMessage?: boolean;
}

export function ErrorMessage({ errorMessage, customMessage }: Props) {
    return (
        <div className="grid justify-items-center sm:flex sm:justify-center grid-cols-1 col-span-2 gap-2 bg-red-300 m-2 p-2 border border-red-400 rounded-lg text-red-700 font-semibold">
            {customMessage == null 
            ? <>
                <p>Un problema ha ocurrido.</p>
                <p>{errorMessage}</p>
            </>
            :
            <>
                <p>{errorMessage}</p>
            </>
            }
        </div>
    );
}