export function calculateMaxRange(greatestGeneral: number, greatestEspecialista: number): number {
    const max = greatestEspecialista > greatestGeneral ? greatestEspecialista : greatestGeneral;

    let ejeY = max > 100 ? max + 10 : 100 ;

    while(ejeY % 10 !== 0) {

        ejeY += 1;
    }
    
    return ejeY;
}