import { useState, useEffect } from "react";
import { SucursalModel } from "../../types";
import { AddButon } from "../../utilities/UtilsComponents/AddButon";
import { EditButton } from "../../utilities/UtilsComponents/EditButton";
import Table from "@mui/joy/Table";
import { useUser } from "../../hooks/useUser";
import { SucursalServices } from "../../services";
import { LoadingComponent } from "../UtilsComponents/LoadingComponent";

export function SucursalesHome() {
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";
    const [sucursales, setSucursales] = useState<Array<SucursalModel>>([]);
    const [dataLoading, setDataLoading] = useState<boolean>(true);

    const sucursalService = new SucursalServices(token);

    useEffect(() => {
        sucursalService.getSucursales()
            .then(data => {
                setSucursales(data);
                setDataLoading(false);
            });
    }, []);

    return (
        <div>
            <h2 className="text-3xl my-4 font-semibold">Sucursales</h2>

            <AddButon url="/sucursales/create" />

            {dataLoading
            ? <LoadingComponent />
            :
            <div className="overflow-auto max-h-[600px] rounded-lg border border-slate-200 my-4">
            <Table
                stickyHeader
                sx={(theme) => ({
                    "th": {bgcolor: "#111827", color: "#ffffff"}
                })}>

            <table className="w-full">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Direccion</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {sucursales.map((sucursal, index)=> {
                        return (
                        <tr key={index} className={`hover:bg-slate-100`}>
                            <td>{sucursal.smallDir}</td>
                            <td>{sucursal.direccion}</td>
                            <td>
                                <EditButton url={`/sucursales/${sucursal.id}`}/>
                            </td>
                        </tr>
                    )})}
                </tbody>
            </table>

            </Table>
            </div>
            }
        </div>
    )
}