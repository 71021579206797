import axios, { AxiosInstance } from "axios"

interface FiltrosProps {
    url: string
    fechaInicial?: string
    fechaFinal?: string
}

export class BaseService {
    protected readonly axios: AxiosInstance;

    /**
     * 
     * @param { string } token - Auth token for getting access to API Backend.
     * @param { string } BASE_URL - URL of endpoint controller
     */
    constructor(
        private readonly token: string, 
        protected readonly BASE_URL: string
        ) {
        this.axios = axios.create({
            baseURL: this.BASE_URL,
            headers: { Authorization: `Bearer ${this.token}` }
        })
    }

    protected genUrlWithQueryFilters({ url, fechaInicial, fechaFinal }: FiltrosProps): string {
        return fechaFinal !== undefined && fechaInicial !== undefined 
            ? url + `?fechaInicial=${fechaInicial}&fechaFinal=${fechaFinal}`
            : url;
    }
}