import { useState, useEffect } from "react";
import { useUser } from "../../hooks/useUser";
import { UserServices } from "../../services";
import { UsuarioModel } from "../../types";
import { EditButton } from "../../utilities/UtilsComponents/EditButton";
import { LoadingComponent } from "../UtilsComponents/LoadingComponent";
import Table from "@mui/joy/Table";
import { ColorEquipoState } from "../UtilsComponents/ColorEquipoState";

export function UsersTable() {
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const userService = new UserServices(token);

    const [usuarios, setUsuarios] = useState<Array<UsuarioModel>>([])
    const [dataLoading, setDataLoading] = useState<boolean>(true);

    useEffect(() => {
        userService.getUsers()
            .then(data => {
                setUsuarios(data);
                setDataLoading(false);
            });
    }, []);

    return (
        <>
        {dataLoading
        ? <LoadingComponent />
        :
        <div className="overflow-auto max-h-[600px] rounded-lg border border-slate-200 my-4">
            <Table
                stickyHeader
                sx={(theme) => ({
                    "th": {bgcolor: "#111827", color: "#ffffff"}
                })}>

            <table className="w-full">
                <thead>
                    <tr>
                        <th>Nombre Usuario</th>
                        <th>Correo</th>
                        <th>Tipo Usuario</th>
                        <th>Estado</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {usuarios.map((usuario, index)=> {
                        return (
                        <tr key={index} className={`hover:bg-slate-100`}>
                            <td>{usuario.nombre}</td>
                            <td>{usuario.correo}</td>
                            <td>{usuario.tipoUsuario.tipo}</td>
                            <td>
                                <ColorEquipoState state={usuario.isActive ? "Activo" : "Inactivo"} />
                            </td>
                            <td>
                                <EditButton url={`/usuarios/${usuario.id}`}/>
                            </td>
                        </tr>
                    )})}
                </tbody>
            </table>

            </Table>
        </div>

        }
        </>
    );
}