import { useState, ChangeEvent, MouseEvent } from "react";
import { Button } from "@mui/joy";
import { Dispatch, SetStateAction } from "react";
import { Estados, EstadosSolicitud } from "../../utilities/StateEnums";
import { AxiosResponse } from "axios";
import { ComentarioServices, ComprasServices,  } from "../../services";
import { useUser } from "../../hooks/useUser";
import { useNavigate, useParams } from "react-router-dom";


interface Props {
    setClose: Dispatch<SetStateAction<boolean>> 
    close: boolean
    updateFunction: (newState: EstadosSolicitud) => void //Promise<AxiosResponse<any, any>>
    comprasService: ComprasServices
}


export function SolicitudCancelEstado({setClose, close, comprasService}: Props) {
    const { id } = useParams();
    const navigate = useNavigate();
    const [comentario, setComentario] = useState<string>("");
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const comentarioService = new ComentarioServices(token);

    const onChangeComentario = (event: ChangeEvent<HTMLInputElement>) => {
        setComentario(event.target.value);
    }

    const onSubmitCancel = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        comprasService.updateStateSolicitud(id, EstadosSolicitud.Cancelado)
            .then(response => {
                if(response.status === 200){
                    comentarioService.saveComentarioInSolicitud({
                        id: id,
                        texto: comentario,
                        usuarioId: userContext.auth?.user?.id ?? 2
                    })
                    .then(response => {
                        if(response.status === 201) {
                            navigate("/compras");
                        }
                    })
                    .catch(error => console.error(error));
                }
            })
            .catch(error => console.error(error));
    }

    return (
        <div className="w-96 bg-white rounded-md transition duration-300">
            <h2 className="text-xl my-4 font-semibold text-center">¿Seguro que quiere cancelar esta solicitud?</h2>

            <div className="grid grid-cols-1" >
                <label className="mx-3 font-medium text-sm" htmlFor="comentario">Motivo de la cancelación:</label>
                <input 
                    autoComplete="off"
                    id="comentario"
                    required={true}
                    minLength={0}
                    min={0}
                    value={comentario}
                    onChange={onChangeComentario}
                    className="border border-gray-900 rounded-md mx-3 px-2"
                    type="text"/>
            </div>

            <div className="flex justify-center gap-2 my-4">
                <Button size="sm"
                        color="danger"
                        className="w-20"
                        disabled={comentario?.length <= 0}
                        onClick={onSubmitCancel}>Cancelar</Button>
                <Button size="sm"
                        color="neutral"
                        className="w-20"
                        onClick={() => setClose(!close)}>Cerrar</Button>
            </div>
        </div>
    );
}