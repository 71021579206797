import { CategoryModel, SubcategoryModel } from "../../../types";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import Button from "@mui/joy/Button/Button";
import { useUser } from "../../../hooks/useUser";
import { CategoriaServices } from "../../../services/categorias";
import { SubcategoryServices } from "../../../services";
import { errorParser } from "../../../utilities";
import { ErrorMessage, LoadingRequest } from "../../UtilsComponents";


export function SubcategoryCreateForm(){

    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [requestLoading, setRequestLoading] = useState<boolean>(false);

    const categoriaService = new CategoriaServices(token);
    const subcategoryService = new SubcategoryServices(token);

    const navigate = useNavigate();
    const { register, 
            handleSubmit, 
            formState: { errors } 
        } = useForm<SubcategoryModel>();
    const [categories, setCategories] = useState<Array<CategoryModel>>([]);

    const submitSubcategory = handleSubmit(async (data: SubcategoryModel) => {
        try {
            setHasError(false);
            setRequestLoading(true);

            const response = await subcategoryService.saveSubcategory({
                nombreSubcategoria: data.nombreSubcategoria,
                categoriaId: data.categoriaId
            })

            console.log(response)

            if(response.status === 201) {
                setRequestLoading(false);
                navigate("/subcategories");
            }

        } catch(error) {
            const message = errorParser(error);
            setRequestLoading(false);
            setErrorMessage(message);
            setHasError(true);
        }
    });

    useEffect(()=>{
        categoriaService.getCategories()
            .then(data => setCategories(data))
    },[])

    return (
    <>
        <h2 className="text-3xl my-4 font-semibold">Nueva Subcategoría</h2>
        <form onSubmit={submitSubcategory}  className="grid grid-cols-2">

            <div className="grid grid-cols-2 justify-between col-span-2">
                <label className="font-medium">Nombre de la Subcategoria</label>
                <input  {...register("nombreSubcategoria",{
                            required: "El nombre de la subcategoría es requerido",
                            maxLength: 30
                        })}  
                        aria-invalid={errors.nombreSubcategoria ? "true": "false"}
                        className="inputform"
                        type="text" 
                        placeholder="Nombre"/>
                {errors.nombreSubcategoria?.type === "required" && (
                    <p className="col-start-2 valErrorText" role="alert">{errors.nombreSubcategoria.message}</p>
                )}
                {errors.nombreSubcategoria?.type === "maxLength" && (
                    <p className="col-start-2 valErrorText" role="alert">Maximo numero de caracteres es 30</p>
                )}
            </div>

            <div className="grid grid-cols-2 justify-between col-span-2">
                <label className="font-medium">Categoria</label>
                <select {...register("categoriaId", {
                            required: "Debe seleccionar una categoría",
                        })}
                        aria-invalid={errors.categoriaId ? "true" : "false"}
                        className="h-8 my-2 border font-normal text-sky-900 border-cyan-300 hover:border-cyan-900 rounded-lg bg-transparent">
                    {categories.map((category, index) => (
                        <option key={index} value={category.id}>{category.nombreCategoria}</option>
                    ))}
                    <option hidden value={""} defaultChecked></option>
                </select>
                {errors.categoriaId?.type === "required" && (
                    <p className="col-start-2 valErrorText" role="alert">{errors.categoriaId.message}</p>
                )}
            </div>

            <div className="flex justify-end col-span-2 m-2 gap-2">
                {requestLoading
                ? <LoadingRequest />
                : <Button size="sm" color="success" className="w-20" type="submit">Registrar</Button>
                }
                <Link to="/subcategories">
                    <Button size="sm" color="neutral" className="w-20 mx-2">Cancelar</Button>
                </Link>
            </div>

            {hasError && (
                <ErrorMessage
                    errorMessage={errorMessage} />
            )}

        </form>
    </>
    );
}