import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button } from "@mui/joy";
import { useEffect, useState } from "react";
import { ChecklistProvider } from "../../context/ChecklistContext";
import { useUser } from "../../hooks/useUser";
import { ChecklistServices } from "../../services";
import { ChecklistModel, ChecklistRequest, ErrorRequest, TareaRequest } from "../../types";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { LoadingComponent } from "../UtilsComponents/LoadingComponent";
import { LayoutChecklistV2 } from "./LayoutChecklistV2";
import { ErrorMessage, LoadingRequest } from '../UtilsComponents';
import { isAxiosError } from 'axios';
import { ModalContainer } from '../UtilsComponents/ModalContainer';
import { EquipoRevision } from '../../types/checklist';
import { CreateTicketFromChecklist } from './CreateTicketFromChecklist';

export function ChecklistHome() {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams(); 

    const param = new URLSearchParams(location.search).get("sucursal")
    const sucursalId = param != null? parseInt(param) : undefined;

    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const checklistService = new ChecklistServices(token);

    //const [isSideDetailOpen, setSideDetailOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [openModal, setOpenModal] = useState<boolean>(false);

    const [checklist, setChecklist] = useState<ChecklistModel>();
    const [listTareas, setListTareas] = useState<Array<TareaRequest>>([]);

    // ----- Creacion de tickets -----
    const [equiposConProblemas, setEquiposConProblemas] = useState<Array<EquipoRevision[]>>([]);

    const [checklistRequest, setChecklistRequest] = useState<ChecklistRequest>({
        listaId: 0,
        responsableId: 0,
        sucursalId: sucursalId ?? 0,
        comentarioGeneral: "",
        revisionTareas: []
    })

    const validateChecklistRequest = (checklistRequest: ChecklistRequest, checklist: ChecklistModel): boolean => {
        // validar que la cantidad de tareas revisadas sea la misma
        // que las tareas recibias
        if (checklistRequest.revisionTareas.length != checklist.tareas.length)
            return false;

        return true;
    }

    const openTicketCreationModal = async () => {
        if(checklistRequest.revisionTareas.find(tarea => tarea.revisionesEquipo.find(equipo => equipo.estado == "NO OK"))) {
            console.log("Hay equipos con estado NO OK");
            setOpenModal(true);

            const revisionesConProblemas = checklistRequest.revisionTareas.map(tarea => tarea.revisionesEquipo
                .filter(equipo => equipo.estado == "NO OK"))
                .filter(equipo => equipo.length > 0);
            
            setEquiposConProblemas(revisionesConProblemas);
        }
        else {
            await saveRevision();
        }
    }

    const saveRevision = async () => {
        try {
            setHasError(false);
            setSaveLoading(true);

            if (!validateChecklistRequest(checklistRequest, checklist!)) {
                setHasError(true);
                setErrorMessage("Todas las revisiónes tienen que ser realizadas.");
                setSaveLoading(false);
                console.log(saveLoading);
                return;
            }

            await checklistService.saveRevision(checklistRequest);

            setSaveLoading(false);
            navigate("/checklist/historial");
        } catch(error) {
            setSaveLoading(false);
            setHasError(true);

            if(isAxiosError<ErrorRequest, Record<string, unknown>>(error)) {
                const message = error.response?.status == 500 
                    ? "Intente más tarde."
                    : error.response?.data.problemDetails.error[0].description;
                setErrorMessage(message ?? "No message");
            }
        }
    }

    useEffect(() => {
        checklistService.getDailyChecklist(id, sucursalId)
            .then(data => {
                setChecklist(data);
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        if (checklist != null)
            setChecklistRequest({
                listaId: checklist!.id,
                responsableId: userContext.auth!.user!.id,
                sucursalId: checklistRequest.sucursalId,
                comentarioGeneral: "",
                revisionTareas: listTareas
            });
    }, [listTareas]);

    return (
        <ChecklistProvider>
            {isLoading
                ? <LoadingComponent />
                :
                <>
                    <div>
                        <div className="flex items-center gap-2 sm:gap-2">

                            {checklist?.checkedToday && (
                                <CheckCircleIcon
                                    className="text-green-800"
                                    fontSize="large"
                                    sx={{ height: '100%', width: 40, fontWeight: 'bold' }}/>
                            )}
                            <h2 className="text-3xl my-4 font-semibold">{checklist?.nombre} - {sucursalId}</h2>

                            {/* <button className="bg-gray-200 rounded px-[2px]">
                                <EditNoteIcon sx={{ height: '100%', width: 24 }} />
                            </button>

                            <button className="bg-gray-200 rounded px-[2px]">
                                <FormatListBulletedIcon sx={{ height: '100%', width: 24 }} />
                            </button> */}
                        </div>

                        {/* <SideDetail 
                        setSideDetailOpen={setSideDetailOpen}
                        isSideDetailOpen={isSideDetailOpen} /> */}

                        {checklist?.checkedToday
                            ? <div>
                                <p>La revision del dia de hoy de esta lista ya fue realizada.</p>
                                <div className="my-5">
                                    <Link to="/checklist">
                                        <Button 
                                            className="w-20 mx-2"
                                            size="sm"
                                            color="neutral" >
                                            Regresar
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        
                        :
                        <>
                        <LayoutChecklistV2 
                            listTareas={listTareas}
                            setListTareas={setListTareas}
                            checklist={checklist}/>

                        {hasError && (
                            <ErrorMessage 
                                errorMessage={errorMessage}/>
                        )}

                        {openModal && (
                            <ModalContainer>
                                <CreateTicketFromChecklist
                                saveRevisionFunction={saveRevision}
                                openModal={openModal}
                                setOpenModal={setOpenModal}
                                equiposConProblemas={equiposConProblemas}
                                setEquiposConProblemas={setEquiposConProblemas}/>
                            </ModalContainer>
                        )}

                        <div className="flex justify-between my-5">
                            {saveLoading
                            ? <LoadingRequest />
                            :
                            <Button
                                onClick={openTicketCreationModal}
                                size="sm"
                                color="success"
                                className="w-20"
                                type="submit">Terminar Revision</Button>
                            }

                            <Link to="/checklist">
                                <Button 
                                    className="w-20 mx-2"
                                    size="sm"
                                    color="neutral" >
                                    Regresar
                                </Button>
                            </Link>
                        </div>
                        </>}
                    </div>
                </>
            }
        </ChecklistProvider>
    );
}
