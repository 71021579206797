import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { ChartDisplay } from "../../types/agenda";

interface Props {
    name: string;
    text:string;
    chartDisplay: ChartDisplay;
    setChartDisplay: Dispatch<SetStateAction<ChartDisplay>>;
}

export function CheckBox({ text, name , chartDisplay, setChartDisplay}: Props) {

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setChartDisplay({
            ...chartDisplay,
            [event.target.name]: event.target.checked
        })
    }

    return (
        <label className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer rounded-lg">
            <input 
                defaultChecked
                name={name}
                onChange={handleChange}
                value={"true"}
                className={`form-checkbox appearance-none h-4 w-4 text-indigo-600 
                relative peer shrink-0 border-2 rounded-sm 
                border-[#111827] checked:border-[#111827]
                checked:hidden
                focus:outline-none focus:ring-offset-0 focus:ring-2 focus:ring-blue-100
                disabled:border-steel-400 disabled:bg-steel-400`}
                type="checkbox"/>
                
            <svg
                className={`
                    sticky
                    w-4 h-4
                    hidden peer-checked:block
                    pointer-events-none 
                    text-white bg-[#111827] rounded-sm
                    `}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round">
                <polyline points="20 6 9 17 4 12"></polyline>
            </svg>

            <span className="ml-2">{ text }</span>
        </label>
    );
}