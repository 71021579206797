import { Prioridad } from "./StateEnums";

interface Urgencias {
    Inmediata: string
    Dia: string
    TresDias: string
    MasDias: string
}

export const prioridadDefault = (urgencia: string): Prioridad => {
    let prioridadSelected: keyof Urgencias;
    const prioridadesList: Urgencias = {
        Inmediata: Prioridad.Urgente,
        Dia: Prioridad.Alta,
        TresDias: Prioridad.Media,
        MasDias: Prioridad.Baja,
    } 
    
    switch(urgencia) {
        case "Menos de 4 horas": prioridadSelected = "Inmediata"; break;
        case "Menos de 24 horas": prioridadSelected = "Dia"; break;
        case "24-72 horas": prioridadSelected = "TresDias"; break;
        case "+72 horas": prioridadSelected = "MasDias"; break;
        default: prioridadSelected = "MasDias"; break;
    }

    const keyPrioridad: string = prioridadesList[prioridadSelected];
    const prioridadReturn: Prioridad = Prioridad[keyPrioridad as keyof typeof Prioridad];
    return prioridadReturn;
}