import axios, { AxiosInstance } from "axios";
import { ComentarioModel } from "../types";


export class ComentarioServices {

    private readonly BASE_URL_TICKET = "/api/ticket";
    private readonly BASE_URL_SOLICITUD = "/api/compras";
    private axios: AxiosInstance;
    
    /**
     * @param { string } token - Auth token for getting access to API Backend.
     */
    constructor(private readonly token: string) {
        this.axios = axios.create({
            headers: { Authorization: `Bearer ${this.token}` }
        })
    }

    /**
     * Get list of comentarios on a ticket
     * 
     * @param { string | undefined} id - Ticket id
     * @returns { Promise<Array<ComentarioModel>> } - Returns a promise with an array of comentarios
     */
    public async getComentariosByTicketId(id: string | undefined): Promise<Array<ComentarioModel>> {
        const response = await this.axios.get<Array<ComentarioModel>>(
            this.BASE_URL_TICKET + `/${id}/comentarios`
        );
        const { data } = response;
        return data;
    }

    /**
     * Save a comentario related to a ticket
     * 
     * @param data - Comentario data
     * @returns 
     */
    public saveComentarioInTicket(data: { id: string | undefined, texto: string, usuarioId: number, imagen?: File | null}) {
        return this.axios.post(this.BASE_URL_TICKET + `/${data.id}/comentarios`, 
        {
            "texto": data.texto,
            "usuarioId": data.usuarioId,
            "imagen": data.imagen as File
        }, 
        { 
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    /**
     * Save a comentario related to a solicitud compras
     * 
     * @param data - Comentario data
     * @returns 
     */
    public saveComentarioInSolicitud(data: { id: string | undefined, texto: string, usuarioId: number, imagen?: File | null }) {
        return this.axios.post(this.BASE_URL_SOLICITUD + `/${data.id}/comentarios`, {
            "texto": data.texto,
            "usuarioId": data.usuarioId,
            "imagen": data.imagen as File
        },
        { 
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    /**
     * Get list of comentarios on a solicitud compras
     * 
     * @param { string | undefined} id - Solicitud compras id
     * @returns { Promise<Array<ComentarioModel>> } - Returns a promise with an array of comentarios
     */
    public async getComentarioBySolicitudId(id: string | undefined): Promise<Array<ComentarioModel>> {
        const response = await this.axios.get(
            this.BASE_URL_SOLICITUD + `/${id}/comentarios`
        );
        const { data } = response;
        return data;
    }
}