import { AddButon } from "../../utilities/UtilsComponents/AddButon";
import { CodeEquipoHome } from "../EquipoComponents/CodeEquipoComponents/CodeEquipoHome";
import { EquipoTable } from "../EquipoComponents";

export function EquipoHome(){

    return (
        <div className="w-full">

            <h2 className="text-3xl my-4 font-semibold">Equipos</h2>

            <AddButon url="/equipos/create"/>

            <EquipoTable />

            <br/>

            <CodeEquipoHome />
        </div>
    );
}