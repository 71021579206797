import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { TipoUsuarioModel, UsuarioCreate, SucursalModel } from "../../types";
import { Button } from "@mui/joy";
import { useUser } from "../../hooks/useUser";
import { SucursalServices, UserServices } from "../../services";
import { LoadingRequest, ErrorMessage } from "../UtilsComponents";
import { errorParser } from "../../utilities";

export function UserCreateForm(){
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";
    const navigate = useNavigate();

    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [requestLoading, setRequestLoading] = useState<boolean>(false);

    const [userTypes, setUserTypes] = useState<Array<TipoUsuarioModel>>([])
    const [sucursales, setSucursales] = useState<Array<SucursalModel>>([]);
    const { register, handleSubmit, formState: { errors } } = useForm<UsuarioCreate>();
    
    const sucursalService = new SucursalServices(token);
    const userService = new UserServices(token);

    const submitSaveUser = handleSubmit(async (data: UsuarioCreate) => {
        try {
            setHasError(false);
            setRequestLoading(true);

            const response = await userService.saveUser(data);

            if(response.status === 201) {
                setRequestLoading(false);
                navigate("/usuarios");
            }

        } catch(error) {
            const message = errorParser(error);
            setRequestLoading(false);
            setErrorMessage(message);
            setHasError(true);
        }
    })

    useEffect(() => {
        userService.getUserTypes()
            .then(data => setUserTypes(data));

        sucursalService.getSucursales()
            .then(data => setSucursales(data));
    },[]);

    return (
        <div>
            <h2 className="text-3xl my-4 font-semibold">Nuevo usuario</h2>
            <form className="grid grid-cols-2" onSubmit={submitSaveUser}>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Nombre</label>
                    <input {...register("nombre", {
                                required: "El nombre es requerido",
                                maxLength: 100
                            })}
                            aria-invalid={errors.nombre ? "true": "false"}
                            className="inputform"
                            placeholder="Nombre del usuario"/>
                    {errors.nombre?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.nombre.message}</p>
                    )}
                    {errors.nombre?.type === "maxLength" && (
                        <p className="col-start-2 valErrorText" role="alert">Maximo numero de caracteres es 100</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Username</label>
                    <input {...register("username", {
                                required: "El username es requerido",
                                maxLength: 50
                            })}
                            aria-invalid={errors.username ? "true": "false"}
                            className="inputform"
                            placeholder="Username"/>
                    {errors.username?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.username.message}</p>
                    )}
                    {errors.username?.type === "maxLength" && (
                        <p className="col-start-2 valErrorText" role="alert">Maximo numero de caracteres es 50</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Correo</label>
                    <input {...register("correo", {
                                required: "El correo el requerido",
                                maxLength: 40
                            })}
                            aria-invalid={errors.correo ? "true" : "false"}
                            className="inputform"
                            type="email"
                            placeholder="Correo electronico"/>
                    {errors.correo?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.correo.message}</p>
                    )}
                    {errors.correo?.type === "maxLength" && (
                        <p className="col-start-2 valErrorText" role="alert">Maximo numero de caracteres es 40</p>
                    )}
                </div>
                
                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Contraseña</label>
                    <input {...register("contrasena", {
                                required: "La contraseña es requerida"
                            })}
                            aria-invalid={errors.contrasena ? "true" : "false"}
                            className="inputform"
                            placeholder="Contrasena"/>
                    {errors.contrasena?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.contrasena.message}</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="sucursalId">Sucursal</label>
                    <select {...register("sucursalId", {
                                min: 1
                            })}
                            aria-invalid={errors.sucursalId ? "true" : "false"}
                            className="inputform"
                            id="sucursalId">
                        {sucursales.map((sucursal, index) => (
                            <option key={index} value={sucursal.id}>{sucursal.smallDir}</option>
                        ))}
                            <option hidden value={0} defaultChecked></option>
                    </select>
                    {errors.sucursalId?.type === "min" && (
                        <p className="col-start-2 valErrorText" role="alert">Debe seleccionar una sucursal</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Tipo de usuario</label>
                    <select {...register("tipoUsuarioId", {
                                min: 1
                            })}
                            aria-invalid={errors.tipoUsuarioId ? "true" : "false"}
                            className="inputform">
                        {userTypes.map((type, index) => (
                            <option key={index} value={type.id}>{type.tipo}</option>
                        ))}
                        <option hidden value={0} defaultChecked></option>
                    </select>
                    {errors.tipoUsuarioId?.type === "min" && (
                        <p className="col-start-2 valErrorText" role="alert">Debe seleccionar un tipo de usuario</p>
                    )}
                </div>

                <div className="flex justify-end col-span-2 m-2 gap-2">
                    {requestLoading
                    ? <LoadingRequest />
                    : <Button size="sm" color="success" className="w-20" type="submit">Registrar</Button>
                    }
                    <Link to="/usuarios">
                        <Button size="sm" color="neutral" className="w-20 mx-2">Cancelar</Button>
                    </Link>
                </div>

                {hasError && (
                    <ErrorMessage
                        errorMessage={errorMessage} />
                )}

            </form>
        </div>
    );
}