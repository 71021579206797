import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { FiltrosModel, CategoryModel, SucursalModel, ShowFiltros, UsuarioModel, ChecklistModel } from "../../types";
import { Estados, EstadosMantenimiento, EstadosSolicitud, urgenciaList } from "../../utilities/StateEnums";
import { useUser } from "../../hooks/useUser";
import { CategoriaServices,ChecklistServices, SucursalServices, UserServices } from "../../services";
import { EspecialidadDropMenuFilter } from "./EspecialidadDropMenuFilter";
import { DoctoresDropMenuFilter } from "./DoctoresDropMenuFilter";

interface Props {
    filtros: FiltrosModel
    setFiltros: Dispatch<SetStateAction<FiltrosModel>>
    showFiltros: ShowFiltros
    typeFilter: "Ticket" | "Solicitud" | "Mantenimiento" | "Checklist"
}

export function Filtros({ filtros, setFiltros, showFiltros, typeFilter }: Props) {

    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const categoriaService = new CategoriaServices(token);
    const sucursalService = new SucursalServices(token);
    const userService = new UserServices(token);
    const checklistService = new ChecklistServices(token);

    const [responsables, setResponsables] = useState<Array<UsuarioModel>>([]);
    const [categories, setCategoires] = useState<Array<CategoryModel>>([]);
    const estados = Object.values(typeFilter === "Ticket" ? Estados : 
        typeFilter === "Solicitud" ? EstadosSolicitud : EstadosMantenimiento);
    const [sucursales, setSucursales] = useState<Array<SucursalModel>>([]); 
    const [checklist, setChecklist] = useState<Array<ChecklistModel>>([]);
    
    const onFilterChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFiltros({
            ...filtros,
            [event.target.name]: event.target.value
        });
    }

    useEffect(() => {
        categoriaService.getCategories()
            .then(data => setCategoires(data));

        sucursalService.getSucursales()
            .then(data => setSucursales(data));

        checklistService.getListChecklist()
            .then(data => setChecklist(data));

        switch (typeFilter) {
            case "Mantenimiento":
                userService.getUsersByRole("Mantenimiento")
                    .then(data => setResponsables(data));
                break;

            case "Solicitud":
                userService.getUsersByRole("Compras")
                    .then(data => setResponsables(data));
                break;
        
            default:
                userService.getUsersByRole("Mantenimiento")
                    .then(data => setResponsables(data));
                break;
        }
    }, []);


    return (
        <div className="w-full p-2">
            <form className="grid grid-cols-4 w-full gap-y-3 sm:gap-y-2 gap-x-3 text-left">

                {showFiltros.fechas && (
                    <>
                    <div className="col-span-2 flex flex-col sm:flex-row">
                        <label className="w-full sm:w-1/3" htmlFor="fechaInicio">Fecha inicial:</label>
                        <input 
                            className="inputdate w-11/12 sm:w-2/3"
                            id="fechaInicio"
                            type="date"
                            name="fechaInicial"
                            onChange={onFilterChange}
                            value={filtros.fechaInicial}
                            color="primary"/>
                    </div>

                    <div className="col-span-2 flex flex-col sm:flex-row">
                        <label className="w-full sm:w-1/3" htmlFor="fechaFinal">Fecha final:</label>
                        <input 
                            className="inputdate w-11/12 sm:w-2/3"
                            id="fechaFinal"
                            type="date"
                            onChange={onFilterChange}
                            value={filtros.fechaFinal}
                            name="fechaFinal"
                            color="primary"/>
                    </div>
                    </>
                )}

                {showFiltros.categoria && (
                    <>
                    <div className="col-span-2 flex flex-col sm:flex-row">
                        <label className="w-full sm:w-1/3">Categoria:</label>
                        <select className="selectfilter w-11/12 sm:w-2/3"
                                name="categoriaId"
                                onChange={onFilterChange}>
                                <option value={0} defaultChecked>Todas las categorias</option>
                                {categories.map((category, index) => (
                                    <option key={index} value={category.id}>{category.nombreCategoria}</option>
                                ))}
                        </select>
                    </div>
                    </>
                )}

                {showFiltros.estado && (
                    <>
                    <div className="col-span-2 flex flex-col sm:flex-row">
                        <label className="w-full sm:w-1/3">Estado:</label>
                        <select className="selectfilter w-11/12 sm:w-2/3"
                                name="estado"
                                onChange={onFilterChange}>
                                <option value={""} defaultChecked>Todos los estados</option>
                                {estados.map((estado, index) => (
                                    <option key={index} value={estado}>{estado}</option>
                                ))}
                        </select>
                    </div>
                    </>
                )}

                {showFiltros.sucursal && (
                    <>
                    <div className="col-span-2 flex flex-col sm:flex-row">
                        <label className="w-full sm:w-1/3">Sucursal:</label>
                        <select className="selectfilter w-11/12 sm:w-2/3"
                                name="sucursalId"
                                onChange={onFilterChange}>
                                <option value={0} defaultChecked>Todos las sucursales</option>
                                {userContext.auth?.user?.tipoUsuario === "General" 
                                    ? (
                                    <option value={userContext.auth.user.sucursalId}>{userContext.auth.user.sucursalNombre}</option>
                                    )
                                    :sucursales.map((sucursal, index) => (
                                    <option key={index} value={sucursal.id}>{sucursal.smallDir}</option>
                                    ))
                                }
                        </select>
                    </div>
                    </>
                )}

                {showFiltros.responsable && (
                    <>
                    <div className="col-span-2 flex flex-col sm:flex-row">
                        <label className="w-full sm:w-1/3">Responsable:</label>
                        <select className="selectfilter w-11/12 sm:w-2/3"
                                name="responsableId"
                                onChange={onFilterChange}
                                >
                                <option value={0} defaultChecked>Todas los responsables</option>
                                {responsables.map((user, index) => (
                                    <option key={index} value={user.id}>{user.nombre}</option>
                                ))}
                        </select>
                    </div>
                    </>
                )}

                {showFiltros.urgencia && (
                    <>
                    <div className="col-span-2 flex flex-col sm:flex-row">
                        <label className="w-full sm:w-1/3">Urgencia:</label>
                        <select className="selectfilter w-11/12 sm:w-2/3"
                                name="urgencia"
                                onChange={onFilterChange}
                                >
                                <option value={""} defaultChecked>Todas las urgencias</option>
                                {urgenciaList.map((urgencia, index) => (
                                    <option key={index} value={urgencia}>{urgencia}</option>
                                ))}
                        </select>
                    </div>
                    </>
                )}

                {showFiltros.checklist && (
                    <>
                    <div className="col-span-2 flex flex-col sm:flex-row">
                        <label className="w-full sm:w-1/3">Lista:</label>
                        <select className="selectfilter w-11/12 sm:w-2/3"
                                name="checklistId"
                                onChange={onFilterChange}
                                >
                                <option value={""} defaultChecked>Todas las Listas</option>
                                {checklist.map((lista, index) => (
                                    <option key={index} value={lista.id}>{lista.nombre}</option>
                                ))}
                        </select>
                    </div>
                    </>
                )}

                {showFiltros.tipoDoctor && (
                    <div className="col-span-2 flex flex-col sm:flex-row">
                        <label className="w-full sm:w-1/3">Tipo de Doctor:</label>
                        <select className="selectfilter w-11/12 sm:w-2/3"
                                name="tipoDoctor"
                                onChange={onFilterChange}>
                                <option value={"todos"} defaultChecked>Todas los tipos</option>
                                <option value={"general"} defaultChecked>Generales</option>
                                <option value={"especialista"} defaultChecked>Especialistas</option>
                        </select>
                    </div>
                )}

                {showFiltros.especialidad && (
                    <EspecialidadDropMenuFilter 
                        setFiltros={setFiltros}
                        filtros={filtros}/>
                )}

                {showFiltros.doctores && (
                    <DoctoresDropMenuFilter
                        setFiltros={setFiltros}
                        filtros={filtros}/>
                )}

            </form>
        </div>
    );
}