import { useForm } from "react-hook-form";
import { CodePrefixRequest } from "../../../types";
import { Button } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../hooks/useUser";
import { EquipoServices } from "../../../services/equipos";

export function CodeEquipoCreateForm() {

    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";
    const navigate = useNavigate();

    const equipoService = new EquipoServices(token);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<CodePrefixRequest>();

    const submitSaveCode = handleSubmit((data: CodePrefixRequest) => {
        equipoService.saveCodePrefix(data)
            .then(response => {
                if(response.status === 201) {
                    navigate("/equipos");
                }
            })
            .catch(error => console.error(error));
    });

    return (
        <div>
            <h2 className="text-3xl my-4 font-semibold">Registrar Código para Equipos</h2>
            <form 
                className="grid grid-cols-2" 
                onSubmit={submitSaveCode} 
                autoComplete="off">

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="prefijo">Prefijo: </label>
                    <input {...register("prefijo", {
                            required: "Debe ingresar un prefijo",
                            maxLength: 10
                        })}
                        id="prefijo"
                        className="inputform"/>
                    {errors.prefijo?.type === "required" && (
                        <p className="valErrorText col-start-2" role="alert">{errors.prefijo.message}</p>
                    )}
                    {errors.prefijo?.type === "maxLength" && (
                        <p className="valErrorText col-start-2" role="alert">Número de caracteres permitidos: 10</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="significado">Significado: </label>
                    <input {...register("significado", {
                            required: "Debe ingresar el significado del prefijo",
                            maxLength: 100
                        })}
                        id="significado"
                        className="inputform"/>
                    {errors.significado?.type === "required" && (
                        <p className="valErrorText col-start-2" role="alert">{errors.significado.message}</p>
                    )}
                    {errors.significado?.type === "maxLength" && (
                        <p className="valErrorText col-start-2" role="alert">Número de caracteres permitidos: 100</p>
                    )}
                </div>

                <div className="flex justify-end col-span-2 m-2 gap-2">
                    <Button size="sm" color="success" className="w-20" type="submit">Registrar</Button>
                    <Button 
                        onClick={() => navigate(-1)}
                        size="sm" 
                        color="neutral" 
                        className="w-20 mx-2">
                            Cancelar
                    </Button>
                </div>
            </form>
        </div>
    );
}