import { ChangeEvent } from "react";
import { TypeGeneralId } from "../../../../utilities/StateEnums";

interface Props {
    handleCheckboxChange: (event: ChangeEvent<HTMLInputElement>) => void;
    isChecked: boolean;
    value: number;
    text: string;
    especialidad: number;
}

export function Checkbox({ handleCheckboxChange, value, text, especialidad, isChecked }: Props) {
    return (
        <div>
            <label 
                className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer">
                <input 
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    value={value}
                    className={`form-checkbox appearance-none h-4 w-4 text-indigo-600 
                    relative peer shrink-0 border-2 rounded-sm 
                    ${TypeGeneralId.includes(especialidad)
                        ? "border-[#ff638480] checked:border-[#ff638480]"
                        : "border-[#35a2eb80] checked:bg-[#35a2eb80]"
                    }
                    checked:hidden
                    focus:outline-none focus:ring-offset-0 focus:ring-2 focus:ring-blue-100
                    disabled:border-steel-400 disabled:bg-steel-400`}
                    type="checkbox"/>
                <svg
                    className={`
                        sticky 
                        w-4 h-4
                        hidden peer-checked:block
                        pointer-events-none 
                        ${TypeGeneralId.includes(especialidad)
                            ? "text-white bg-[#ff638480] rounded-sm"
                            : "text-white bg-[#35a2eb80] rounded-sm"
                        }
                        `}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    >
                    <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
                <span className="ml-2">{text}</span>
            </label>
        </div>
    );
}