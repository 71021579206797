import PersonIcon from '@mui/icons-material/Person';

interface Props {
    nombre: string;
}

export function DoctorTag({ nombre }: Props) {
    return (
        <div className="flex mx-2 border border-slate-200 rounded-lg w-fit items-center py-1 px-2">
            <PersonIcon sx={{ fontSize: "20px", color: "#334155" }}/>
            <p className="font-semibold text-sm">{ nombre }</p>
        </div>
    );
}