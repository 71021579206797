import axios from "axios";
import { BaseService } from "./BaseService";
import { BitacoraModel } from "../types";


export class BitacoraServices extends BaseService {

    /**
     * @param { string } token - Auth token for getting access to API Backend.
     */
    constructor(token: string) {
        super(token, "/api/bitacora");
    }

    /**
     * Get list of Bitacora registers of Mantenimieto's moves history
     * 
     * @param { string } [fechaInicial] - Optional filter for initial date range
     * @param { string } [fechaFinal] - Optional filter for end date range
     * 
     * @returns { Promise<Array<BitacoraModel>> } - Retruns a promise with a bitacora registers array 
     */
    public async getBitacoraList(fechaInicial?: string, fechaFinal?: string): Promise<Array<BitacoraModel>> {

        const url = this.genUrlWithQueryFilters({ url: "", fechaInicial, fechaFinal });

        const response = await this.axios.get<Array<BitacoraModel>>(url);
        const { data } = response;

        return data;
    }

    /**
     * Get list of Bitacora registers of Compras' moves history
     * 
     * @param { string } [fechaInicial] - Optional filter for initial date range
     * @param { string } [fechaFinal] - Optional filter for end date range
     * 
     * @returns { Promise<Array<BitacoraModel>>} - Retruns a promise with a bitacora registers array 
     */
    public async getBitacoraComprasList(fechaInicial?: string, fechaFinal?: string): Promise<Array<BitacoraModel>> {
        
        const url = this.genUrlWithQueryFilters({ url: "/compras", fechaInicial, fechaFinal });

        const response = await this.axios.get<Array<BitacoraModel>>(url);
        const { data } = response;

        return data;
    }

}