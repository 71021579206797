import { ChangeEvent, useEffect, useState } from "react";
import { CodePrefixResponse, EquipoModel, EquipoRequest } from "../../types";
import { useUser } from "../../hooks/useUser";
import { EquipoServices } from "../../services/equipos";
import { UseFormSetValue } from "react-hook-form";
import { EquipoCodeParser } from "../../utilities/equipoCodeParser";

interface Props {
    setValue: UseFormSetValue<EquipoRequest | EquipoModel>
    prefix: string,
    number: string
}

export function CodeInput({ setValue, prefix, number }: Props) {
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const equipoService = new EquipoServices(token);

    const [codePrefixList, setCodePrefixList] = useState<Array<CodePrefixResponse>>([]);

    const [prefijo, setPrefijo] = useState<string>(prefix);
    const [numero, setNumero] = useState<string>(number);

    const onCodeChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        if(event.target.name === "number-code") {
            setNumero(event.target.value);
        }

        if(event.target.name === "prefijo") {
            setPrefijo(event.target.value);
        }
    }

    useEffect(()=> {
        equipoService.getCodePrefixList()
            .then(data => setCodePrefixList(data));
    }, []);

    useEffect(() => {
        const code = EquipoCodeParser.parse(prefijo, numero);
        if(code !== null ) {
            setValue("codigo", code, { shouldDirty: true });
        }
    }, [prefijo, numero]);

    return (
    <div className="grid grid-cols-3 gap-2">
        <select
            onChange={onCodeChange}
            className="selectform col-span-2"
            name="prefijo"
            placeholder="Prefijo">
            <option hidden ></option>
            {codePrefixList.map((code, index) => (
                <option key={index} value={code.prefijo}>{`${code.prefijo} - ${code.significado}`}</option>
            ))}
        </select>
        <input 
            onChange={onCodeChange}
            className="inputform col-span-1"
            name="number-code"
            value={numero}
            type="number" />
    </div>
    );
}