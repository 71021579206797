import { MantenimientoHomeDashboard } from "./MantenimientoHomeDashboard";

export function MantenimientoDashboard() {
    return (
        <div>
            <h2 className="text-3xl my-4 font-semibold">Mantenimientos Preventivos</h2>
            <MantenimientoHomeDashboard
                filtrosShowed={{
                    fechas: false, 
                    categoria: true,
                    estado: true,
                    sucursal: true,
                    responsable: false
                }}/>
        </div>
    );
}