import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useForm } from "react-hook-form";
import { CreateTicket } from "../../types/ticket";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { Estados, Prioridad, Urgencia, prioridadList, urgenciaList } from "../../utilities/StateEnums";
import { Button } from "@mui/joy";
import { EquipoRevision } from "../../types/checklist";
import { prioridadDefault } from "../../utilities/prioridadDefault";

interface Props {
    informadorId: number;
    revision: EquipoRevision;
    setTicketToCreate: Dispatch<SetStateAction<CreateTicket[]>>;
    ticketToCreate: CreateTicket[];
    sucursalId: number;
}

export function RowCreateTicketFromChecklist({ sucursalId, informadorId, revision, setTicketToCreate, ticketToCreate }: Props) {
    const [showMiniForms, setShowMiniForms] = useState<boolean>(false);

    const {
        register,
        setValue,
        handleSubmit,
        getValues,
        formState: {
            errors
        }
    } = useForm<CreateTicket>({
        defaultValues: {
            sucursalId: sucursalId,
            equipoId: revision.equipoId ?? 0,
            informado: informadorId,
            estado: Estados.Abierto,
            titulo: "",
            descripcion: "",
            prioridad: Prioridad.Media,
            urgencia: Urgencia.TresDias,
            tareaId: revision.tareaId,
            isEquipoDefault: revision.isEquipoDefault,
            subcategoriaId: revision.subcategoriaId,
            imagen: null
        }
    });

    const searchTicket = (ticket: CreateTicket, deletedTicketData: CreateTicket) => {
        return (
            ticket.equipoId == deletedTicketData.equipoId
            && ticket.titulo == deletedTicketData.titulo
            && ticket.descripcion == deletedTicketData.descripcion
            && ticket.tareaId == deletedTicketData.tareaId
        );
    }

    const handleSelectEquipoForTicket = (event: ChangeEvent<HTMLInputElement>) => {
        if(event.target.checked) {
            setShowMiniForms(true);
        }
        else
        {
            const deletedTicketData = getValues(); 
            const index = ticketToCreate.findIndex(ticket => {
                return searchTicket(ticket, deletedTicketData);
            });

            if(index > -1) {
                ticketToCreate.splice(index, 1);
                setTicketToCreate(ticketToCreate);
            }
            setShowMiniForms(false);
        }
    }

    const handleChanges = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

        if(event.target.name === "urgencia") {
            setValue("prioridad", prioridadDefault(event.target.value), { shouldDirty: true, shouldTouch: true, shouldValidate: true });
        }

        if(event.target.name == "imagen") {
            const file = (event.target as HTMLInputElement)
            if(file != null && file.files != null) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const base64string = (e.target?.result as string).split(",")[1];
                    console.log(base64string);
                    setValue("imagen", base64string);
                };

                reader.readAsDataURL(file.files[0]);
            }
        }
    }

    const addTicket = handleSubmit((data: CreateTicket) => {
        setTicketToCreate([
            ...ticketToCreate,
            data
        ])
    });

    const ticket = getValues();
    const isTicketInList = ticketToCreate.find(ticketInList => searchTicket(ticketInList, ticket)) ? true : false;

    return (
        <div>
            <div className="flex items-center gap-2">
                <div className={`block transform transition-transform duration-300 ${showMiniForms ? 'rotate-90' : '-rotate-90 sm:rotate-0'}`}>
                    <KeyboardArrowRightIcon sx={{
                        width: "20px",
                        height: "20px"
                    }} />
                </div>
                <label htmlFor="">{revision.equipoName} {revision.estado}</label>
                <input 
                    type="checkbox" 
                    value={revision.equipoId ?? 0}
                    onChange={handleSelectEquipoForTicket}/>
            </div>

            {showMiniForms && (
                <form 
                    className="grid grid-cols-2 mx-5 my-2"
                    onSubmit={addTicket}
                    autoComplete="off">

                    <div className="grid grid-cols-2 justify-between col-span-2">
                        <label className="font-medium" htmlFor="">Título</label>
                        <input
                            {...register("titulo", {
                                required: "El titulo es requerido",
                                maxLength: 150
                            })}
                            className="inputform"
                            type="text"/>
                        {errors.titulo?.type === "required" && (
                            <p className="col-start-2 valErrorText" role="alert">{errors.titulo.message}</p>
                        )}
                        {errors.titulo?.type === "maxLength" && (
                            <p className="col-start-2 valErrorText" role="alert">Número de caracteres permitidos: 150</p>
                        )}
                    </div>

                    <div className="grid grid-cols-2 justify-between col-span-2">
                        <label className="font-medium" htmlFor="">Descripción</label>
                        <input 
                            {...register("descripcion")}
                            className="inputform"
                            type="text"/>
                    </div>

                    <div className="grid grid-cols-2 justify-between col-span-2">
                        <label className="font-medium">Prioridad</label>
                        <select {...register("prioridad")}
                                id="prioridad"
                                disabled={true}
                                className="inputform">
                                <option defaultChecked value={""} hidden></option>
                                {prioridadList.map((value, index) => (
                                    <option key={index} value={value}>{value}</option>
                                ))}
                        </select>
                    </div>

                    <div className="grid grid-cols-2 justify-between col-span-2">
                        <label className="font-medium" htmlFor="">Urgencia</label>
                        <select {...register("urgencia", {
                                    required: "La urgencia es requerida",
                                })}
                                aria-invalid={errors.urgencia ? "true" : "false"}
                                onChange={handleChanges}
                                name="urgencia"
                                id="urgencia"
                                className="inputform">
                                <option defaultChecked value={""} hidden></option>
                                {urgenciaList.map((value, index) => (
                                    <option key={index} value={value}>{value}</option>
                                ))}
                        </select>
                        {errors.urgencia?.type === "required" && (
                            <p className="col-start-2 valErrorText" role="alert">{errors.urgencia.message}</p>
                        )}
                    </div>

                    <div className="grid grid-cols-2 justify-between col-span-2">
                        <label className="font-medium">Archivo</label>
                        <input 
                            className="inputform"
                            onChange={handleChanges}
                            type="file"
                            color="primary"
                            name="imagen"/>
                    </div>

                <div className="flex justify-center col-span-2 m-2 gap-2">
                    {isTicketInList
                    ?
                    <Button
                        disabled={isTicketInList}
                        color="success"
                        type="submit">
                        Ticket Agregado
                    </Button>
                    :
                    <Button
                        color="success"
                        type="submit">
                        Agregar ticket
                    </Button>
                    }
                </div>

                </form>
            )}
        </div>
    );
}