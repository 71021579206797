
export const showData = async (url: any, setData: any) => {
    const response = await fetch(url);
    if(response.ok) {
        const data = await response.json();
        setData(data);
    }else {
        console.log("status code: " + response.status);
    }

}

export const formatDate = (date = new Date(), agregarDias: number = 0) => {
    const year = date.toLocaleString('default', { year: 'numeric'});
    const month = date.toLocaleString('default', { month: '2-digit'});
    const day = date.toLocaleString('default', { day: '2-digit'});

    return [year, month, day].join('-');
}