import { FiltrosMetricsModel, MetricsComprasResponse, MetricsFilter, MetricsResponse } from "../types";
import { BaseService } from "./BaseService";


export class MetricsServices extends BaseService {

    /**
     * @param { string } token - Auth token for getting access to API Backend.
     */
    constructor(token: string) {
        super(token, "/api/metrics")
    }

    /**
     * Get all metrics for "Tickets de mantenimiento" using FiltrosMetricsModel.
     * 
     * @param { FiltrosMetricsModel } filtros - Metrics filters.
     * @returns - Metrics of "Tickets de mantenimiento".
     */
    async getMetricsTicket(filtros: FiltrosMetricsModel) {

        const url = this.genUrlWithFilters("/mantenimiento", filtros);

        const response = await this.axios.get<MetricsResponse>(url)

        if(response.status === 200) {
            const { data } = response;
            return data;
        }
    }

    /**
     * Get all metrics for "Solicitud de compras" using FiltrosMetricsModel.
     * 
     * @param { FiltrosMetricsModel } filtros - Metrics filters.
     * @returns - Metrics of "Solicitud de compras".
     */
    async getMetricsCompras(filtros: FiltrosMetricsModel) {

        const url = this.genUrlWithFilters("/compras", filtros);

        const response = await this.axios.get<MetricsComprasResponse>(url)

        if(response.status === 200) {
            const { data } = response;
            return data;
        }
    }

    /**
     * Get metrics of created "Tickets".
     * 
     * @returns - Metrics of created "Tickets".
     */
    async getTicketsCreados() {
        const response = await this.axios.get("/mantenimiento/created")
        if(response.status === 200) {
            const { data } = response;
            return data;
        }
    }

    /**
     * Get metrics of finished "Tickets".
     * 
     * @returns - Metrics of finished "Tickets".
     */
    async getTicketsTerminados() {
        const response = await this.axios.get("/mantenimiento/terminados")
        if(response.status === 200) {
            const { data } = response;
            return data;
        }
    }

    /**
     * Get metrics of expired "Tickets".
     * 
     * @param { MetricsFilter } filters - Metrics filters.
     * @returns - Metrics of expired "Tickets".
     */
    async getTicketsVencidos(filters?: MetricsFilter) {

        let url = filters === undefined || filters.categoriaId === 0
            ? "/mantenimiento/vencidos"
            : "/mantenimiento/vencidos" + `?categoria=${filters.categoriaId}`

        const response = await this.axios.get(url)
        if(response.status === 200) {
            const { data } = response;
            return data;
        }
    }

    /**
     * Parse a url adding query params for metrics endpoint.
     * 
     * @param { string } url - Metrics enpoint Url.
     * @param { FiltrosMetricsModel } filters - Metrics filters. 
     * @returns { string } - Parsed url.
     */
    genUrlWithFilters(url: string, { 
            fechaInicial, 
            fechaFinal, 
            sucursalId, 
            responsableId,
            categoriaId, 
            subcategoriaId, 
            equipoId 
        }: FiltrosMetricsModel): string {

        let baseUrl: string = url + "?";

        if(fechaInicial !== undefined && fechaFinal !== undefined)
            baseUrl += `fechaInicial=${fechaInicial}&fechaFinal=${fechaFinal}`;

        if(sucursalId > 0)
            baseUrl += `&sucursalId=${sucursalId}`;

        if(responsableId > 0)
            baseUrl += `&responsableid=${responsableId}`;

        if(categoriaId > 0)
            baseUrl += `&categoriaId=${categoriaId}`;

        if(subcategoriaId > 0)
            baseUrl += `&subcategoriaId=${subcategoriaId}`;

        if(equipoId > 0)
            baseUrl += `&equipoId=${equipoId}`;

        return baseUrl;
    }
}