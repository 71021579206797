import { ReactNode } from "react";
import { Link } from "react-router-dom";

interface Props {
    open: boolean
    item: ReactNode
    url: string
}



export function DropMenuItem({ open, item, url }: Props) {
    return (
        <Link to={url}>
            <li className={`${open ? "block" : "opacity-0 hidden"} my-2 flex flex-col sm:flex-row cursor-pointer w-full sm:py-0 sm:h-10 px-3 sm:pl-0 sm:pr-0 sm:mt-2 rounded text-gray-400 sm:hover:bg-gray-700 hover:text-gray-300`}>
                <div className="flex">
                    <div className="flex flex-col sm:flex-row items-center w-full justify-between h-full">
                        {item}
                    </div>
                </div>
            </li>
        </Link>
    );
}