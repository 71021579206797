import { useRef, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/joy/Button/Button";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import { useUser } from "../../hooks/useUser";
import { Avatar, Divider, Dropdown, MenuButton, Sheet } from "@mui/joy";

interface Props {
    className: string;
}

export function MenuAccount({ className }: Props) {
    const { userContext } = useUser();
    const navigate = useNavigate();
    const buttonRef = useRef(null);
    const [open, setOpen] = useState(false);
    const { isLoggin, logout } = useUser();

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenChange = useCallback((event: React.SyntheticEvent | null, isOpen: boolean) => {
        setOpen(isOpen);
    },[],);

    const logoutHandler = () => {
        logout();
        navigate("/login");
    }

    return (
         <div className={className}>
            <Dropdown open={open} onOpenChange={handleOpenChange}>
                <MenuButton
                    startDecorator={
                        <Avatar sx={{
                            "--Avatar-size": "24px"
                        }}/>}
                    variant="plain"
                    color="primary"
                    size="sm">
                    {isLoggin 
                    ? <p className="hidden sm:block">{userContext.auth?.user?.username}</p>
                    : <p className="hidden sm:block">No User</p>
                    }
                </MenuButton>
                <Menu>
                    <Sheet sx={{
                        textAlign: "center",
                        mx: "8px",
                        my: "2px"
                    }}>
                        <p className="font-semibold">{userContext.auth?.user?.nombre}</p>
                    </Sheet>
                    <Divider/>

                    <Sheet sx={{
                        textAlign: "left",
                        mx: "8px",
                        my: "2px"
                    }}>
                        <Link to="/">
                            <p className="font-normal">Tickets</p>
                        </Link>
                    </Sheet>

                    <Sheet sx={{
                        textAlign: "left",
                        mx: "8px",
                        my: "2px"
                    }}>
                        <Link to="/agenda">
                            <p className="font-normal">Agenda</p>
                        </Link>
                    </Sheet>

                    <Divider/>

                    <MenuItem onClick={logoutHandler}>Cerrar Sesion</MenuItem>
                </Menu>
            </Dropdown>
        </div>
    );
}