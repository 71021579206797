import { EquipoCode, EquipoCodeSliced } from "../types";

export class EquipoCodeParser {

    public static parse(prefijo: string, number: string): EquipoCode {
        if(prefijo.length > 0 && number.length > 0)
            return `${prefijo}-${number}`;

        return null
    }

    public static deserializate(equipoCode: EquipoCode): EquipoCodeSliced | null {
        if(equipoCode === null)
            throw "equipoCode is null";

        const arrayString = equipoCode.split("-");
        return {
            prefix: arrayString[0], 
            number: arrayString[1]
        };
    }
}