import { ChangeEvent, Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { FiltrosModel } from "../../types";
import { especialidades } from "../../utilities/StateEnums";
import { Checkbox } from "./FiltrosComponents/AgendaFilter/Checkbox";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

interface Props {
    setFiltros: Dispatch<SetStateAction<FiltrosModel>>
    filtros: FiltrosModel;
}

export function EspecialidadDropMenuFilter({ setFiltros, filtros }: Props) {

    const [showDropMenuEspecialidad, setShowDropMenuEspecialidad] = useState<boolean>(false)
    const [selectedOptions, setSelectedOptions] = useState<Array<number>>([]);

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        setSelectedOptions((prevSelectedOptions): number[] =>
            prevSelectedOptions.includes(value)
                ? prevSelectedOptions.filter((option) => option !== value)
                : [...prevSelectedOptions, value]
        );
    };

    const cleanFilter = () => {
        setFiltros({
            ...filtros,
            especialidad: []
        });

        setSelectedOptions([]);
    }

    useEffect(() => {
        setFiltros({
            ...filtros,
            especialidad: selectedOptions
        });
    }, [selectedOptions])

    return (
        <div className="relative text-left col-span-2 flex flex-col sm:flex-row z-50">
            <label className="w-full sm:w-1/3">Especialidades:</label>
            <button 
                onClick={() => {
                    setShowDropMenuEspecialidad(!showDropMenuEspecialidad)
                    console.log(showDropMenuEspecialidad)
                }}
                id="dropdownButton" 
                type="button" 
                className={`inline-flex justify-center w-2/3 h-7 rounded-lg border border-cyan-500 shadow-sm bg-white text-sm font-medium text-sky-800 hover:bg-gray-50 focus:outline-none`} 
                aria-haspopup="true" 
                aria-expanded="true">
                <p className="pt-1">Seleccionar Especialidad</p>
                <svg 
                    className={`-mr-1 ml-2 h-7 w-5 ${!showDropMenuEspecialidad ? 'rotate-180' : '-rotate-90 sm:rotate-0'} block transform transition-transform duration-300`}
                    xmlns="http://www.w3.org/2000/svg" 
                    fill="none" viewBox="0 0 24 24" 
                    stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"/>
                </svg>
            </button>

            <div 
                id="dropdownMenu" 
                className={`${!showDropMenuEspecialidad ? "hidden" : ""} origin-top-right absolute right-0 top-6 mt-2 w-2/3 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5`}>
                <div className="py-1">

                    <div className="relative">
                        <label className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                            onClick={cleanFilter}>
                            <FilterAltOffIcon sx={{ "color": "#111827", "width": "22px" }}/>
                            <span className="ml-2 font-semibold italic">Eliminar filtros seleccionados</span>
                        </label>
                    </div>

                    {especialidades.map((esp, index) => (
                    <Fragment key={index}>
                        <Checkbox 
                            isChecked={selectedOptions.includes(esp.id)}
                            handleCheckboxChange={handleCheckboxChange}
                            value={esp.id}
                            text={esp.nombre}
                            especialidad={0}/>
                    </Fragment>
                    ))}

                </div>
            </div>
        </div>
    );
}