import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress  } from "@mui/joy";
import { useParams } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import { ComprasServices } from "../../services/compras";
import { Estados, EstadosSolicitud } from "../../utilities/StateEnums";
import { ModalContainer } from "../UtilsComponents/ModalContainer";
import { RechazarTicket } from "../TicketComponents/RechazarTicket";
import { RechazarSolicitud } from "./RechazarSolicitud";

    
interface Props {
    estado: string
    is_validated: boolean
    fechaAtencion: string
    setValue: any
    className: string
    previousUrl: string
    atendidoState: {
        atendidoState: boolean
        setAtendidoState: Dispatch<SetStateAction<boolean>>
    },
    cerradoState: {
        cerradoState: boolean
        setCerradoState: Dispatch<SetStateAction<boolean>>
    },
}

export function SolicitudChangeEstado({ 
    estado, 
    is_validated,
    fechaAtencion, 
    setValue, 
    className, 
    previousUrl, 
    atendidoState, 
    cerradoState, 
}: Props) {
    const { userContext } = useUser();
    const { id } = useParams(); 
    const navigate = useNavigate();
    const [showRechazar, setShowRechazar] = useState(false);
    const token = userContext.auth?.jwt ?? "";

    const comprasService = new ComprasServices(token);

    useEffect(() => {
        atendidoState.setAtendidoState(estado != "Aprobado" && estado != "Vencido");
        cerradoState.setCerradoState(estado === "Terminado");
    }, [estado]);

    const onClickAtendido = () => {
        return comprasService.updateStateSolicitud(id, EstadosSolicitud.Proceso)
            .then(response => {
                if(response.status === 200) {
                    setValue("estado", "En Proceso");
                    atendidoState.setAtendidoState(!atendidoState.atendidoState);
                }
            });
    }

    const onClickCerrar = () => {
        return comprasService.updateStateSolicitud(id, EstadosSolicitud.Terminado)
            .then(response => {
                if(response.status === 200){
                    setValue("estado","Terminado");
                    cerradoState.setCerradoState(!cerradoState.cerradoState);
                    navigate("/compras");
                }
            });
    }

    const onClickAprobar = () => {
        return comprasService.updateStateSolicitud(id, EstadosSolicitud.Aprobado)
            .then(response => {
                if(response.status === 200) {
                    navigate("/compras");
                }
            });
    }

    return (
        <>
        { !(estado === 'default')
        ? <div className={className}>

            { is_validated === true && estado !== EstadosSolicitud.Rechazado && estado !== EstadosSolicitud.Cancelado
                ? <>
                    <Button onClick={onClickAtendido}
                            size="sm"
                            disabled={atendidoState.atendidoState || fechaAtencion.length > 0}
                            color="primary">
                                Atender
                    </Button>

                    <Button onClick={onClickCerrar}
                            size="sm"
                            disabled={cerradoState.cerradoState}
                            color="warning">
                                Cerrar
                    </Button>
                </>
                : <></>
            }

            {/* Aprobar o rechazar solicitud - Only Admins - */}
            { userContext.auth?.user?.tipoUsuario === "Admin" && is_validated === false
                ? estado !== "Rechazado" && (
                    <>
                        <Button onClick={onClickAprobar}
                                size="sm"
                                color="success">
                            Aprobar
                        </Button>
                        <Button onClick={() => setShowRechazar(!showRechazar)}
                                size="sm"
                                color="danger">
                                    Rechazar
                        </Button>
                    </>)
                :   <></>
            }

            {  showRechazar && (
                /* Hay que cambiar el componente que se usa aqui */
                <ModalContainer>
                    <RechazarSolicitud cancelar={ showRechazar} setCancelar={setShowRechazar}/>
                </ModalContainer>
                )
            }

        </div>
        : <CircularProgress />
        }
        </>
    );
}