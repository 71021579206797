import { MantenimientoRequest, MantenimientoResponse } from "../types";
import { BaseService } from "./BaseService";


export class MantenimientoServices extends BaseService {

    /**
     * @param { string } token - Auth token for getting access to API Backend.
     */
    constructor(token: string) {
        super(token, "/api/mantenimiento");
    }

    /**
     * Get list of pending maintenances
     * 
     * @returns { Proimse<Array<MantenimientoResponse>> } - Returns a promise with a MantenimientoResponse array
     */
    public async getMantenimientosList(): Promise<Array<MantenimientoResponse>> {
        const response = await this.axios.get<Array<MantenimientoResponse>>("");
        const { data } = response;
        return data;
    }

    /**
     * Get list of maintenance register
     * 
     * @returns { Promise<Array<MantenimientoResponse>> } - Returns a promise with a maintenance registers array
     */
    public async getRegistrosMantenimientoList(fechaInicial?: string, fechaFinal?: string): Promise<Array<MantenimientoResponse>> {
        const response = await this.axios.get<Array<MantenimientoResponse>>("/consulta", {
            params: { fechaInicial, fechaFinal}
        });
        const { data } = response;
        return data;
    }

    /**
     *  Get maintenance data of a equipo by its id
     * 
     * @param { string | undefined} equipoId - Equipo id
     * @returns { Promise<MantenimientoResponse> } - Returns a promise with maintenance equipo data
     */
    public async getMantenimientoByEquipoId(equipoId: string | undefined): Promise<MantenimientoResponse> {
        const response = await this.axios.get<MantenimientoResponse>(`/${equipoId}`)
        const { data } = response;
        return data;
    }

    /**
     * Get maintenance register by its id
     * 
     * @param { string | undefined } id - Register id
     * @returns { Promise<MantenimientoResponse> } - Returns a promise with register maintenance data
     */
    public async getRegistroMantenimiento(id: string | undefined): Promise<MantenimientoResponse> {
        const response = await this.axios.get<MantenimientoResponse>(`/consulta/${id}`)
        const { data } = response 
        return data;
    }

    /**
     * Save a new maintenance register
     * 
     * @param { MantenimientoRequest } registro - Data of the new maintenance register
     * @returns 
     */
    public async saveNewMantenimientoRegistro(registro: MantenimientoRequest) {
        return await this.axios.post(
            "" , 
            registro,
            { headers: {
                'Content-Type': 'multipart/form-data'
            }});
    }
}