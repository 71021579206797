import { useState, useEffect } from "react";
import { EquipoModel, FiltrosModel } from "../../types";
import { EditButton } from "../../utilities/UtilsComponents/EditButton";
import Table from "@mui/joy/Table";
import { useUser } from "../../hooks/useUser";
import { EquipoServices, FiltroServices } from "../../services";
import { ColorEquipoState } from "../UtilsComponents/ColorEquipoState";
import { LoadingComponent } from "../UtilsComponents/LoadingComponent";
import { EquipoFilters } from ".";
import { formatDate } from "../../utilities/showData";
import { ElementsNotFound } from "../UtilsComponents";

export function EquipoTable() {
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const equipoService = new EquipoServices(token);

    const [equipos, setEquipos] = useState<Array<EquipoModel>>([]);
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [filtros, setFiltros] = useState<FiltrosModel>({
        fechaInicial: formatDate(new Date(new Date().setDate(new Date().getDate() - 7))),
        fechaFinal: formatDate(new Date()),
        categoriaId: 1,
        sucursalId: 2,
        estado: "",
        responsableId: 0,
        subcategoriaId: 1,
        urgencia: "",
        checklistId: 0
    });

    useEffect(()=> {
        setDataLoading(true);

        equipoService.getEquipos(filtros.sucursalId, filtros.subcategoriaId, filtros.categoriaId)
            .then(data => {
                const equiposFilter = FiltroServices.filtrosEquipos(data, filtros);
                setEquipos(equiposFilter);
                setDataLoading(false);
            })

    }, [filtros]);


    return (
        <>
        <EquipoFilters
            filtros={filtros}
            setFiltros={setFiltros}/>

        {dataLoading
        ? <LoadingComponent />
        :
        <div className="overflow-auto max-h-[600px] rounded-lg border border-slate-200 my-4">
        <Table
            stickyHeader
            sx={(theme) => ({
                "th": {bgcolor: "#111827", color: "#fff"}
            })}>

            <table className="w-full">
                <thead>
                    <tr>
                        <th>Código</th>
                        <th>Nombre</th>
                        <th>Numero de serie</th>
                        <th>Departamento</th>
                        <th>Categoría</th>
                        <th>Subcategoría</th>
                        <th>Sucursal</th>
                        <th>Estado</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {equipos.length > 0 &&
                    equipos.map((equipo, index)=> {
                        return (
                        <tr key={index} className={`hover:bg-slate-100`}>
                            <td>{equipo.codigo}</td>
                            <td>{equipo.nombre}</td>
                            <td>{equipo.numeroSerie}</td>
                            <td>{equipo.departamento}</td>
                            <td>{equipo.nombreCategoria}</td>
                            <td>{equipo.subcategoria.nombreSubcategoria}</td>
                            <td>{equipo.sucursal.smallDir}</td>
                            <td>
                                <ColorEquipoState state={equipo.activo} />
                            </td>
                            <td>
                                <EditButton url={`/equipos/${equipo.id}`} />
                            </td>
                        </tr>
                    )})
                }
                </tbody>
            </table>

        </Table>

        {equipos.length == 0 && (
            <ElementsNotFound />
        )}

        </div>
        }
        </>
    );
} 