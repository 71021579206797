import { Dispatch, SetStateAction, createContext, useState} from "react";
import { AuthUserResponse } from "../types";

interface AuthProps {
    user: AuthUserResponse | null
    jwt: string | null
}

interface Context {
    jwt: string | null
    setJwt: any | null
    user: any | null
    setUser: any | null
    auth: AuthProps | null
    setAuth: Dispatch<SetStateAction<AuthProps>> | null
}


export const UserContext = createContext<Context>({
    jwt: null,
    setJwt: null,
    user: null,
    setUser:  null,
    auth:  null,
    setAuth: null
    
});

export function UserContextProvider({children}:any) {
    const [jwt, setJwt] = useState<string | null>(
        () => window.sessionStorage.getItem("jwt")
    );
    const [user, setUser] = useState(
        () => {
            const user = window.sessionStorage.getItem("user");
            if(user == null)
                return null;
            JSON.parse(user)
            return user;
        }
    );
    const [auth, setAuth] = useState<AuthProps>({
        user: null,
        jwt: null
    });

    return <UserContext.Provider value={{
        jwt, 
        setJwt,
        user,
        setUser,
        auth,
        setAuth
        }}>
        {children}
    </UserContext.Provider>
}
