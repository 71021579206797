import { useEffect, useState } from "react";
import { FiltrosMetricsModel, Metrics, MetricsComprasResponse } from "../../types";
import { formatDate } from "../../utilities/showData";
import { FiltrosMantenimiento } from "../UtilsComponents/FiltrosMetricas/FiltrosMantenimiento";
import { useUser } from "../../hooks/useUser";
import { CircularProgress } from "@mui/joy";
import { CategoriaServices } from "../../services";
import { MetricsServices } from "../../services/metrics";
import { BarChartContainer } from "./GraphicsComponents/BarComponents/BarChartContainer";
import { BarStackedChartContainer } from "./GraphicsComponents/BarComponents/BarStackedChartContainer";

export function MetricsCompras() {

    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const [loading, setLoading] = useState(false);

    const categoriaService = new CategoriaServices(token);
    const metricsService = new MetricsServices(token);

    const [dataMetrics, setDataMetrics] = useState<MetricsComprasResponse>({
        comprasCerrados: new Map<string, Metrics>([]),
        comprasCreados: new Map<string, Metrics>([]),
        comprasVencidos: new Map<string, Metrics>([]),
        comprasRechazadas: new Map<string, Metrics>([]),
        comprasCanceladas: new Map<string, Metrics>([]),
        comprasTiempoPromedioCierre: { labels: [], data: [], total: [], listIds: [] },
        comprasTiempoPromedioAtencion: { labels: [], data: [], total: [], listIds: [] },
        solicitudData: { 
            solicitudCreadas: 0, 
            solicitudVencidas: 0, 
            solicitudNoVencidas: 0, 
            solicitudCerradas: 0 ,
            solicitudCanceladas: 0,
            solicitudRechazadas: 0,
        }
    });

    const [filtros, setFiltros] = useState<FiltrosMetricsModel>({
        fechaInicial: formatDate(new Date(new Date().setDate(1))),
        fechaFinal: formatDate(new Date()),
        sucursalId: 0,
        responsableId: 0,
        categoriaId: 0,
        subcategoriaId: 0,
        equipoId: 0
    });

    useEffect(() => {
        setLoading(true)

        metricsService.getMetricsCompras(filtros)
            .then(dataResponse => {
                if(dataResponse !== undefined) {
                    setDataMetrics(dataResponse);
                    setLoading(false);
                }
            });

    }, [filtros])

    return (
        <div>

            <h2 className="text-3xl my-4 font-semibold">Métricas de Compras</h2>
            <div>
                <FiltrosMantenimiento
                    filtros={filtros}
                    setFiltros={setFiltros} />
                <div>
                    <div className="flex flex-row">

                        <div className="flex gap-4">
                            <p className="rounded-lg p-2 m-2 bg-white shadow-lg font-semibold">Total de Tickets {dataMetrics.solicitudData.solicitudCreadas}</p>
                            <p className="rounded-lg p-2 m-2 bg-white shadow-lg font-semibold">Total Vencidos {dataMetrics.solicitudData.solicitudVencidas}</p>
                            <p className="rounded-lg p-2 m-2 bg-white shadow-lg font-semibold">Total No Vencidos {dataMetrics.solicitudData.solicitudNoVencidas}</p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-row flex-wrap">
                    <div className="rounded-lg p-2 m-2 bg-white shadow-lg">
                        <h2 className="text-xl my-4 font-semibold">Solicitudes de Compra Creadas (Total: {dataMetrics.solicitudData.solicitudCreadas})</h2>
                        {!loading 
                            ?
                            <div>
                                <BarChartContainer 
                                    metricType="Compras"
                                    color="DefaultColor"
                                    width={400} 
                                    height={300} 
                                    showGraphics={{
                                        sucursal: true,
                                        categoria: true,
                                        subcategoria: true,
                                        equipo: false,
                                        responsable: false
                                    }}
                                    numberType="Integer"
                                    dataMetrics={(new Map<string, Metrics>(Object.entries(dataMetrics.comprasCreados)))}/>
                            </div>
                            : <div className="w-[400px] h-[300px] flex justify-center items-center"><CircularProgress /></div>
                        }
                    </div>

                    <div className="rounded-lg p-2 m-2 bg-white shadow-lg">
                        <h2 className="text-xl my-4 font-semibold">Solicitudesd de Compras Cerradas (Total: {dataMetrics.solicitudData.solicitudCerradas})</h2>
                        {!loading 
                            ? dataMetrics.comprasCerrados !== undefined 
                                ? <BarChartContainer
                                    metricType="Compras"
                                    color="DefaultColor"
                                    width={400} 
                                    height={300} 
                                    showGraphics={{
                                        sucursal: true,
                                        categoria: true,
                                        subcategoria: true,
                                        equipo: false,
                                        responsable: true
                                    }}
                                    numberType="Integer"
                                    dataMetrics={(new Map<string, Metrics>(Object.entries(dataMetrics.comprasCerrados)))}/>
                                : <div className="flex items-center justify-center w-[400px] h-[260px]">
                                    <p className="text-lg my-4 font-semibold text-center items-center">Sin Datos</p>
                                </div> 
                            : <div className="w-[400px] h-[300px] flex justify-center items-center"><CircularProgress /></div>
                        }
                    </div>

                    <div className="rounded-lg p-2 m-2 bg-white shadow-lg">
                        <h2 className="text-xl my-4 font-semibold">Solicitudesd de Compras Rechazadas (Total: {dataMetrics.solicitudData.solicitudRechazadas})</h2>
                        {!loading 
                            ? dataMetrics.comprasRechazadas !== undefined 
                                ? <BarChartContainer 
                                    metricType="Compras"
                                    color="DefaultColor"
                                    width={400} 
                                    height={300} 
                                    showGraphics={{
                                        sucursal: true,
                                        categoria: true,
                                        subcategoria: true,
                                        equipo: false,
                                        responsable: false 
                                    }}
                                    numberType="Integer"
                                    dataMetrics={(new Map<string, Metrics>(Object.entries(dataMetrics.comprasRechazadas)))}/>
                                : <div className="flex items-center justify-center w-[400px] h-[260px]">
                                    <p className="text-lg my-4 font-semibold text-center items-center">Sin Datos</p>
                                </div> 
                            : <div className="w-[400px] h-[300px] flex justify-center items-center"><CircularProgress /></div>
                        }
                    </div>

                    <div className="rounded-lg p-2 m-2 bg-white shadow-lg">
                        <h2 className="text-xl my-4 font-semibold">Solicitudes de Compras Canceladas: (Total: {dataMetrics.solicitudData.solicitudCanceladas})</h2>
                        {!loading 
                            ? dataMetrics.comprasCanceladas !== undefined 
                                ? <BarChartContainer
                                    metricType="Compras"
                                    color="DefaultColor"
                                    width={400} 
                                    height={300} 
                                    showGraphics={{
                                        sucursal: true,
                                        categoria: true,
                                        subcategoria: true,
                                        equipo: false,
                                        responsable:false 
                                    }}
                                    numberType="Integer"
                                    dataMetrics={(new Map<string, Metrics>(Object.entries(dataMetrics.comprasCanceladas)))}/>
                                : <div className="flex items-center justify-center w-[400px] h-[260px]">
                                    <p className="text-lg my-4 font-semibold text-center items-center">Sin Datos</p>
                                </div> 
                            : <div className="w-[400px] h-[300px] flex justify-center items-center"><CircularProgress /></div>
                        }
                    </div>
                    <div className="rounded-lg p-2 m-2 bg-white shadow-lg">
                        <h2 className="text-xl my-4 font-semibold w-[400px]">Horas Promedio de Cierre de Solicitudes de Compras</h2>
                        {!loading 
                            ?   <BarChartContainer
                                    metricType="Compras"
                                    color="DefaultColor"
                                    width={400} 
                                    height={300} 
                                    showGraphics={{
                                        sucursal: false,
                                        categoria: false,
                                        subcategoria: false,
                                        equipo: false,
                                        responsable: true
                                    }}
                                    numberType="Float"
                                    dataMetrics={(new Map<string, Metrics>(Object.entries(dataMetrics.comprasTiempoPromedioCierre)))}/>
                                : <div className="flex items-center justify-center w-[400px] h-[260px]">
                                    <p className="text-lg my-4 font-semibold text-center items-center">Sin Datos</p>
                                </div> 
                        }
                    </div>


                    <div className="rounded-lg p-2 m-2 bg-white shadow-lg">
                        <h2 className="text-xl my-4 font-semibold w-[400px]">Horas Promedio de Atencion de Tickets</h2>
                        {!loading 
                                ? <BarChartContainer
                                    metricType="Compras"
                                    color="DefaultColor"
                                    width={400} 
                                    height={300} 
                                    showGraphics={{
                                        sucursal: false,
                                        categoria: false,
                                        subcategoria: false,
                                        equipo: false,
                                        responsable: true
                                    }}
                                    numberType="Float"
                                    dataMetrics={(new Map<string, Metrics>(Object.entries(dataMetrics.comprasTiempoPromedioAtencion)))}/>
                                : <div className="flex items-center justify-center w-[400px] h-[260px]">
                                    <p className="text-lg my-4 font-semibold text-center items-center">Sin Datos</p>
                                </div> 
                        }
                    </div>

                    <div className="rounded-lg p-2 m-2 bg-white shadow-lg">
                        <h2 className="text-xl my-4 font-semibold w-[400px]">Porcentaje de Solicitudes de Compra Vencidas (Total: {dataMetrics.solicitudData.solicitudVencidas})</h2>
                        {!loading 
                            ? dataMetrics.comprasVencidos !== undefined 
                            ?
                            <BarStackedChartContainer
                                metricType="Compras"
                                color="VencidosColor"
                                width={400} 
                                height={300} 
                                showGraphics={{
                                    sucursal: true,
                                    categoria: true,
                                    subcategoria: true,
                                    equipo: false,
                                    responsable: true
                                }}
                                numberType="Porcent"
                                dataMetrics={(new Map<string, Metrics>(Object.entries(dataMetrics.comprasVencidos)))}/> 
                                : <div className="flex items-center justify-center w-[400px] h-[260px]">
                                    <p className="text-lg my-4 font-semibold text-center items-center">Sin Datos</p>
                                </div> 
                            : <div className="w-[400px] h-[300px] flex justify-center items-center"><CircularProgress /></div>
                        }
                    </div>


                </div>
            </div>
        </div>
    );
}