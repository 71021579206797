import { useEffect, useState } from "react";
import { Table } from "@mui/joy";
import { AddButon } from "../../utilities/UtilsComponents/AddButon";
import { useUser } from "../../hooks/useUser";
import { ChecklistServices } from "../../services";
import { ChecklistModel } from "../../types";
import { EditButton } from "../../utilities/UtilsComponents/EditButton";
import { ElementsNotFound, LoadingComponent } from "../UtilsComponents";

export function ListHome() {
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const checklistService = new ChecklistServices(token);

    const [checklist, setChecklist] = useState<Array<ChecklistModel>>([]);
    const [dataLoading, setDataLoading] = useState<boolean>(true);

    useEffect(() => {
        checklistService.getListChecklist()
            .then(data => {
                setChecklist(data);
                setDataLoading(false);
            });
    }, []);

    return (
        <div className="w-full">

            <h2 className="text-3xl my-4 font-semibold">Listas de Revisiones</h2>

            <AddButon url="/revision/create"/>

            {dataLoading
            ? <LoadingComponent />
            : 
            <div className="overflow-auto max-h-[600px] rounded-lg border border-slate-200 my-4">
                <Table
                    stickyHeader
                    sx={(theme) => ({
                        "th": {bgcolor: "#111827", color: "#ffffff"}
                    })}>
                    <thead>
                        <tr>
                            <th className="w-20">Folio</th>
                            <th>Nombre</th>
                            <th>Hora limite</th>
                            <th className="w-24">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {checklist.map((list, index) => (
                            <tr key={index} className="hover:bg-slate-100">
                                <td>{list.id}</td>
                                <td>{list.nombre}</td>
                                <td>
                                    {list.horaLimite && (
                                    <input 
                                        className="bg-inherit"
                                        type="time" 
                                        value={list.horaLimite} 
                                        disabled/>
                                    )}
                                </td>
                                <td>
                                    <EditButton url={`/revision/${list.id}`} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                {checklist.length == 0 && (
                    <ElementsNotFound />
                )}

            </div>
            }
        </div>
    );
}