import { useContext } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import { UserContext } from "../../context/UserConetxt";

interface AuthProps {
    allowRole: Array<string>
}

export function RequireAuth({ allowRole }: AuthProps) {
    const { auth } = useContext(UserContext);
    const location = useLocation();
    return (
        //auth?.user?.tipoUsuario === allowRole ?
        allowRole.find(role => role === auth?.user?.tipoUsuario)
        ? <Outlet />
        : auth?.user
            ? <Navigate to="/unauthorizate" state={{ from: location }} replace />
            : <Navigate to="/login" state={{ from: location }} replace />
    );
}