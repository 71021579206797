import { useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { TipoUsuarioModel, UsuarioModel } from "../../types";
import { Button } from "@mui/joy";
import { useUser } from "../../hooks/useUser";
import { UserServices } from "../../services";
import { LoadingScreen } from "../UtilsComponents/LoadingScreen";
import { LoadingRequest, ErrorMessage } from "../UtilsComponents";
import { errorParser } from "../../utilities";
import { ModalContainer } from "../UtilsComponents/ModalContainer";
import { ChangeUserStatus } from "./ChangeUserStatus";
import { ROLES } from "../../utilities/StateEnums";

export function UserDetails(){
    const navigate = useNavigate();
    const { id } = useParams();
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const userService = new UserServices(token);

    const [userTypes, setUserTypes] = useState<Array<TipoUsuarioModel>>([])
    const [dataLoading, setDataLoading] = useState<boolean>(true);

    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [requestLoading, setRequestLoading] = useState<boolean>(false);
    const [showDeactivateUser, setShowDeactivateUser] = useState<boolean>(false);

    const { 
        register,
        handleSubmit,
        getValues,
        formState: {
            errors,
            isDirty,
        } 
    } = useForm<UsuarioModel>({
        defaultValues: async () => {
            const user = await userService.getUserById(id)

            const userTypes = userService.getUserTypes()
                .then(data => setUserTypes(data));

            Promise.all([user, userTypes])
                .then(_ => setDataLoading(false));

            return user
        }
    })
    const userStatus: boolean = getValues("isActive");
    const idUser: number = getValues("id");
    
    const submitUpdateUser = handleSubmit(async (data: UsuarioModel) => {
        try {
            setHasError(false);
            setRequestLoading(true);

            const response = await userService.updateUser(data)

            if(response.status === 200) {
                setRequestLoading(false);
                navigate("/usuarios");
            }

        } catch(error) {
            const message = errorParser(error);
            setRequestLoading(false);
            setErrorMessage(message);
            setHasError(true);
        }
    });

    const submitDeleteUser = async (id: string | undefined) => {
        userService.deactivateUser(id)
            .then(response => {
                if(response.status === 204)
                    navigate("/usuarios");
            })
            .catch(error => console.error(error));
    }

    return (
        <>
        {dataLoading
        ? <LoadingScreen />
        :
        <div>
            <h2 className="text-3xl my-4 font-semibold">Detalles</h2>
            <form className="grid grid-cols-2" onSubmit={submitUpdateUser}>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Nombre</label>
                    <input {...register("nombre", {
                                required: "El nombre es requerido",
                                maxLength: 100
                            })}
                            aria-invalid={errors.nombre ? "true": "false"}
                            className="inputform"
                            placeholder="Nombre del usuario"/>
                    {errors.nombre?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.nombre.message}</p>
                    )}
                    {errors.nombre?.type === "maxLength" && (
                        <p className="col-start-2 valErrorText" role="alert">Maximo numero de caracteres es 100</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Username</label>
                    <input {...register("username", {
                                required: "El username es requerido",
                                maxLength: 50
                            })}
                            aria-invalid={errors.username ? "true": "false"}
                            className="inputform"
                            placeholder="Username"/>
                    {errors.username?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.username.message}</p>
                    )}
                    {errors.username?.type === "maxLength" && (
                        <p className="col-start-2 valErrorText" role="alert">Maximo numero de caracteres es 50</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="sucursal">Sucursal</label>
                    <input {...register("sucursal.smallDir")} 
                            id="sucursal"
                            disabled={true}
                            className="inputform"/>

                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Correo</label>
                    <input {...register("correo", {
                                required: "El correo el requerido",
                                maxLength: 40
                            })}
                            aria-invalid={errors.correo ? "true" : "false"}
                            className="inputform"
                            type="email"
                            placeholder="Correo electronico"/>
                    {errors.correo?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.correo.message}</p>
                    )}
                    {errors.correo?.type === "maxLength" && (
                        <p className="col-start-2 valErrorText" role="alert">Maximo numero de caracteres es 40</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Tipo de usuario</label>
                    <select {...register("tipoUsuarioId", {
                                min: 1
                            })}
                            aria-invalid={errors.tipoUsuarioId ? "true" : "false"}
                            className="inputform">
                        {userTypes.map((type, index) => (
                            <option key={index} value={type.id}>{type.tipo}</option>
                        ))}
                        <option hidden value={0} defaultChecked></option>
                    </select>
                    {errors.tipoUsuarioId?.type === "min" && (
                        <p className="col-start-2 valErrorText" role="alert">Debe seleccionar un tipo de usuario</p>
                    )}
                </div>


                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Estatus del Usuario</label>
                    <input className="inputform"
                            disabled={true}
                            value={userStatus ? "Activo" : "Desactivo"}/>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <div className="w-full">
                        <Link to={`change-password`}>
                        <p 
                        className="italic cursor-pointer no-underline hover:underline hover:text-blue-900">
                        Cambiar contraseña
                        </p>
                        </Link>
                    </div> 
                    <div className="w-fit"></div>
                </div>

                <div className="flex justify-end col-span-2 m-2 gap-2 ">
                    {requestLoading
                    ? <LoadingRequest />
                    : <Button size="sm" 
                            disabled={!isDirty}
                            color="success" 
                            className="w-20" 
                            type="submit">
                        Actualizar
                    </Button>
                    }
                    {[ROLES.ADMIN]
                        .find( role => role === userContext.auth?.user?.tipoUsuario) && (
                            <>
                            {userStatus 
                            ? <Button 
                                size="sm" 
                                color="danger" 
                                className="w-20 mx-2" 
                                onClick={() => setShowDeactivateUser(!showDeactivateUser)}>
                                Desactivar
                            </Button>
                            : <Button 
                                size="sm" 
                                color="primary" 
                                className="w-20 mx-2" 
                                onClick={() => setShowDeactivateUser(!showDeactivateUser)}>
                                Activar
                            </Button>
                            }
                            </>
                    )}
                    <Link to="/usuarios">
                        <Button size="sm" color="neutral" className="w-20 mx-2">Regresar</Button>
                    </Link>
                </div>

                {hasError && (
                    <ErrorMessage 
                        errorMessage={errorMessage} />
                )}

            </form>

            {showDeactivateUser && (
                <ModalContainer>
                    <ChangeUserStatus 
                        showModal={showDeactivateUser}
                        setShowModal={setShowDeactivateUser}
                        state={userStatus}
                        idUser={idUser.toString()}
                        deactivateFunction={submitDeleteUser}/>

                </ModalContainer>
            )}

        </div>
        }
        </>
    );
}