import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import { UserContextProvider } from "./context/UserConetxt";
import { RequireAuth } from "./components/AuthComponents/RequireAuth";
import { NotFound } from './components/UtilsComponents/NotFound';
import { AgendaLayout } from './components/AgendaComponents/AgendaLayout';
import { VistaGenearl } from './components/AgendaComponents/VistaGeneral';

enum ROLES {
  ADMIN = "Admin",
  GENERAL = "General",
  MANTENIMIENTO = "Mantenimiento",
  COMPRAS = "Compras",
  RESPONSABLE_MAN = "Responsable-Mantenimiento",
  RESPONSABLE_COMP = "Responsable-Compras"
}

export default function App(){
    return (
        <UserContextProvider>

            <Routes>
            {/* Public */}
            <Route path="/login" element={AppRoutes.auth.login.element}/>
            <Route path={AppRoutes.auth.unauth.path} element={AppRoutes.auth.unauth.element}/>

            {/* Authorization */}
            <Route path='/' element={<Layout/>}>
                {/* Ticket */}
                <Route element={<RequireAuth allowRole={[ROLES.ADMIN, ROLES.MANTENIMIENTO, ROLES.GENERAL, ROLES.RESPONSABLE_MAN]}/>}>
                    {/* Dashboard */}
                    <Route path='/' element={AppRoutes.home.element}/>

                    <Route path={AppRoutes.ticket.home.path}>
                        <Route index={true} element={AppRoutes.ticket.home.element}/>
                        {AppRoutes.ticket.nested.map((route, index) => {
                        return <Route key={index} path={route.path}element={route.element} />;
                        })}
                    </Route>

                    {/* Bitacora */}
                    <Route path={AppRoutes.bitacora.home.path}>
                        <Route index={true} element={AppRoutes.bitacora.home.element}/>
                    </Route>

                    {/* Metricas Tickets */}
                    <Route path={AppRoutes.metrics.mantenimiento.path} element={AppRoutes.metrics.mantenimiento.element}/>

                </Route>


                {/* Mantenimiento Preventivo */}
                <Route element={<RequireAuth allowRole={[ROLES.ADMIN, ROLES.MANTENIMIENTO, ROLES.RESPONSABLE_MAN]}/>}>
                    <Route path={AppRoutes.mantenimiento.home.path}>
                        <Route index={true} element={AppRoutes.mantenimiento.home.element}/>
                        {AppRoutes.mantenimiento.nested.map((route, index) => {
                        return <Route key={index} path={route.path}element={route.element} />;
                        })}
                    </Route>
                </Route>

                {/* Compras */}
                <Route element={<RequireAuth allowRole={[ROLES.ADMIN, ROLES.COMPRAS, ROLES.GENERAL, ROLES.RESPONSABLE_COMP, ROLES.MANTENIMIENTO]}/>}>
                    <Route path={AppRoutes.solicitudes.home.path}>
                        <Route index={true} element={AppRoutes.solicitudes.home.element}/>
                        {AppRoutes.solicitudes.nested.map((route, index) => {
                        return <Route key={index} path={route.path} element={route.element} />;
                        })}
                    </Route>

                    {/* Bitacora Compras */}
                    <Route path={AppRoutes.bitacoraCompras.home.path}>
                        <Route index={true} element={AppRoutes.bitacoraCompras.home.element}/>
                    </Route>

                    {/* Metricas Compras */}
                    <Route path={AppRoutes.metrics.compras.path} element={AppRoutes.metrics.compras.element}/>
                </Route>

                {/* Checklist */}
                <Route element={<RequireAuth allowRole={[ROLES.ADMIN, ROLES.RESPONSABLE_MAN, ROLES.MANTENIMIENTO, ROLES.GENERAL]}/>}>
                    <Route path={AppRoutes.checklist.home.path}>
                        <Route index={true} element={AppRoutes.checklist.home.element} />
                        {AppRoutes.checklist.nested.map((route, index) => {
                        return <Route key={index} path={route.path}element={route.element} />;
                        })}
                    </Route>
                </Route>

                <Route element={<RequireAuth allowRole={[ROLES.ADMIN, ROLES.RESPONSABLE_MAN]}/>}>
                    {/* Usuario */}
                    <Route element={<RequireAuth allowRole={[ROLES.ADMIN]}/>}>
                        <Route path={AppRoutes.usuario.home.path}>
                            <Route index={true} element={AppRoutes.usuario.home.element}/>
                            {AppRoutes.usuario.nested.map((route, index) => {
                            return <Route key={index} path={route.path}element={route.element} />;
                            })}
                        </Route>
                    </Route>

                    {/* Equipo */}
                    <Route element={<RequireAuth allowRole={[ROLES.ADMIN, ROLES.RESPONSABLE_MAN]}/>}>
                        <Route path={AppRoutes.equipo.home.path}>
                            <Route index={true} element={AppRoutes.equipo.home.element}/>
                            {AppRoutes.equipo.nested.map((route, index) => {
                            return <Route key={index} path={route.path}element={route.element} />;
                            })}
                        </Route>
                    </Route>

                    {/* Category */}
                    <Route element={<RequireAuth allowRole={[ROLES.ADMIN]}/>}>
                        <Route path={AppRoutes.category.home.path}>
                            <Route index={true} element={AppRoutes.category.home.element}/>
                            {AppRoutes.category.nested.map((route, index) => {
                            return <Route key={index} path={route.path}element={route.element} />;
                            })}
                        </Route>
                    </Route>

                    {/* Subcategory */}
                    <Route element={<RequireAuth allowRole={[ROLES.ADMIN]}/>}>
                        <Route path={AppRoutes.subcategory.home.path}>
                            <Route index={true} element={AppRoutes.subcategory.home.element}/>
                            {AppRoutes.subcategory.nested.map((route, index) => {
                            return <Route key={index} path={route.path}element={route.element} />;
                            })}
                        </Route>
                    </Route>

                    {/* Sucursal */}
                    <Route element={<RequireAuth allowRole={[ROLES.ADMIN]}/>}>
                        <Route path={AppRoutes.sucursal.home.path}>
                            <Route index={true} element={AppRoutes.sucursal.home.element}/>
                            {AppRoutes.sucursal.nested.map((route, index) => {
                            return <Route key={index} path={route.path}element={route.element} />;
                            })}
                        </Route>
                    </Route>
                    
                    {/* Listas/Revision */}
                    <Route element={<RequireAuth allowRole={[ROLES.ADMIN, ROLES.RESPONSABLE_MAN, ROLES.MANTENIMIENTO]}/>}>
                        <Route path={AppRoutes.revision.home.path}>
                            <Route index={true} element={AppRoutes.revision.home.element} />
                            {AppRoutes.revision.nested.map((route, index) => {
                            return <Route key={index} path={route.path}element={route.element} />;
                            })}
                        </Route>
                    </Route>

                </Route>

                <Route path="*" element={<NotFound/>}/>
            </Route>

            {/* Ocupacion de agendas */}
            <Route path="/agenda" element={<AgendaLayout />}>
                <Route element={<RequireAuth allowRole={[ROLES.ADMIN, ROLES.MANTENIMIENTO, ROLES.GENERAL, ROLES.RESPONSABLE_MAN]}/>}>
                    <Route path="" element={<VistaGenearl />}/>

                    {/* Authorization */}

                </Route>
            </Route>

            </Routes>

        </UserContextProvider>
    );
  }
