import { ShowFiltros } from "../../types";
import { SolicitudHome } from "./SolicitudHome";


export function SolicitudConsultas() {

    const showFilters: ShowFiltros = {
        fechas: true,
        categoria: true,
        estado: true,
        sucursal: true,
        urgencia: true,
        responsable: true,
        checklist: false
    }

    return (
        <div>
            <h2 className="text-3xl my-4 font-semibold">Consulta de Solicitudes de Compra</h2>
            <SolicitudHome 
                titulo={true}
                onlyNonTerminados={false}
                filtrosShowed={showFilters}/>
        </div>
    );
}