import ClearIcon from '@mui/icons-material/Clear';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CategoryModel, SubcategoryModel, TareaCreateModel } from '../../types';
import { CategoriaServices } from '../../services';
import { useUser } from '../../hooks/useUser';
import { ErrorMessage, LoadingRequest } from '../UtilsComponents';

interface Props {
    setValue: any;
    tareas: Array<TareaCreateModel>
    setTareas: Dispatch<SetStateAction<Array<TareaCreateModel>>>
}

export function AgregarTareas({ setValue, tareas, setTareas }: Props) {
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const categoryService = new CategoriaServices(token);

    const [subcategoriaSelected, setSubcategoriaSelected] = useState<number>(0);
    const [categoriaSelected, setCategoriaSelected] = useState<number>(0);

    const [categories, setCategories] = useState<Array<CategoryModel>>([]);
    const [subcategorias, setSubcategorias] = useState<Array<SubcategoryModel>>([]);
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);

    const [inputTarea, setInputTarea] = useState<string>("");

    const onChangeRevision = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputTarea(value);
    }

    const handleDeleteRevision = (index: number) => {
        tareas.splice(index, 1);
        setTareas([...tareas]);
    }

    const onClickAddRevision = () => {
        setError(false);

        if(subcategoriaSelected === 0) {
            setError(true);
            return
        }

        const tarea: TareaCreateModel = {
            nombre: inputTarea,
            descripcion: "",
            subcategoriaId: subcategoriaSelected
        }

        setTareas([...tareas, tarea]);
        setInputTarea("");

        console.log(tarea);

        setValue("tareasRequest", tareas, { shouldDirty: true });
        setCategoriaSelected(0);
        setSubcategoriaSelected(0);
    }

    const onFilterChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

        const value  = parseInt(event.target.value);

        if(event.target.name == "categoriaId") {

            if(value != 0) {
                const subcategories = categories.find(c => c.id == value)?.subcategoria;
                setSubcategorias(subcategories!);
                setCategoriaSelected(value);
            }
        }

        if(event.target.name == "subcategoriaId") {

            if(value != 0) {
                setSubcategoriaSelected(value);
            }
        }
    }

    useEffect(() => {
        const categories = categoryService.getCategories()
            .then(data => {
                setCategories(data)
            });
        
        Promise.all([categories])
            .then(_ => setDataLoading(false));
    }, []);

    return (
        <div>
            <div className="grid gap-5 grid-cols-1 justify-between col-span-2">

                <div className="flex gap-5 grid-cols-1 justify-between col-span-2">
                    <div className="flex items-center w-full">
                        <div className="relative flex-1">
                            <div className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 w-10 absolute left-1 top-1/2 -translate-y-1/2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    strokeLinejoin="round"
                                    className="h-5 w-5 text-slate-400">
                                    <path d="M5 12h14"></path>
                                    <path d="M12 5v14"></path>
                                </svg>
                            </div>
                            <input
                                onChange={onChangeRevision}
                                value={inputTarea}
                                className="
                                    flex h-10 w-full rounded-lg border border-input bg-background 
                                    px-10 py-2 text-sm ring-offset-background file:border-0 
                                    file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground 
                                    disabled:cursor-not-allowed disabled:opacity-50 pr-10 inputform"
                                placeholder="Agregar una revisión"
                                type="text" />
                        </div>
                    </div>

                    <button 
                        className="p-1 my-2 rounded-lg bg-gray-700 font-semibold text-slate-200 order-2 sm:order-3 disabled:bg-gray-400"
                        type="button"
                        disabled={inputTarea.length == 0}
                        onClick={onClickAddRevision}>
                        Agregar
                    </button>
                </div>

                <div className="grid gap-2 grid-cols-1 justify-between col-span-2">
                    <div className="flex gap-4">
                        {dataLoading
                        ? <div className="w-full h-[28px] grid justify-items-center"><LoadingRequest /></div>
                        :
                        <>
                        <select 
                            value={categoriaSelected}
                            className="selectfilter w-11/12 sm:w-2/3"
                            name="categoriaId"
                            onChange={onFilterChange}>
                                <option value={0} hidden>Seleccionar categoría</option>
                                {categories.map((category, index) => (
                                <option key={index} value={category.id}>{category.nombreCategoria}</option>
                                ))
                                }
                        </select>

                        <select 
                            value={subcategoriaSelected}
                            className="selectfilter w-11/12 sm:w-2/3"
                            name="subcategoriaId"
                            onChange={onFilterChange}>
                                <option value={0} hidden>Seleccionar subcategoría</option>
                                {subcategorias.map((subcategoria, index) => (
                                <option key={index} value={subcategoria.id}>{subcategoria.nombreSubcategoria}</option>
                                ))
                                }
                        </select>
                        </>
                        }
                    </div>

                    {error && (
                        <ErrorMessage 
                            errorMessage={"Tiene que seleccionar una subcategoria"}/>
                    )}

                </div>

            </div>

            <div className="grid gap-2 grid-cols-1 justify-between col-span-2">
                <p className="font-medium mt-4">Tareas Agregadas: {tareas.length}</p>
                <div className="grid content-start gap-2 overflow-auto max-h-[400px] h-[400px]">
                    {tareas.length == 0
                    ? <p className="italic text-gray-700 font-light">Sin revisiones agregadas</p>
                    : <>
                        {tareas.map((tarea, index) => (
                            <div key={index} className="flex justify-between border rounded-xl shadow-sm py-3 px-4 ">
                                <div>
                                    <p className="font-semibold">{index + 1}. {tarea.nombre}</p>
                                    <div className="flex gap-4 text-cyan-800">
                                        <p>Categoria: {categories.find(cat => cat.subcategoria?.find(sub => sub.id == tarea.subcategoriaId))?.nombreCategoria}</p>
                                        <p>Subcategoria: {subcategorias.find(sub => sub.id == tarea.subcategoriaId)?.nombreSubcategoria}</p>
                                    </div>

                                </div>
                                <ClearIcon 
                                    className="text-red-300 hover:bg-gray-100 hover:rounded"
                                    onClick={() => handleDeleteRevision(index)}/>
                            </div>
                        ))}
                    </>}
                </div>
            </div>
        </div>
    );
}