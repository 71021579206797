import { createPortal } from "react-dom";


export function ModalContainer({children}: any) {
    return createPortal(
        <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-black z-50 duration-300">
            {children}
        </div>,
        document.getElementById("modal") as HTMLElement ?? <></>
    )
}