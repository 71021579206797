import ViewStreamRoundedIcon from '@mui/icons-material/ViewStreamRounded';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import Divider from '@mui/joy/Divider';
import { Dispatch, SetStateAction } from 'react';
import { CheckBox } from './CheckBox';
import { ChartDisplay } from '../../types/agenda';

interface Props {
    chartDisplay: ChartDisplay;
    setChartDisplay: Dispatch<SetStateAction<ChartDisplay>>;
}

export function DisplayCharts({ chartDisplay, setChartDisplay }: Props) {
    return (
        <div className="flex justify-between my-2 rounded-lg border-2 border-slate-200 shadow-sm py-1 ">
            <div className="flex mx-2">
                <CheckBox
                    name="showDaysChart"
                    setChartDisplay={setChartDisplay}
                    chartDisplay={chartDisplay}
                    text="Día"/>

                <CheckBox 
                    name="showSucursalChart"
                    setChartDisplay={setChartDisplay}
                    chartDisplay={chartDisplay}
                    text="Sucursal"/>

                <CheckBox 
                    name="showEspecialistaChart"
                    setChartDisplay={setChartDisplay}
                    chartDisplay={chartDisplay}
                    text="Especialidad"/>

                <CheckBox 
                    name="showDoctoraChart"
                    setChartDisplay={setChartDisplay}
                    chartDisplay={chartDisplay}
                    text="Doctor"/>
            </div>
            
            
            <div className="flex">
                <Divider orientation="vertical"/>
                <div className="mx-2 flex gap-2 items-center">
                    <label
                        className={`${chartDisplay.layout === "Row" ? "bg-slate-200": ""} rounded-lg p-1 w-[32px] h-[32px] flex items-center cursor-pointer`}
                        onClick={() => setChartDisplay({...chartDisplay, layout: "Row"})}>
                        <input
                            className="w-[32px] h-[32px]"
                            type="button"
                            name="row"/>
                        <GridViewRoundedIcon sx={{ "color": "#111827"}}/>
                    </label>


                    <label
                        onClick={() => setChartDisplay({...chartDisplay, layout: "Column"})}
                        className={`${chartDisplay.layout === "Column" ? "bg-slate-200":""} rounded-lg p-1 w-[32px] h-[32px] flex items-center cursor-pointer`}>
                        <input 
                            type="button"
                            name="column"/>
                        <ViewStreamRoundedIcon sx={{ "color": "#111827"}}/>
                    </label>
                </div>
            </div>
            

        </div>
    );
}