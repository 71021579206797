import 'chart.js/auto';
import 'chartjs-plugin-datalabels';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
    Chart as ChartJs,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { VistaGeneral } from '../../../types/agenda';
import { calculateMaxRange } from '../../../utilities/calculateMaxRange';

ChartJs.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface Props {
    chartConfig: VistaGeneral;
}

export function VistaGeneralChart({ chartConfig }: Props) {

    const greatestGeneral = chartConfig.general.toSorted((a: number, b: number) => b - a)[0];
    const greatestEspecialista = chartConfig.especialista.toSorted((a: number, b: number) => b - a)[0];

    const max = calculateMaxRange(greatestGeneral, greatestEspecialista);

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                min: 0,
                max: max
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: '',
            },
            datalabels: {
                display: true,
                color: "rgba(17, 24, 39, 1)",
                formatter: function(value: any, _context: any) {
                    return `${value} %`
                },
            }
        },
    };

    const dataset = []

    if(chartConfig.general.length > 0) {
        dataset.push({
                label: chartConfig.generalDatasetName ?? "Generales",
                data: chartConfig.general,
                backgroundColor: chartConfig.generalColors.length > 0 ? chartConfig.generalColors : 'rgba(255, 99, 132, 0.5)',
            });
    }

    if(chartConfig.especialista.length > 0) {
        dataset.push({
                label: chartConfig.especialistaDatasetName ?? "Especialistas",
                data: chartConfig.especialista.length > 0 ? chartConfig.especialista : null,
                backgroundColor: chartConfig.especialistaColors.length > 0 ? chartConfig.especialistaColors : 'rgba(255, 99, 132, 0.5)',
            });
    }

    const data = {
        labels: chartConfig.labels,
        datasets: dataset,
    };

    return (
        <Bar 
            redraw={true}
            options={options}
            data={data}
            plugins={[ChartDataLabels]}/>
    );
}