import { Table } from "@mui/joy";
import { useUser } from "../../hooks/useUser";
import { useEffect, useState } from "react";
import { HistorialRevision } from "../../types/checklist";
import { ChecklistServices, FiltroServices } from "../../services";
import { EditButton } from "../../utilities/UtilsComponents/EditButton";
import { useLocation } from "react-router-dom";
import { Filtros } from "../UtilsComponents/Filtros";
import { FiltrosModel } from "../../types";
import { formatDate } from "../../utilities/showData";
import { ElementsNotFound, LoadingComponent } from "../UtilsComponents";


export function HistorialHome() {
    const location = useLocation();
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const [historialList, setHistorialList] = useState<Array<HistorialRevision>>([]);
    const [dataLoading, setDataLoading] = useState<boolean>(true);

    const [filtros, setFiltros] = useState<FiltrosModel>({
        fechaInicial: formatDate(new Date(new Date().setDate(new Date().getDate() - 7))),
        fechaFinal: formatDate(new Date()),
        categoriaId: 0,
        sucursalId: 0,
        estado: "",
        responsableId: 0,
        subcategoriaId: 0,
        urgencia: "",
        checklistId: 0
    });

    const checklistService = new ChecklistServices(token);

    useEffect(() => {
        checklistService.getHistorialRevisiones(filtros.fechaInicial, filtros.fechaFinal)
            .then(data => {
                setHistorialList(data);
                setDataLoading(false);
            })
    }, [filtros.fechaInicial, filtros.fechaFinal]);


    const historialFiltered: Array<HistorialRevision> = FiltroServices.filtrosChecklist(historialList, filtros);

    return (
        <div>

            <h2 className="text-3xl my-4 font-semibold">Historial de Revisiones</h2>

            <Filtros 
                typeFilter={"Checklist"}
                filtros={filtros}
                setFiltros={setFiltros}
                showFiltros={{
                    fechas: true,
                    categoria: false,
                    estado: false,
                    sucursal: true,
                    responsable: true,
                    urgencia: false,
                    checklist: true,
                }}/>

            {dataLoading 
            ? <LoadingComponent />
            :
            <div className="overflow-auto max-h-[600px] rounded-lg border border-slate-200">
                <Table 
                    stickyHeader
                    sx={(theme) => ({
                        "th": {bgcolor: "#111827", color: "#ffffff"}
                    })}>
                    <table className="w-full table-fixed">
                        <thead>
                            <tr>
                                <th className="w-20">Folio</th>
                                <th>Lista</th>
                                <th>Responsable</th>
                                <th>Sucursal</th>
                                <th>Fecha de creacion</th>
                                <th className="w-20">Detalles</th>
                            </tr>
                        </thead>
                        <tbody>
                        {historialFiltered.map((historial, index) => (
                            <tr key={index} className="hover:bg-slate-100">
                                <td>{historial.id}</td>
                                <td>{historial.listaName}</td>
                                <td>{historial.responsableName}</td>
                                <td>{historial.sucursalName}</td>
                                <td>{historial.fechaCreacion}</td>
                                <td>
                                    <EditButton url={`/checklist/historial/${historial.id}`} previousUrl={location.pathname}/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </Table>

                {historialFiltered.length == 0 && (
                    <ElementsNotFound />
                )}

            </div>
            }
        </div>
    );
}