import { ChangeEvent, useEffect, useState } from "react";
import { CategoryModel, SubcategoryModel, TareaCreateModel, TareaModel } from "../../types";
import { useUser } from "../../hooks/useUser";
import { ChecklistServices } from "../../services";
import { ErrorIconMessage, LoadingRequest } from "../UtilsComponents";

interface Props {
    tarea: TareaModel;
    categories: CategoryModel[];
}

export function UpdateTarea({ tarea, categories }: Props) {
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const checklistService = new ChecklistServices(token);

    const [initialTareaNameValue, setInitialTareaNameValue] = useState<string>(tarea.nombre);
    const [nombreTarea, setNombreTarea] = useState<string>(tarea.nombre)
    const [isDirty, setIsDirty] = useState<boolean>(false);

    let categoriaId = categories.find(
        cat => cat.subcategoria?.find(sub => sub.id == tarea.subcategoriaId)?.id
    )?.id;
    const [subcategorias, setSubcategorias] = useState<Array<SubcategoryModel>>(
        categories.find(cat => cat.id == categoriaId)?.subcategoria ?? []
    );

    const [categoriaSelected, setCategoriaSelected] = useState<number>(categoriaId ?? 0);
    const [subcategoriaInitial, setSubcategoriaInitial] = useState<number>(tarea.subcategoriaId);
    const [subcategoriaSelected, setSubcategoriaSelected] = useState<number>(tarea.subcategoriaId);

    const [hasError, setHasError] = useState<boolean>(false);
    const [requestLoading, setRequestLoading] = useState<boolean>(false);

    const onChangeNombre = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setNombreTarea(value);
    } 

    const onClickSaveChanges = async () => {
        try {
            setHasError(false);
            setRequestLoading(true);

            const data: TareaCreateModel = {
                nombre: nombreTarea,
                descripcion: null,
                subcategoriaId: subcategoriaSelected
            }

            const response = await checklistService.updateTareaById(tarea.id, data);

            if(response.status == 200) {
                setInitialTareaNameValue(data.nombre);
                setSubcategoriaInitial(subcategoriaSelected);
                setRequestLoading(false);
            }
        } catch(error) {
            setRequestLoading(false);
            setHasError(true);
        }
    }

    const onFilterChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value  = parseInt(event.target.value);

        if(event.target.name == "categoriaId") {

            if(value != 0) {
                const subcategories = categories.find(c => c.id == value)?.subcategoria;
                setCategoriaSelected(value);
                setSubcategoriaSelected(0);
                setSubcategorias(subcategories!);
            }
        }

        if(event.target.name == "subcategoriaId") {

            if(value != 0) {
                setSubcategoriaSelected(value);
            }
        }
    }

    useEffect(() => {
        setInitialTareaNameValue(tarea.nombre);
        setNombreTarea(tarea.nombre);
        setSubcategoriaInitial(tarea.subcategoriaId);

        categoriaId = categories.find(
            cat => cat.subcategoria?.find(sub => sub.id == tarea.subcategoriaId)?.id
        )?.id;

        setCategoriaSelected(categoriaId ?? 0);
        setSubcategoriaSelected(tarea.subcategoriaId);

        setSubcategorias(
            categories.find(cat => cat.id == categoriaId)?.subcategoria ?? []
        );

    }, [tarea])

    useEffect(() => {

        setIsDirty(initialTareaNameValue == nombreTarea && subcategoriaInitial == subcategoriaSelected);

    }, [nombreTarea, initialTareaNameValue, subcategoriaInitial, subcategoriaSelected]);

    return (
        <div className="flex justify-between">
            <input 
                className="w-10/12 h-[24px] font-semibold"
                type="text" 
                value={nombreTarea}
                onChange={onChangeNombre}/>

            <div className="grid gap-2 grid-cols-1 justify-between col-span-2">
                {tarea.version2 && (
                    
                <div className="flex gap-4">
                    <select 
                        value={categoriaSelected}
                        className="selectfilter w-11/12 sm:w-2/3"
                        name="categoriaId"
                        onChange={onFilterChange}>
                            <option value={0} hidden>Seleccionar categoría</option>
                            {categories.map((category, index) => (
                            <option key={index} value={category.id}>{category.nombreCategoria}</option>
                            ))
                            }
                    </select>

                    <select 
                        value={subcategoriaSelected}
                        className="selectfilter w-11/12 sm:w-2/3"
                        name="subcategoriaId"
                        onChange={onFilterChange}>
                            <option value={0} hidden>Seleccionar subcategoría</option>
                            {subcategorias.map((subcategoria, index) => (
                            <option key={index} value={subcategoria.id}>{subcategoria.nombreSubcategoria}</option>
                            ))
                            }
                    </select>
                </div>
                )}
            </div>

            {!isDirty && (
                <>
                {requestLoading
                ? <LoadingRequest />
                :
                <button 
                    className="w-auto px-2 bg-[#1F7A1F] rounded mx-2 text-white" 
                    onClick={onClickSaveChanges}
                    type="button">
                    Guardar
                </button>
                }
                </>
            )}
            {hasError && (
                <ErrorIconMessage />
            )}
        </div>
    );
}