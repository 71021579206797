import { useState, useEffect, ChangeEvent } from "react";
import { useForm } from "react-hook-form";
import { CategoryModel, SolicitudResponse, SucursalModel, UsuarioModel } from "../../types";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Divider } from "@mui/joy";
import { useUser } from "../../hooks/useUser";
import { SolicitudChangeEstado } from "./SolicitudChangeEstado";
import { ModalContainer } from "../UtilsComponents/ModalContainer";
import { SolicitudCancelEstado } from "./SolicitudCancelEstado";
import { Comentarios } from "../TicketComponents/Comentarios";
import { prioridadDefault } from "../../utilities/prioridadDefault";
import { 
    ComentarioTypes,
    Estados,
    EstadosSolicitud,
    urgenciaList,
    prioridadList,
} from "../../utilities/StateEnums";
import { 
    ComprasServices,
    UserServices,
    SucursalServices,
    CategoriaServices,
} from "../../services";
import { LoadingScreen } from "../UtilsComponents/LoadingScreen";
import { ImagePreview } from "../UtilsComponents/ImageComponents";
import { LoadingRequest, ErrorMessage } from "../UtilsComponents";
import { errorParser } from "../../utilities";

export function SolicitudDetail() {
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [requestLoading, setRequestLoading] = useState<boolean>(false);

    const comprasService = new ComprasServices(token);
    const userService = new UserServices(token);
    const sucursalService = new SucursalServices(token);
    const categoriaService = new CategoriaServices(token);

    const navigate = useNavigate();
    const { id } = useParams();
    const { register,
            watch, 
            handleSubmit, 
            setValue,
            formState: { errors, isDirty } 
        } = useForm<SolicitudResponse>({
        defaultValues: async () => {
            const solicitud = await comprasService.getSolicitudById(id);

            const users = userService.getUsersByRole("Compras")
                .then(data => setListUserResponsable(data));
            
            const sucursales = sucursalService.getSucursales()
                .then(data => setSucursales(data));

            const categories = categoriaService.getCategories()
                .then(data => setCategorias(data));
            
            Promise.all([users, sucursales, categories, solicitud])
                .then(values => setIsLoaded(true));
            
            return solicitud;
        }
    });

    const resposable = watch("responsableId");
    const categoria_id = watch("categoriaId");
    const is_vaidated = watch("aprobada", false);
    const estado = watch("estado", "default");
    const fechaAtencion = watch("fechaAtencion", "");
    const archivo = watch("archivo")
    const ticketId = watch("ticketId")

    const [openModal, setOpenModal] = useState(false);
    const [atendidoState, setAtendidoState] = useState<boolean>(estado != "Aprobado" && estado != "Vencido");
    const [cerradoState, setCerradoState] = useState<boolean>(estado === "Terminado");
    const [idCategorySelected, setIdCategorySelected] = useState<number | null>(categoria_id);

    const [isLoaded, setIsLoaded] = useState(false);

    let categorySelected: CategoryModel | undefined;
    const [listUserResponsable, setListUserResponsable] = useState<Array<UsuarioModel>>([]);
    const [sucursales, setSucursales] = useState<Array<SucursalModel>>([]);
    const [categorias, setCategorias] = useState<Array<CategoryModel>>([]);

    const submitUpdate = handleSubmit(async (data: SolicitudResponse) => {
        try {
            setHasError(false);
            setRequestLoading(true);

            const response = await comprasService.updateSolicitud(id, data)
            if(response.status === 200) {
                setRequestLoading(false);
                navigate("/compras");
            }

        } catch(error) {
            const message = errorParser(error);
            setRequestLoading(false);
            setErrorMessage(message);
            setHasError(true);
        }
    })

    const updateStatusSolicitud = (newStatus: EstadosSolicitud) => {
        comprasService.updateStateSolicitud(id, newStatus)
            .then(response => {
                if(response.status === 200) {
                    navigate("/compras");
                }
            })
            .catch(error => console.error(error));
    }

    const handleChanges = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        if(event.target.name === "categoryId") {
            setValue("categoriaId", parseInt(event.target.value), { shouldDirty: true });
            setValue("subcategoriaId", 0);
            setIdCategorySelected(parseInt(event.target.value));
        }

        if(event.target.name === "urgencia") {
            setValue("urgencia", event.target.value, { shouldDirty: true });
            setValue("prioridad", prioridadDefault(event.target.value), { shouldDirty: true });
        }
    }

    // filter subcategories by categoria id selected.
    if(idCategorySelected != null) {
        categorySelected = categorias.find((category) => category.id === idCategorySelected);
    }

    // set default categoria id.
    useEffect(() => {
        setIdCategorySelected(categoria_id)
    }, [categoria_id])

    return (
        <>
        {!isLoaded
        ? <LoadingScreen />
        : (
        <div>
            <h2 className="text-3xl my-4 font-semibold">Solicitud Folio {id}</h2>
            <form className="grid grid-cols-2" onSubmit={submitUpdate}>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="titulo">Titulo</label>
                    <input {...register("titulo", {
                                required: "El titulo es requerido",
                                maxLength: 150
                            })}
                            aria-invalid={errors.titulo ? "true" : "false"}
                            id="titulo"
                            className="inputform"/>
                    {errors.titulo?.type === "required" && (
                        <p className="valErrorText col-start-2" role="alert">{errors.titulo.message}</p>
                    )}
                    {errors.titulo?.type === "maxLength" && (
                        <p className="valErrorText col-start-2" role="alert">Número de caracteres permitidos: 150</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="descripcion">Descripción</label>
                    <textarea {...register("descripcion", {
                                required: "La descripcion es requerida"
                            })}
                            className="textareaform"
                            aria-invalid={errors.descripcion ? "true" : "false"}
                            id="descripcion"
                            rows={5}/>
                    {errors.descripcion?.type === "required" && (
                        <p className="valErrorText col-start-2" role="alert">{errors.descripcion.message}</p>
                    )}
                </div>
                
                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Informado por</label>
                    <input {...register("creadorName")}
                            disabled={true}
                            className="inputform"/>
                </div>
                
                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="responsable">Responsable</label>
                    { resposable !== undefined && (

                        <select {...register("responsableId", {
                                    required: "El responsable es requerido",
                                    min: 1
                                })}
                                aria-invalid={errors.responsableId ? "true" : "false"}
                                id="responsable"
                                disabled={userContext.auth?.user?.tipoUsuario !== "Admin"}
                                className="inputform">
                                <option value={0} hidden></option>
                                {listUserResponsable.map((user, index) => (
                                    <option key={index} value={user.id}>{user.nombre}</option>
                                ))}
                        </select>
                    )
                    }
                    {errors.responsableId?.type === "required" && (
                        <p className="valErrorText col-start-2" role="alert">{errors.responsableId.message}</p>
                    )}
                    {errors.responsableId?.type === "min" && (
                        <p className="valErrorText col-start-2" role="alert">Tiene que seleccionar un responsable</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="sucursal">Sucursal</label>
                    <select {...register("sucursalId", {
                                required: "La sucursal es requerida",
                                min: 1
                            })}
                            aria-invalid={errors.sucursalId ? "true" : "false"}
                            id="sucursal"
                            className="inputform">
                            {userContext.auth?.user?.tipoUsuario === "General" 
                                ? (
                                    <option 
                                        defaultChecked 
                                        value={userContext.auth.user.sucursalId}>
                                        {userContext.auth.user.sucursalNombre}
                                    </option>
                                )
                                : (
                                <>
                                    <option value={0} hidden></option>
                                    {sucursales.map((sucursal, index) => (
                                        <option key={index} value={sucursal.id}>{sucursal.smallDir}</option>
                                    ))}
                                </>
                                )
                            }
                    </select>
                    {errors.sucursalId?.type === "required" && (
                        <p className="valErrorText col-start-2" role="alert">{errors.sucursalId.message}</p>
                    )}
                    {errors.sucursalId?.type === "min" && (
                        <p className="valErrorText col-start-2" role="alert">Tiene que seleccionar una sucursal</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="categoria">Categoria</label>
                    <select {...register("categoriaId", {
                                required: "La categoria es requerida",
                                min: 1
                            })}
                            aria-invalid={errors.categoriaId ? "true" : "false"}
                            onChange={handleChanges}
                            id="categoria"
                            name="categoryId"
                            className="inputform">
                            {categorias.map((category, index) => (
                                <option key={index} value={category.id}>{category.nombreCategoria}</option>
                            ))}
                    </select>
                    {errors.categoriaId?.type === "required" && (
                        <p className="valErrorText col-start-2" role="alert">{errors.categoriaId.message}</p>
                    )}
                    {errors.categoriaId?.type === "min" && (
                        <p className="valErrorText col-start-2" role="alert">Tiene que seleccionar una categoría</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="subcategoria">Subcategoria</label>
                    <select {...register("subcategoriaId", {
                                required: "La subcategoría es requerida",
                                min: 1
                            })}
                            aria-invalid={errors.subcategoriaId ? "true" : "false"}
                            id="subcategoria"
                            className="inputform">
                            <option defaultChecked value={0} hidden></option>
                            {categorySelected?.subcategoria?.map((subcategory, index) => (
                                <option key={index} value={subcategory.id}>{subcategory.nombreSubcategoria}</option>
                            ))}
                    </select>
                    {errors.subcategoriaId?.type === "required" && (
                        <p className="valErrorText col-start-2" role="alert">{errors.subcategoriaId.message}</p>
                    )}
                    {errors.subcategoriaId?.type === "min" && (
                        <p className="valErrorText col-start-2" role="alert">Tiene que seleccionar una subcategoría</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Estado</label>
                    <input {...register("estado")}
                            disabled={true}
                            className="inputform"/>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="urgencia">Urgencia</label>
                    <select {...register("urgencia", {
                                required: "La urgencia es requerida",
                            })}
                            aria-invalid={errors.urgencia ? "true" : "false"}
                            onChange={handleChanges}
                            name="urgencia"
                            id="urgencia"
                            className="inputform">
                            <option defaultChecked value={""} hidden></option>
                            {urgenciaList.map((value, index) => (
                                <option key={index} value={value}>{value}</option>
                            ))}
                    </select>
                    {errors.urgencia?.type === "required" && (
                        <p className="valErrorText col-start-2" role="alert">{errors.urgencia.message}</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="prioridad">Prioridad</label>
                    <select {...register("prioridad")}
                            id="prioridad"
                            disabled={true}
                            className="inputform">
                            <option defaultChecked value={""} hidden></option>
                            {prioridadList.map((value, index) => (
                                <option key={index} value={value}>{value}</option>
                            ))}
                    </select>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Validación</label>
                    <select {...register("aprobada")}
                            disabled={true}
                            className="inputform">
                        <option value={"true"}>Validado</option>
                        <option value={"false"}>Sin Validar</option>
                    </select>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Fecha de Informe</label>
                    <input {...register("fechaCreacion")}
                            type="datetime-local"
                            disabled={true}
                            className="inputform"/>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Fecha de Aprobacion</label>
                    <input
                        {...register("fechaAprobacion")}
                        type="datetime-local"
                        disabled={true}
                        className="inputform"/>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Fecha de Atención</label>
                    <input {...register("fechaAtencion")}
                            type="datetime-local"
                            disabled={true}
                            className="inputform"/>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Fecha de Vencimiento</label>
                    <input {...register("fechaVencimiento")}
                            type="datetime-local"
                            disabled={true}
                            className="inputform"/>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Fecha de Cierre</label>
                    <input 
                        {...register("fechaCierre")}
                        type="datetime-local"
                        disabled={true}
                        className="inputform"/>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Fecha de Rechazo</label>
                    <input 
                        {...register("fechaRechazo")}
                        type="datetime-local"
                        disabled={true}
                        className="inputform"/>
                </div>

                { ["Admin", "Compras"].find(role => role === userContext.auth?.user?.tipoUsuario) && (
                    <SolicitudChangeEstado className={"flex flex-col col-span-2 my-3 w-28 gap-y-1"}
                        previousUrl={"/compras"}
                        is_validated={is_vaidated}
                        estado={estado}
                        setValue={setValue}
                        fechaAtencion={fechaAtencion}
                        atendidoState={{atendidoState: atendidoState, setAtendidoState: setAtendidoState}}
                        cerradoState={{cerradoState: cerradoState, setCerradoState: setCerradoState}}/>
                )}

                { openModal && (
                    <ModalContainer>
                        <SolicitudCancelEstado 
                            setClose={setOpenModal} 
                            close={openModal}
                            comprasService={comprasService}
                            updateFunction={updateStatusSolicitud}/>
                    </ModalContainer>
                )}

                <div className="flex justify-between col-span-2 m-2 gap-2 ">
                    <div>
                    {ticketId && (
                    <Link to={`/ticket/${ticketId}`}> 
                        <p>Ir al ticket de mantenimiento</p>
                    </Link>
                    )}
                    </div>

                    <div className="flex gap-2">
                    {requestLoading
                    ? <LoadingRequest />
                    : <Button size="sm" 
                            color="success" 
                            className="w-20" 
                            disabled={!isDirty}
                            type="submit">
                        Guardar
                    </Button>
                    }
                    { ["Admin", "Compras", "General"].find(role => role === userContext.auth?.user?.tipoUsuario) 
                        && estado !== EstadosSolicitud.Terminado && estado !== EstadosSolicitud.Rechazado && (
                        <Button size="sm" 
                                color="danger" 
                                className="w-20 mx-2" 
                                disabled={estado === Estados.Cancelado}
                                onClick={() => setOpenModal(!openModal)}>
                                Cancelar Solicitud
                        </Button>
                    )}
                    <Button 
                        className="w-20 mx-2"
                        size="sm"
                        color="neutral"
                        onClick={() => navigate(-1)}>
                        Regresar
                    </Button>
                    </div>
                </div>

                {hasError && (
                    <ErrorMessage
                        errorMessage={errorMessage} />
                )}

            </form>
            {archivo !== null && (
                <ImagePreview 
                    urlImage={archivo}/>
            )}

            <Divider />
            <Comentarios
                id={id}
                comentarioType={ComentarioTypes.SOLICITUD}
                usuarioId={userContext.auth?.user?.id ?? 2} />
        </div>)}
        </>
 );
}