import { useState, useEffect } from "react"; 
import { Table } from "@mui/joy";
import { useUser } from "../../hooks/useUser";
import { BitacoraModel, FiltrosModel, ShowFiltros } from "../../types";
import { BitacoraServices } from "../../services/bitacora";
import { Filtros } from "../UtilsComponents/Filtros";
import { formatDate } from "../../utilities/showData";
import { SearchFilter } from "../../utilities/UtilsComponents/SearchFilter";
import { FiltroServices } from "../../services";
import { LoadingComponent } from "../UtilsComponents/LoadingComponent";
import { ElementsNotFound } from "../UtilsComponents";

export function BitacoaComprasHome() {
    const [bitacoraRegisters, setBitacoraRegisters] = useState<Array<BitacoraModel>>([]);
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";
    const bitacoraServices = new BitacoraServices(token);

    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [search, setSearch] = useState<string>(""); 
    const [filtros, setFiltros] = useState<FiltrosModel>({
        fechaInicial: formatDate(new Date(new Date().setDate(new Date().getDate() - 7))),
        fechaFinal: formatDate(new Date()),
        categoriaId: 0,
        sucursalId: 0,
        estado: "",
        responsableId: 0,
        subcategoriaId: 0,
        urgencia: "",
        checklistId: 0,
    });

    const showfiltros: ShowFiltros = {
        fechas: true,
        categoria: false,
        estado: false,
        sucursal: false,
        responsable: false,
        urgencia: false,
        checklist: false,
    }

    useEffect(()=> {
        bitacoraServices.getBitacoraComprasList(filtros.fechaInicial, filtros.fechaFinal)
            .then(data => {
                setBitacoraRegisters(data);
                setDataLoading(false);
            });

    }, [filtros.fechaInicial, filtros.fechaFinal]);

    let listBitacoraFilter: Array<BitacoraModel> = FiltroServices.filtrosBitacora(bitacoraRegisters, search, filtros);

    return (
        <div>
            <h2 className="text-3xl my-4 font-semibold">Bitácora - Compras</h2>

            <SearchFilter
                searchValue={search}
                setSearchValue={setSearch}/>
            <Filtros
                showFiltros={showfiltros}
                setFiltros={setFiltros}
                filtros={filtros}
                typeFilter="Solicitud"/>

            {dataLoading
            ? <LoadingComponent />
            :
            <div className="overflow-auto max-h-[600px] rounded-lg border border-slate-200">
                <Table 
                    stickyHeader
                    sx={(theme) => ({
                        "th": {bgcolor: "#111827", color: "#fff"}
                    })}>
                    <table className="border border-gray-700 rounded-lg overflow-hidden w-full">
                        <thead className="bg-gray-700 text-gray-400">
                            <tr>
                                <th>Folio</th>
                                <th>Evento</th>
                                <th>Descripcion</th>
                                <th>Fecha</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listBitacoraFilter.map((registro, index) => (
                                    <tr key={index} className={`hover:bg-slate-100`}>
                                        <td>{registro.folio}</td>
                                        <td>{registro.evento}</td>
                                        <td>{registro.descripcion}</td>
                                        <td>{registro.fecha}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </Table>

                {listBitacoraFilter.length == 0 && (
                    <ElementsNotFound />
                )}

            </div>
            }
        </div>
    );
}