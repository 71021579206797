import axios from "axios";
import { CodePrefixRequest, CodePrefixResponse, EquipoModel, EquipoRequest } from "../types";
import { BaseService } from "./BaseService";


interface EquipoSave {
    nombre: string
    numeroSerie: string
    departamento: string
    subcategoriaId: number
    sucursalId: number
    codigo?: string
}

interface EquipoUpdate extends EquipoSave {
    id: number
    frecuenciaMan: number
    ultimoMan: string
}


export class EquipoServices extends BaseService {

    /**
     * @param { string } token - Auth token for getting access to API Backend.
     */
    constructor(token: string) {
        super(token, "/equipo")
    }

    /**
     * Get list of Equipos based on its sucursal id and subcategoria id
     * 
     * @param { number } [sucursalId] - Sucursal Id
     * @param { number } [subcategoriaId] - equipo's subcategoria id
     * @param { number } [categoriaId] - equipo's categoria id
     * @returns { Promise<Array<EquipoModel>> } - List of EquipoModel
     */
    public async getEquipos(sucursalId?: number, subcategoriaId?: number, categoriaId?: number): Promise<Array<EquipoModel>> {
        const response = await this.axios.get("", { params: { sucursalId, subcategoriaId, categoriaId }});
        const { data } = response;
        return data;
    }

    /**
     * Get a Equipo by its id
     * 
     * @param { string | undefined } [id] - Equipo id
     * @returns { Promise<EquipoModel>} - Equipo
     */
    public async getEquipoById(id: string | undefined): Promise<EquipoModel> {
        const response = await this.axios.get<EquipoModel>(`/${id}`);
        const { data } = response;
        return data;
    }

    /**
     * Get list of Equipos of a Sucursal by sucursal's id
     * 
     * @param { string } sucursalId - Sucursal id
     * @returns - List of Equipos
     */
    public async getEquiposBySucursalId(sucursalId: string) {
        const response = await this.axios.get(`/by-sucursal/${sucursalId}`);
        const { data } = response;
        return data;
    }

    /**
     * Save a new Equipo in the data base
     * 
     * @param { EquipoRequest } equipoRequest - New Equipo data
     * @returns 
     */
    public saveEquipo(equipoRequest: EquipoRequest) {
        return this.axios.post("", {...equipoRequest});
    }

    /**
     * Update a Equipo
     * 
     * @param { EquipoUpdate } equipoUpdate - Equipo data
     * @returns 
     */
    public updateEquipo(equipoUpdate: EquipoUpdate) {
        return this.axios.put(`/${equipoUpdate.id}`,{
            nombre: equipoUpdate.nombre, 
            numeroSerie: equipoUpdate.numeroSerie,
            departamento: equipoUpdate.departamento,
            subcategoriaId: equipoUpdate.subcategoriaId,
            sucursalId: equipoUpdate.sucursalId,
            frecuenciaMan: equipoUpdate.frecuenciaMan,
            codigo: equipoUpdate.codigo,
            ultimoMan: equipoUpdate.ultimoMan,
        });
    }

    /**
     * Change status Equipo to "Desactivado"
     * 
     * @param { number } id - Equipo id  
     * @returns 
     */
    public deleteEquipo(id: number) {
        return this.axios.delete(`/${id}`);
    }

    /**
     *  Change status Equipo to "Activo"
     * 
     * @param { number } id - Equipo id 
     * @returns 
     */
    public async reactivateEquipo(id: number) {
        return await this.axios.get(`/${id}/reactivate`);
    }

    /**
     * Get the list of code prefix
     * 
     * @returns 
     */
    public async getCodePrefixList(): Promise<Array<CodePrefixResponse>> {
        const response = await this.axios.get<Array<CodePrefixResponse>>("/create-code");
        const { data } = response;
        return data;
    }

    /**
     * Get a code prefix by its id
     * 
     * @param { string | undefined} id - CodePrefix id
     * @returns { Promise<CodePrefixResponse> } - CodePrefix
     */
    public async getCodePrefixById(id: string | undefined): Promise<CodePrefixResponse> {
        const response = await this.axios.get<CodePrefixResponse>(`/create-code/${id}`);
        const { data } = response;
        return data
    }

    /**
     * Save a new CodePrefix in the data base
     * 
     * @param { CodePrefixRequest } data - New code prefix data
     * @returns 
     */
    public async saveCodePrefix(data: CodePrefixRequest) {
        const response = await this.axios.post<CodePrefixRequest>("/create-code", data);
        return response;
    }

    /**
     * Update a CodePrefix
     * 
     * @param { string | undefined } id - CodePrefix id
     * @param { CodePrefixRequest } data - Updated CodePrefix data
     * @returns 
     */
    public async updateCodePrefix(id: string | undefined, data: CodePrefixRequest) {
        const response = await this.axios.put<CodePrefixRequest>(`/create-code/${id}`, data)
        return response;
    }

    /**
     * Delete a CodePrefix from the data base
     * 
     * @param { string | undefined } id - CodePrefix id
     * @returns 
     */
    public async deleteCodePrefix(id: string | undefined) {
        const response = await this.axios.delete(`/create-code/${id}`);
        return response;
    }
}