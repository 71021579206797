import { useEffect, useState } from "react";
import { useUser } from "../../../hooks/useUser";
import { AgendaServices } from "../../../services";
import { FiltrosModel } from "../../../types";
import { ChartLayout, VistaGeneral } from "../../../types/agenda";
import { VistaGeneralChart } from "./VistaGeneralChart";
import { LoadingComponent } from "../../UtilsComponents";

interface Props {
    filtros: FiltrosModel;
    layout: ChartLayout;
}

export function VistaEspecialidad({ filtros, layout }: Props) {
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const agendaService = new AgendaServices(token);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [dataVistaEspecialidad, setDataVistaEspecialidad] = useState<VistaGeneral>({
        labels: [],
        general: [],
        especialista: [],
        generalColors: [],
        especialistaColors: []
    });

    useEffect(() => {
        setIsLoading(true);

        agendaService.getVistaEspecialidadData(
            filtros.fechaInicial,
            filtros.fechaFinal,
            filtros.sucursalId,
            filtros.especialidad,
            filtros.doctores,
            filtros.tipoDoctor,
        ).then(data => {
            setDataVistaEspecialidad(data);
            setIsLoading(false);
        });

    }, [filtros]);

    return (
        <div className={`${layout == "Row" ? "w-full flex justify-center" : "flex justify-center" } rounded-lg shadow-sm p-4 bg-slate-50 border-2 border-slate-200`}>
            <div className={`${layout == "Row" ? "w-[850px]" : "w-10/12"} p-4`}>
            {isLoading
            ? <LoadingComponent />
            : <VistaGeneralChart
                chartConfig={dataVistaEspecialidad} />
            }
            </div>
        </div>
    );
}