import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button } from "@mui/joy";
import { useUser } from "../../hooks/useUser";
import { TicketServices, ComprasServices } from "../../services";
import { SolicitudResponse, TicketResponse } from "../../types";
import { MetricType } from "../../types/metrics";
import { MetricsTicketsTable } from "./MetricsTicketsTable";
import { MetricsSolicitudesTable } from "./MetricsSolicitudesTable";
import { LoadingComponent } from "../UtilsComponents/LoadingComponent";

interface Props {
    setClose: Dispatch<SetStateAction<boolean>>;
    close: boolean;
    data: string | number;
    ticketsIdList: Array<number>;
    metricType: MetricType;
}

export function ShowBarData({setClose, close, data, ticketsIdList, metricType }: Props) {

    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const [ticketsList, setTicketsList] = useState<Array<TicketResponse>>([]);
    const [solicitudList, setSolicitudList] = useState<Array<SolicitudResponse>>([]);

    const [dataLoading, setDataLoading] = useState<boolean>(false);

    const ticketService = new TicketServices(token);
    const comprasService = new ComprasServices(token);

    useEffect(() => {
        setDataLoading(true);

        if(metricType == "Ticket")
            ticketService.getTicketsByIds(ticketsIdList)
                .then(data => {
                    setTicketsList(data);
                    setDataLoading(false);
                });
        
        if(metricType == "Compras")
            comprasService.getSolicitudesByIds(ticketsIdList)
                .then(data => {
                    setSolicitudList(data);
                    setDataLoading(false);
                });
    }, []);

    return (
        <div className="bg-white w-screen h-screen sm:h-auto sm:w-5/6 sm:rounded-md transition duration-300">

            {dataLoading 
            ? <LoadingComponent />
            : <>
                {metricType == "Ticket" && (
                    <MetricsTicketsTable 
                        ticketsList={ticketsList} />
                )}

                {metricType == "Compras" && (
                    <MetricsSolicitudesTable
                        solicitudList={solicitudList} />
                )}

                <div className="grid grid-cols-1 justify-items-center py-2">
                    <Button size="sm"
                            color="neutral"
                            className="w-20"
                            onClick={() => setClose(!close)}>Cerrar</Button>
                </div>
            </>}

        </div>
    );
}