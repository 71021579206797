import { Table } from "@mui/joy";
import { useUser } from "../../hooks/useUser";
import { MantenimientoServices } from "../../services/mantenimientos";
import { useEffect, useState } from "react";
import { FiltrosModel, MantenimientoResponse, ShowFiltros } from "../../types";
import { EditButton } from "../../utilities/UtilsComponents/EditButton";
import { SearchFilter } from "../../utilities/UtilsComponents/SearchFilter";
import { Filtros } from "../UtilsComponents/Filtros";
import { formatDate } from "../../utilities/showData";
import { FiltroServices } from "../../services";
import { LoadingComponent } from "../UtilsComponents/LoadingComponent";
import { ElementsNotFound } from "../UtilsComponents";

interface Props {
    filtrosShowed: ShowFiltros
}

export function MantenimientoHomeConsulta({ filtrosShowed }: Props) {
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const manService = new MantenimientoServices(token);

    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [listRegistros, setListRegistros] = useState<Array<MantenimientoResponse>>([]);
    const [search, setSearch] = useState<string>(""); 
    const [filtros, setFiltros] = useState<FiltrosModel>({
        fechaInicial: formatDate(new Date(new Date().setDate(new Date().getDate() - 7))),
        fechaFinal: formatDate(new Date()),
        categoriaId: 0,
        sucursalId: 0,
        estado: "",
        responsableId: 0,
        subcategoriaId: 0,
        urgencia: "",
        checklistId: 0,
    });

    let listRegistrosMantenimiento: Array<MantenimientoResponse> = [];

    useEffect(() => {

        manService.getRegistrosMantenimientoList(filtros.fechaInicial, filtros.fechaFinal)
            .then(data => {
                setListRegistros(data);
                setDataLoading(false);
            });

    }, [filtros.fechaInicial, filtros.fechaFinal])

    listRegistrosMantenimiento = FiltroServices.filtrosMantenimientos(listRegistros, search, filtros);

    return (
        <div>

            <SearchFilter searchValue={search} setSearchValue={setSearch}/>
            <Filtros 
                typeFilter={"Mantenimiento"}
                filtros={filtros} 
                setFiltros={setFiltros} 
                showFiltros={filtrosShowed}/>

            {dataLoading
            ? <LoadingComponent />
            :
            <div className="overflow-auto max-h-[600px] rounded-lg border border-slate-200">

            <Table
                stickyHeader
                sx={(theme) => ({
                    "th": {bgcolor: "#111827", color: "#ffffff"}
                })}>
                <thead>
                    <tr>
                        <th>Folio</th>
                        <th>Código</th>
                        <th>Equipo</th>
                        <th>Sucursal</th>
                        <th>Categoría</th>
                        <th>Responsable</th>
                        <th>Fecha</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {listRegistrosMantenimiento.map((registro, index) => (
                        <tr key={index} className="hover:bg-slate-100">
                            <td>{registro.id}</td>
                            <td>{registro.codigo}</td>
                            <td>{registro.nombreEquipo}</td>
                            <td>{registro.nombreSucursal}</td>
                            <td>{registro.nombreCategoria}</td>
                            <td>{registro.nombreResponsable}</td>
                            <td>{registro.fechaUltimoMantenimiento}</td>
                            <td>
                                <EditButton url={`/preventivo/consulta/${registro.id}`} previousUrl={""}/>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {listRegistrosMantenimiento.length == 0 && (
                <ElementsNotFound />
            )}

            </div>
            }
        </div>
    );
}