import { useForm } from "react-hook-form";
import { useEffect, useState, ChangeEvent } from "react";
import { useNavigate, Link } from "react-router-dom";
import { SucursalModel } from "../../types";
import { Estados, estadosList, Prioridad, prioridadList, Urgencia, urgenciaList } from "../../utilities/StateEnums";
import Input from "@mui/joy/Input/Input";
import Button from "@mui/joy/Button/Button"; 
import TextArea from "@mui/joy/Textarea";
import { useUser } from "../../hooks/useUser";
import { EquipoServices, SucursalServices, TicketServices } from "../../services";
import { ErrorMessage, LoadingRequest } from "../UtilsComponents";
import { CreateTicketRequest } from "../../types/ticket";
import { errorParser } from "../../utilities";
import { prioridadDefault } from "../../utilities/prioridadDefault";

interface EquipoDisponible {
    id: number
    nombre: string
    subcategoriaId: number
    subcategoriaNombre: string
    categoriaId: number
    categoriaNombre: string
}

interface SubcategoriaDisponible {
    subcategoriaId: number
    subcategoriaNombre: string
    equiposDisponibles: Array<EquipoDisponible>
}

interface CategoriaDisponible {
    categoriaId: number
    categoriaNombre: string
    subcategorias : Array<SubcategoriaDisponible>
}

export function TicketCreateForm() {
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const navigate = useNavigate();

    const { 
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm<CreateTicketRequest>({
        defaultValues: {
            estado: Estados.Abierto,
            urgencia: Urgencia.TresDias,
            prioridad: Prioridad.Media,
            informadoNombre: userContext.auth?.user?.nombre ?? "",
            informado: userContext.auth?.user?.id ?? 0,
            categoriaId: 0,
            subcategoriaId: 0,
            equipoId: 0,
            sucursalId: 0,
            titulo: "",
            descripcion: "",
            imagen: null
        }
    });

    const submitCreateTicket = handleSubmit(async (data: CreateTicketRequest) => {
        try {
            setHasError(false);
            setSaveLoading(true);

            await ticketService.saveTicket(data);

            setSaveLoading(false);
            navigate("/");
        } catch(error) {
            setSaveLoading(false);
            setHasError(true);
            const message = errorParser(error);
            setErrorMessage(message);
        }
    });

    const [categorySelected, setCategorySelected] = useState<number | null>(null);
    const [subcategorySelected, setSubcategorySelected] = useState<number | null>(null);
    const [categories, setCategories] = useState<Array<CategoriaDisponible>>([]);
    const [sucursales, setSucursales] = useState<Array<SucursalModel>>([]);

    // send request managing
    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [saveLoading, setSaveLoading] = useState<boolean>(false);

    // initialize services
    const equipoService = new EquipoServices(token);
    const sucursalService = new SucursalServices(token);
    const ticketService = new TicketServices(token);

    const handleChanges = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        if(event.target.name === "imagen") {
            const target = event.target as HTMLInputElement;
            if(target.files != null) {
                const file = target.files[0];
                setValue("imagen", file);
            }
        }

        if(event.target.name === "categoriaId") {
            setCategorySelected(parseInt(event.target.value));
        }

        if(event.target.name === "subcategoriaId") {
            setSubcategorySelected(parseInt(event.target.value));
        }

        if(event.target.name === "urgencia") {
            setValue("prioridad", prioridadDefault(event.target.value));
        }
    }

    const handleSucursal = (event: ChangeEvent<HTMLSelectElement>) => {
        equipoService.getEquiposBySucursalId(event.target.value)
            .then(data => {
                setCategories(data.categorias);
            });

        setValue("categoriaId", 0);
        setValue("subcategoriaId", 0);
        setValue("equipoId", 0);
    }

    let SubcategorySelected: CategoriaDisponible | undefined;
    if(categorySelected != null){
        SubcategorySelected = categories.find((category) => category.categoriaId === categorySelected);
    }

    let EquiposSelected: SubcategoriaDisponible | undefined
    if(SubcategorySelected != null) {
        EquiposSelected = SubcategorySelected.subcategorias?.find((subcategory) => subcategory.subcategoriaId === subcategorySelected);
    }

    useEffect(()=> {
        sucursalService.getSucursales()
            .then(sucursales => setSucursales(sucursales))
            .catch(error => console.log(error))
    }, [])

    return (
        <div>
            <div className="w-full">
                <h2 className="text-3xl my-4 font-semibold">Crear tickets</h2>
                <form onSubmit={submitCreateTicket} className="grid grid-cols-2" autoComplete="off">

                    <label className="font-medium">Estado</label>
                    <select 
                        {...register("estado", { required: true })}
                        className="inputTicketForm"
                        disabled={true}>
                        {estadosList.map((value, index) => (
                            <option key={index} value={value}>{value}</option>
                        ))}
                        <option defaultChecked value={""} hidden></option>
                    </select>

                    <label className="font-medium">Urgencia</label>
                    <select 
                        {...register("urgencia", { required: true })}
                        className="inputTicketForm"
                        onChange={handleChanges}>
                        <option defaultChecked value={""} hidden></option>
                        {urgenciaList.map((value, index) => (
                            <option key={index} value={value}>{value}</option>
                        ))}
                    </select>

                    <label className="font-medium">Prioridad</label>
                    <select 
                        {...register("prioridad", { required: true })}
                        className="inputTicketForm"
                        disabled={true}>
                        <option defaultChecked value={""} hidden></option>
                        {prioridadList.map((value, index) => (
                            <option key={index} value={value}>{value}</option>
                        ))}
                    </select>

                    <label className="font-medium">Informado por</label>
                    <input 
                        {...register("informadoNombre", { required: true })}
                        className="inputform"
                        disabled={true}/>

                    <div className="grid grid-cols-2 justify-between col-span-2">
                        <label className="font-medium">Sucursal</label>
                        <select 
                            {...register("sucursalId", { 
                                required: "Es requerido seleccionar una sucursal",
                                min: 1,
                            })}
                            aria-invalid={errors.sucursalId ? "true" : "false"}
                            className="inputTicketForm"
                            onChange={handleSucursal}>
                            {userContext.auth?.user?.tipoUsuario === "General" ? (
                                <option value={userContext.auth.user.sucursalId}>{userContext.auth.user.sucursalNombre}</option>
                                )
                            : sucursales.map((sucursal, index) => (
                                <option value={sucursal.id} key={index}>{sucursal.smallDir}</option>
                                ))
                            }
                            <option defaultChecked value={0} hidden></option>
                        </select>
                        {errors.sucursalId?.type === "required" && (
                            <p className="col-start-2 valErrorText" role="alert">{errors.sucursalId.message}</p>
                        )}
                        {errors.sucursalId?.type === "min" && (
                            <p className="col-start-2 valErrorText" role="alert">Es requerido seleccionar una sucursal</p>
                        )}
                    </div>

                    <div className="grid grid-cols-2 justify-between col-span-2">
                        <label className="font-medium">Categoria</label>
                        <select 
                            {...register("categoriaId", {
                                required: "Es requerido seleccionar una categoría",
                                min: 1,
                            })}
                            aria-invalid={errors.categoriaId ? "true" : "false"}
                            className="inputTicketForm"
                            onChange={handleChanges}>
                            {categories.map((category, index) => (
                                <option value={category.categoriaId} key={index}>{category.categoriaNombre}</option>
                            ))
                            }
                            <option defaultChecked value={0} hidden></option>
                        </select>
                        {errors.categoriaId?.type === "required" && (
                            <p className="col-start-2 valErrorText" role="alert">{errors.categoriaId.message}</p>
                        )}
                        {errors.categoriaId?.type === "min" && (
                            <p className="col-start-2 valErrorText" role="alert">Es requerido seleccionar una categoría</p>
                        )}
                    </div>

                    <div className="grid grid-cols-2 justify-between col-span-2">
                        <label className="font-medium">Subcategoria</label>
                        <select 
                            {...register("subcategoriaId", {
                                required: "Es requerido seleccionar una subcategoría",
                                min: 1,
                            })}
                            aria-invalid={errors.subcategoriaId ? "true" : "false"}
                            className="inputTicketForm"
                            onChange={handleChanges}>
                            {SubcategorySelected?.subcategorias?.map((subcategory, index) => (
                                <option value={subcategory.subcategoriaId} key={index}>{subcategory.subcategoriaNombre}</option>
                            ))}
                            <option defaultChecked value={0} hidden></option>
                        </select>
                        {errors.subcategoriaId?.type === "required" && (
                            <p className="col-start-2 valErrorText" role="alert">{errors.subcategoriaId.message}</p>
                        )}
                        {errors.subcategoriaId?.type === "min" && (
                            <p className="col-start-2 valErrorText" role="alert">Es requerido seleccionar una subcategoría</p>
                        )}
                    </div>

                    <div className="grid grid-cols-2 justify-between col-span-2">
                        <label className="font-medium">Equipo</label>
                        <select 
                            {...register("equipoId", {
                                required: "Es requerido seleccionar un equipo",
                                min: 1,
                            })}
                            aria-invalid={errors.equipoId ? "true" : "false"}
                            className="inputTicketForm"
                            onChange={handleChanges}>
                            {EquiposSelected?.equiposDisponibles?.map((equipo, index) => (
                                <option value={equipo.id} key={index}>{equipo.nombre}</option>
                            ))}
                            <option defaultChecked value={0} hidden></option>
                        </select>
                        {errors.equipoId?.type === "required" && (
                            <p className="col-start-2 valErrorText" role="alert">{errors.equipoId.message}</p>
                        )}
                        {errors.equipoId?.type === "min" && (
                            <p className="col-start-2 valErrorText" role="alert">Es requerido seleccionar un equipo</p>
                        )}
                    </div>


                    <div className="grid grid-cols-2 justify-between col-span-2">
                        <label className="font-medium">Titulo</label>
                        <Input 
                            {...register("titulo", {
                                required: "Es requerido escribir un titulo para el ticket",
                                maxLength: 150,
                            })}
                            aria-invalid={errors.titulo ? "true" : "false"}
                            className="border border-gray-500 my-2"
                            onChange={handleChanges}
                            size="sm"
                            color="primary"/>
                        {errors.titulo?.type === "required" && (
                            <p className="col-start-2 valErrorText" role="alert">{errors.titulo.message}</p>
                        )}
                        {errors.titulo?.type === "maxLength" && (
                            <p className="col-start-2 valErrorText" role="alert">Número máximo de caracteres: 150</p>
                        )}
                    </div>

                    <label className="font-medium">Descripcion</label>
                    <TextArea 
                        {...register("descripcion")}
                        className="border border-gray-500 my-2"
                        onChange={handleChanges}
                        maxRows={5}
                        minRows={5}
                        size="sm"
                        color="primary"/>

                    <label className="font-medium">Archivo</label>
                    <Input 
                        className="border border-gray-500 my-2"
                        onChange={handleChanges}
                        size="sm"
                        type="file"
                        color="primary"
                        name="imagen"/>

                <div className="flex justify-end col-span-2 m-2 gap-2">
                    {saveLoading 
                    ? <LoadingRequest />
                    : <Button size="sm" color="success" className="w-20" type="submit">Registrar</Button> 
                    }
                    <Link to="/">
                        <Button size="sm" color="neutral">Cancelar</Button>
                    </Link>
                </div>

                {hasError && (
                    <ErrorMessage 
                        errorMessage={errorMessage}/>
                )}

                </form> 
            </div>
        </div>
    );
}