import { useContext, useCallback, useState } from "react";
import { UserContext } from "../context/UserConetxt";
import { AuthServices } from "../services";

interface Credentials {
    username: string
    password: string
}

export function useUser() {
    const { setJwt, auth, setAuth } = useContext(UserContext);
    const [state, setState] = useState({loading: false, error: false}); 

    const login = useCallback(({username, password}: Credentials) => {
        setState({loading: true, error: false});

        AuthServices.loginUser({username, password})
            .then(data => {
                setState({loading: false, error: false});
                if(setAuth != null)
                    setAuth({
                        user: data.usuario,
                        jwt: data.jwt
                    });
            })
            .catch(error => {
                if(setAuth != null)
                    setAuth({
                        user: null,
                        jwt: null
                    });
                setState({loading: false, error: true});
//                console.error(error)
            });

    },[setJwt, setAuth])

    const logout = useCallback(() => {
        if(setAuth != null)
            setAuth({
                user: null,
                jwt: null
            });
    },[setJwt, setAuth])

    return {
        isLoggin: Boolean(auth?.jwt),
        login,
        logout,
        isLoginLoadin: state.loading,
        isLoginError: state.error,
        userContext: useContext(UserContext)
    }
}