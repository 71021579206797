import { useForm } from "react-hook-form";
import { CategoryModel, ChecklistCreateModel, ChecklistRequest, FiltrosModel, SubcategoryModel, TareaCreateModel } from "../../types";
import { ChangeEvent, useEffect, useState } from "react";
import { Button } from "@mui/joy";
import { CategoriaServices, ChecklistServices } from "../../services";
import { useUser } from "../../hooks/useUser";
import { errorParser } from "../../utilities";
import { ErrorMessage, LoadingRequest } from "../UtilsComponents";
import { Link, useNavigate } from "react-router-dom";
import ClearIcon from '@mui/icons-material/Clear';
import { formatDate } from "../../utilities/showData";
import { AgregarTareas } from "./AgregarTareas";


export function ListCreateForm() {
    const navigate = useNavigate();

    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const checklistService = new ChecklistServices(token);

    const [tareas, setTareas] = useState<Array<TareaCreateModel>>([]);
    const [inputTarea, setInputTarea] = useState<string>("");
    const [subcategoriaSelected, setSubcategoriaSelected] = useState<number>(0);
    const [categoriaSelected, setCategoriaSelected] = useState<number>(0);

    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [requestLoading, setRequestLoading] = useState<boolean>(false);

    const [categories, setCategories] = useState<Array<CategoryModel>>([]);
    const [subcategorias, setSubcategorias] = useState<Array<SubcategoryModel>>([]);
    const [dataLoading, setDataLoading] = useState<boolean>(true);

    const categoryService = new CategoriaServices(token);

    const [filtros, setFiltros] = useState<FiltrosModel>({
        fechaInicial: formatDate(new Date(new Date().setDate(new Date().getDate() - 7))),
        fechaFinal: formatDate(new Date()),
        categoriaId: 1,
        sucursalId: 2,
        estado: "",
        responsableId: 0,
        subcategoriaId: 1,
        urgencia: "",
        checklistId: 0
    });

    const onChangeRevision = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputTarea(value);
    }

    const onClickAddRevision = () => {

        const tarea: TareaCreateModel = {
            nombre: inputTarea,
            descripcion: "",
            subcategoriaId: subcategoriaSelected
        }

        setTareas([...tareas, tarea]);
        setInputTarea("");
        setSubcategoriaSelected(0);
        setCategoriaSelected(0);
    }

    const handleDeleteRevision = (index: number) => {
        tareas.splice(index, 1);
        setTareas([...tareas]);
    }

    const { 
        register,
        handleSubmit,
        setValue,
        formState: {
            errors,
        }
    } = useForm<ChecklistCreateModel>();

    const saveList = handleSubmit(async (data: ChecklistCreateModel) => {
        try {
            setHasError(false);
            setRequestLoading(true);

            if(data.tareasRequest.length == 0) {
                const message = "Tiene que agregar al menos una revisión a la lista."

                setRequestLoading(false);
                setErrorMessage(message);
                setHasError(true);

                return;
            }

            const response = await checklistService.saveList(data);

            if(response.status === 201) {
                setRequestLoading(false);
                navigate("/revision");
            }

        } catch(error) {
            const message = errorParser(error);
            setRequestLoading(false);
            setErrorMessage(message);
            setHasError(true);
        }
    });

    const onFilterChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFiltros({
            ...filtros,
            [event.target.name]: event.target.value
        });

        const value  = parseInt(event.target.value);

        if(event.target.name == "categoriaId") {

            if(value != 0) {
                const subcategories = categories.find(c => c.id == value)?.subcategoria;
                setSubcategorias(subcategories!);
                setFiltros({
                    ...filtros,
                    "categoriaId": value,
                    "subcategoriaId": 0
                });
            }
        }

        if(event.target.name == "subcategoriaId") {

            if(value != 0) {
                setSubcategoriaSelected(value);
            }
        }
    }

    useEffect(() => {
        setValue("tareasRequest", tareas, { shouldDirty: true });
    }, [tareas]);


    useEffect(() => {
        const categories = categoryService.getCategories()
            .then(data => {
                setCategories(data)
                setSubcategorias(data.find(c => c.id == filtros.categoriaId)?.subcategoria!);
            });
        
        Promise.all([categories])
            .then(_ => setDataLoading(false));
    }, []);

    return (
        <div>
            <h2 className="text-3xl my-4 font-semibold">Crear Nueva Lista</h2>

            <form className="grid grid-cols-2" onSubmit={saveList}>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Nombre de la lista</label>
                    <input
                        {...register("nombre", {
                            required: "El nombre de la lista es requerido.",
                            maxLength: 50
                        })}
                        aria-invalid={errors.nombre ? "true" : "false"}
                        className="inputform"
                        placeholder="Nombre"/>
                    {errors.nombre?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.nombre.message}</p>
                    )}
                    {errors.nombre?.type === "maxLength" && (
                        <p className="col-start-2 valErrorText" role="alert">Número de caracteres permitidos: 50</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Hora limite</label>
                    <input
                        {...register("horaLimite")}
                        className="inputform"
                        type="time"
                        placeholder="Descripcion"/>
                </div>

                {/* <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Descripcion</label>
                    <input
                        {...register("descripcion", {
                            maxLength: 100
                        })}
                        className="inputform"
                        placeholder="Descripcion"/>
                    {errors.descripcion?.type === "maxLength" && (
                        <p className="col-start-2 valErrorText" role="alert">Número de caracteres permitidos: 150</p>
                    )}
                </div> */}

                <div className="grid grid-cols-1 justify-between col-span-2">
                    <AgregarTareas 
                        setValue={setValue}
                        tareas={tareas}
                        setTareas={setTareas}/>
                </div>

                {hasError && (
                    <ErrorMessage
                        customMessage={true}
                        errorMessage={errorMessage} />
                )}

                <div className="flex grid-cols-2 my-4 gap-2 justify-end col-span-2">
                    {requestLoading
                    ? <LoadingRequest />
                    : <Button size="sm" color="success" className="w-20" type="submit">Guardar</Button>
                    }
                    <Link to={"/revision"}>
                        <Button size="sm" color="neutral" className="w-20 mx-2">Cancelar</Button>
                    </Link>
                </div>

            </form>

        </div>
    );
}