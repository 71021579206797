import { useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button } from "@mui/joy";
import { SucursalModel } from "../../types";
import { SucursalServices } from "../../services";
import { useUser } from "../../hooks/useUser";
import { LoadingScreen } from "../UtilsComponents/LoadingScreen";
import { LoadingRequest, ErrorMessage } from "../UtilsComponents";
import { errorParser } from "../../utilities";

export function SucursalDetail() {
    const navigate = useNavigate();
    const { id } = useParams();
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [requestLoading, setRequestLoading] = useState<boolean>(false);

    const sucursalService = new SucursalServices(token);

    const { 
        register,
        handleSubmit,
        formState: { 
            errors,
            isLoading,
            isDirty,
        } 
    } = useForm<SucursalModel>({
        defaultValues: async () => sucursalService.getSucursalById(id)
    })

    const updateSucursal = handleSubmit(async (data: SucursalModel) => {
        try {
            setHasError(false);
            setRequestLoading(true);

            const response = await sucursalService.upadteSucursal(id, data)
            if(response.status == 200) {
                setRequestLoading(false);
                navigate("/sucursales")
            }

        } catch(error) {
            const message = errorParser(error);
            setRequestLoading(false);
            setErrorMessage(message);
            setHasError(true);
        }
    });

    const handleDelete = (id: string | undefined) => {
        sucursalService.deleteSucursal(id)
            .then(response => {
                if(response.status === 204)
                    navigate("/sucursales");
            })
    }

    return (
        <>
        {isLoading
        ? <LoadingScreen />
        :
        <div>
            <h2 className="text-3xl my-4 font-semibold">Detalles de la Sucursal</h2>
            <form className="grid grid-cols-2" onSubmit={updateSucursal}>

                <div className="grid grid-cols-2 justify-between col-span-2">

                    <label className="font-medium">Nombre de la sucursal</label>
                    <input {...register("smallDir", {
                                required: "Nombre de sucursal es requerido",
                                maxLength: 40
                            })}
                        className="inputform"
                        aria-invalid={errors.smallDir ? "true" : "false"}
                        placeholder="Sucursal"/>

                        {errors.smallDir?.type === "required" && (
                            <p className="col-start-2 valErrorText" role="alert">{errors.smallDir.message}</p>
                        )}
                        {errors.smallDir?.type === "maxLength" && (
                            <p className="col-start-2 valErrorText" role="alert">Maximo numero de caracteres es 40</p>
                        )}

                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">

                    <label className="font-medium">Direccion</label>
                    <input {...register("direccion",{
                                required: "La direccion es requerida",
                                maxLength: 100
                            })}
                        className="inputform"
                        placeholder="Direccion"/>

                    {errors.direccion?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.direccion.message}</p>
                    )}
                    {errors.direccion?.type === "maxLength" && (
                        <p className="col-start-2 valErrorText" role="alert">Maximo numero de caracteres es 100</p>
                    )}

                </div>

                <div className="flex justify-end col-span-2 m-2 gap-2 ">
                    {requestLoading
                    ? <LoadingRequest />
                    : <Button size="sm" 
                            disabled={!isDirty}
                            color="success" 
                            className="w-20" 
                            type="submit">
                        Actualizar
                    </Button>
                    }
                    <Button size="sm" color="danger" className="w-20 mx-2" onClick={() => handleDelete(id)}>Eliminar</Button>
                    <Link to="/sucursales">
                        <Button size="sm" color="neutral" className="w-20 mx-2">Regresar</Button>
                    </Link>
                </div>

                {hasError && (
                    <ErrorMessage
                        errorMessage={errorMessage} />
                )}

            </form>
        </div>
        }
        </>
    );
}