import { EditButton } from "../../utilities/UtilsComponents/EditButton";
import { useState, useEffect } from "react";
import { TipoUsuarioModel } from "../../types";
import { useUser } from "../../hooks/useUser";
import { UserServices } from "../../services/users";
import Table from "@mui/joy/Table";
import { LoadingComponent } from "../UtilsComponents/LoadingComponent";

export function UserTypesTable() {
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";
    const [userTypes, setUserTypes] = useState<Array<TipoUsuarioModel>>([])
    const [dataLoading, setDataLoading] = useState<boolean>(true);

    const userService = new UserServices(token);

    useEffect(() => {
        userService.getUserTypes()
            .then(data => {
                setUserTypes(data);
                setDataLoading(false);
            });
    }, []);


    return (
        <>
        {dataLoading
        ? <LoadingComponent />
        :
        <div className="overflow-auto max-h-[600px] rounded-lg border border-slate-200 my-4">
        <Table
            stickyHeader
            sx={(theme) => ({
                "th": {bgcolor: "#111827", color: "#ffffff"}
            })}>

        <table className="w-full">
            <thead>
                <tr>
                    <th>Tipo Usuario</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                {userTypes.map((tipo, index)=> {
                    return (
                    <tr key={index} className={`hover:bg-slate-100`}>
                        <td>{tipo.tipo}</td>
                        <td>
                            <EditButton url={`/usuarios/type/${tipo.id}`}/>
                        </td>
                    </tr>
                )})}
            </tbody>
        </table>

        </Table>
        </div>
        }
        </>
    );
}