
interface PreviewUrgenciaColors {
    Inmediata: string
    Dia: string
    TresDias: string
    MasDias: string
}

interface PreviewEstadoColors {
    Abierto: string,
    Proceso: string
    Terminado: string
    Vencido: string
}

interface PreviewEstadosSolicitudColor {
    Abierto: string
    Aprobado: string
    Proceso: string 
    Terminado: string
    Vencido: string
    Cancelado: string
    Rechazado: string
}

interface PreviewEstadosCheckListColor {
    OK: string;
    NOOK: string;
    SinRespuesta: string;
}

export const colorEstadoPreview = (estado: string) => {
        let colorSelect: keyof PreviewEstadoColors;

        const colors: PreviewEstadoColors = {
            Abierto: "bg-blue-200",
            Proceso: "bg-purple-200",
            Terminado: "bg-amber-200",
            Vencido: "bg-rose-200"
        }

        switch (estado) {
            case "Abierto": colorSelect = "Abierto"; break;
            case "Terminado": colorSelect = "Terminado"; break;
            case "Vencido":colorSelect = "Vencido";break;
            default: colorSelect = "Proceso"; break;
        }

        return colors[colorSelect];
}

export const colorEstadoSolicitudPreview = (estado: string) => {
        let colorSelect: keyof PreviewEstadosSolicitudColor;

        const colors: PreviewEstadosSolicitudColor = {
            Abierto: "bg-blue-200",
            Aprobado: "bg-emerald-200",
            Proceso: "bg-purple-200",
            Terminado: "bg-amber-200",
            Vencido: "bg-rose-200",
            Cancelado: "bg-red-200",
            Rechazado: "bg-red-200",
        }

        switch (estado) {
            case "Abierto": colorSelect = "Abierto"; break;
            case "Aprobado": colorSelect = "Aprobado"; break;
            case "Rechazado": colorSelect = "Rechazado"; break;
            case "Terminado": colorSelect = "Terminado"; break;
            case "Vencido":colorSelect = "Vencido";break;
            case "Cancelado":colorSelect = "Cancelado";break;
            default: colorSelect = "Proceso"; break;
        }

        return colors[colorSelect];
}

export const colorUrgenciaPreview = (urgencia: string) => {
    let colorSelect: keyof PreviewUrgenciaColors;

    const colors: PreviewUrgenciaColors = {
        Inmediata: "bg-red-700",
        Dia: "bg-orange-500",
        TresDias: "bg-yellow-400",
        MasDias: "bg-emerald-700"
    }

    switch(urgencia){
        case "Menos de 4 horas": colorSelect = "Inmediata"; break;
        case "Menos de 24 horas": colorSelect = "Dia"; break;
        case "24-72 horas": colorSelect = "TresDias"; break;
        case "+72 horas": colorSelect = "MasDias"; break;
        default: colorSelect = "MasDias"; break;
    }

    return colors[colorSelect];
}

export const colorChecklistRespuestaPreview = (estado: string) => {
    let colorSelect: keyof PreviewEstadosCheckListColor;

    const colors: PreviewEstadosCheckListColor = {
        OK: "bg-emerald-200",
        NOOK: "bg-rose-200",
        SinRespuesta: "bg-gray-200"
    }

    switch(estado) {
        case "NO OK": colorSelect = "NOOK"; break;
        case "OK": colorSelect = "OK"; break;
        default: colorSelect = "SinRespuesta"; break;
    }

    return colors[colorSelect];
}