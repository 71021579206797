import React, { Component } from 'react';
import { Outlet } from "react-router-dom";
import { NavMenu } from './NavMenu';
import { ShowSideBar } from "../utilities/UtilsComponents/ShowSideBar";
import { MobileNavBar } from './UtilsComponents/SideBarComponents/MobileNavBar';

export function Layout() {

      return (
      <div className='sm:min-h-screen sm:h-full sm:flex sm:min-w-screen sm:w-full'>
        <ShowSideBar>
          <NavMenu />
        </ShowSideBar>

        <MobileNavBar />

        <div className='p-4 w-full overflow-y-auto hidden sm:block max-h-screen'>
            <Outlet />
        </div>
      </div>
    );
}
