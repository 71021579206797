import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress  } from "@mui/joy";
import { useParams } from "react-router-dom";
import { RechazarTicket } from "./RechazarTicket";
import { useUser } from "../../hooks/useUser";
//import { updateTicketState } from "../../services/tickets";
import { TicketServices } from "../../services/tickets";
import { Estados } from "../../utilities/StateEnums";

    
interface Props {
    estado: string
    fechaAtencion: string
    setValue: any
    className: string
    previousUrl: string
    atendidoState: {
        atendidoState: boolean
        setAtendidoState: Dispatch<SetStateAction<boolean>>
    },
    cerradoState: {
        cerradoState: boolean
        setCerradoState: Dispatch<SetStateAction<boolean>>
    },
}

export function ChangeEstado({ 
    estado, 
    fechaAtencion, 
    setValue, 
    className, 
    previousUrl, 
    atendidoState, 
    cerradoState, 
}: Props) {
    const { userContext } = useUser();
    const { id } = useParams(); 
    const navigate = useNavigate();
    const [showRechazar, setShowRechazar] = useState(false);
    const token = userContext.auth?.jwt ?? "";

    const ticketService = new TicketServices(token);

    useEffect(() => {
        atendidoState.setAtendidoState(estado != "Abierto" && estado != "Vencido");
        cerradoState.setCerradoState(estado === "Terminado");
    }, [estado]);

    const onClickAtendido = () => {
        return ticketService.updateTicketState(id, "En Proceso")
                .then(response => {
                    if(response.status === 200){
                        setValue("estado", "En Proceso");
                        setValue("responsableNombre", userContext.auth?.user?.nombre);
                        atendidoState.setAtendidoState(!atendidoState.atendidoState);
                    }
                });
    }

    const onClickCerrar = () => {
        return ticketService.updateTicketState(id, "Terminado")
                .then(response => {
                    if(response.status === 200)
                        setValue("estado","Terminado")
                        cerradoState.setCerradoState(!cerradoState.cerradoState);
                        navigate("/");
                });
    }

    return (
        <>
        { !(estado === 'default')
        ? <div className={className}>

            { previousUrl === "/"
                ? <>
                    <Button onClick={onClickAtendido}
                            size="sm"
                            disabled={atendidoState.atendidoState || fechaAtencion.length > 0}
                            color="primary">
                                Atender
                    </Button>


                    <Button onClick={onClickCerrar}
                            size="sm"
                            disabled={cerradoState.cerradoState || estado === Estados.PendienteCompra}
                            color="warning">
                                Cerrar
                    </Button>
                </>
                : <></>
            }

            { !(userContext.auth?.user?.tipoUsuario === "Mantenimiento") 
                ? estado === "Terminado" && previousUrl !== "/"
                    ? <Button onClick={() => setShowRechazar(!showRechazar)}
                            size="sm"
                            color="danger">
                                Rechazar
                    </Button>
                    : <></>
                    : <></>
            }
            { showRechazar && (
                <RechazarTicket cancelar={ showRechazar} setCancelar={setShowRechazar}/>
            )

            }

        </div>
        : <div className={className}>
            <CircularProgress />
        </div>
        }
        </>
    );
}