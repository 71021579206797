import { useParams, useNavigate, Link} from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button } from "@mui/joy";
import { TipoUsuarioModel } from "../../types";
import { useUser } from "../../hooks/useUser";
import { UserServices } from "../../services";
import { LoadingScreen } from "../UtilsComponents/LoadingScreen";
import { LoadingRequest, ErrorMessage } from "../UtilsComponents";
import { errorParser } from "../../utilities";
import { useState } from "react";

export function UserTypeDetail() {
    const { id } = useParams(); 
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [requestLoading, setRequestLoading] = useState<boolean>(false);

    const userService = new UserServices(token);

    const navigate = useNavigate();
    const { 
        register,
        handleSubmit, 
        formState: { 
            errors,
            isLoading,
            isDirty,
        } 
    } = useForm<TipoUsuarioModel>({
        defaultValues: async () => userService.getUserTypeById(id)
    })

    const submitDeleteUserType = async (id: string | undefined) => {
        userService.deleteUserType(id)
            .then(response => {
                if(response.status === 204)
                    navigate("/usuarios");
            })
            .catch(error => console.error(error));
    }

    const submitUpdateUserType = handleSubmit(async (data: TipoUsuarioModel) => {
        try {
            setHasError(false);
            setRequestLoading(true);

            const response = await userService.updateUserType(data);

            if(response.status === 200) {
                setRequestLoading(false);
                navigate("/usuarios");
            }

        } catch(error) {
            const message = errorParser(error);
            setRequestLoading(false);
            setErrorMessage(message);
            setHasError(true);
        }
    });

    return (
        <>
        {isLoading
        ? <LoadingScreen />
        :
        <div>
            <h2 className="text-3xl my-4 font-semibold">Detalles del Tipo</h2>
            <form onSubmit={submitUpdateUserType} className="grid grid-cols-2">

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Nombre</label>
                    <input {...register("tipo", {
                                required: "Nombre del tipo de usuario es requerido",
                                maxLength: 30
                        })} 
                        aria-invalid={errors.tipo ? "true" : "false"}
                        className="inputform"
                        placeholder="Nombre del tipo"/>
                        {errors.tipo?.type === "required" && (
                            <p className="col-start-2 valErrorText" role="alert">{errors.tipo.message}</p>
                        )}
                        {errors.tipo?.type === "maxLength" && (
                            <p className="col-start-2 valErrorText" role="alert">Maximo numero de caracteres es 20</p>
                        )}
                </div>

                <div className="flex justify-end col-span-2 m-2 gap-2 ">
                    {requestLoading
                    ? <LoadingRequest />
                    : <Button size="sm" 
                            disabled={!isDirty}
                            color="success" 
                            className="w-20" 
                            type="submit">
                        Actualizar
                    </Button>
                    }
                    <Button size="sm" color="danger" className="w-20 mx-2" onClick={() => submitDeleteUserType(id)}>Eliminar</Button>
                    <Link to="/usuarios">
                        <Button size="sm" color="neutral" className="w-20 mx-2">Regresar</Button>
                    </Link>
                </div>

                {hasError && (
                    <ErrorMessage 
                        errorMessage={errorMessage} />
                )}

            </form>
        </div>
        }
        </>
    );
}