import { ChangeEvent, Dispatch, SetStateAction } from "react";
import Input from "@mui/joy/Input/Input"

interface Props {
    searchValue: string
    setSearchValue: Dispatch<SetStateAction<string>>
    placeholder?: string;
    className?: string;
}

export function SearchFilter({ searchValue, setSearchValue, placeholder, className }: Props) {
    
    const onSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    }

    return (
        <Input 
            className={className ?? "my-2"} 
            placeholder={placeholder ?? "Buscar... (folio, titulo, equipo)"}
            size="sm" 
            value={searchValue}
            onChange={onSearchValueChange}/>
    )
}