import { Dispatch, SetStateAction } from "react";

interface Props {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>
    state: boolean;
    idEquipo: string | undefined;
    deactivateFunction(id: string | undefined): void; 
    reactivateFunction(id: string | undefined): void; 
}

export function ChangeEquipoStatus({showModal, setShowModal, state, idEquipo, deactivateFunction, reactivateFunction}: Props) {

    return (
        <div className="p-4 w-[400px] border rounded-md shadow-md bg-white">

            <div className="grid grid-cols-2" >
                {
                state
                ? <p className="mb-2 font-medium col-span-2 text-center">¿Seguro qué quiere desactivar este equipo?</p> 
                : <p className="mb-2 font-medium col-span-2 text-center">¿Seguro qué qeiere reactivar este equipo?</p> 
                }

                <div className="flex justify-center col-span-2 m-2 gap-2">
                    {
                    state
                    ? <button className="py-1 text-sm font-medium bg-red-700 text-gray-50 w-20 rounded-md disabled:bg-red-300"
                        onClick={() => deactivateFunction(idEquipo)}>
                        Desactivar
                    </button>
                    : <button className="py-1 text-sm font-medium bg-blue-700 text-gray-50 w-20 rounded-md disabled:bg-red-300"
                        onClick={() => reactivateFunction(idEquipo)}>
                        Activar
                    </button>
                    }
                    <button className="py-1 text-sm font-medium bg-gray-700 text-gray-50 w-20 rounded-md"
                            onClick={() => setShowModal(!showModal)}>Cancelar</button>
                </div>
            </div>

        </div>
    );
}