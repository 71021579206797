import CircleIcon from '@mui/icons-material/Circle';
import { useForm } from "react-hook-form";
import { ChecklistServices } from "../../services";
import { useUser } from "../../hooks/useUser";
import { HistorialRevision } from "../../types/checklist";
import { Link, useParams } from "react-router-dom";
import { Fragment, useState } from "react";
import { LoadingScreen } from "../UtilsComponents/LoadingScreen";
import { Button, Table } from "@mui/joy";
import { colorChecklistRespuestaPreview } from '../../utilities/selectColorPreview';

export function HistorialDetail() {
    const { id } = useParams();
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const checklistService = new ChecklistServices(token);

    const { register, watch } = useForm<HistorialRevision>({
        defaultValues: async () => {
            const revision = await checklistService.getRevisionHistoryById(id)

            Promise.all([revision])
                .then(values => setIsLoaded(true));

            return revision; 
        }
    });

    const listaName = watch("listaName");
    const listTares = watch("tareaRevisiones");

    return (
        <>
        {!isLoaded
        ? <LoadingScreen />
        :
        <div>

            <h2 className="text-3xl my-4 font-semibold">Historial de: {listaName}</h2>

            <form className="grid grid-cols-2">

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="listaName">Lista:</label>
                    <input {...register("listaName")}
                        id="listaName"
                        disabled
                        className="inputform"/>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="responsableName">Responsable:</label>
                    <input {...register("responsableName")}
                        id="responsableName"
                        disabled
                        className="inputform"/>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="sucursalName">Sucursal:</label>
                    <input {...register("sucursalName")}
                        id="sucursalName"
                        disabled
                        className="inputform"/>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="fechaCreacion">Fecha de Revisión:</label>
                    <input {...register("fechaCreacion")}
                        id="fechaCreacion"
                        type="datetime-local"
                        disabled
                        className="inputform"/>
                </div>

                <div className="grid grid-cols-1 justify-items-center col-span-2 ">

                    <div className="overflow-auto max-h-[600px] rounded-lg border border-slate-200">
                        <Table
                            sx={(theme) => ({
                                "th": {bgcolor: "#111827", color: "#ffffff"}
                            })}>
                            <thead>
                                <tr>
                                    <th>Tarea</th>
                                    <th>Comentario</th>
                                </tr>
                            </thead>
                            <tbody>
                            {listTares.map((tarea, index) => (
                                <Fragment key={index}>
                                <tr>
                                    <td className="font-semibold text-base">
                                        <p>{tarea.tareaNombre} 
                                            {tarea.estado.length > 0 && (
                                                <span className={`px-2 ${colorChecklistRespuestaPreview(tarea.estado)} rounded-xl text-center w-30 mx-2`}>
                                                {tarea.estado}
                                                </span>
                                            )}
                                        </p>
                                    </td>
                                    <td>{tarea.comentario != null ? <p>{tarea.comentario}</p> : <p className="italic">Sin comentario</p>}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} className="border-0">
                                        {tarea.revisionesEquipo.map((revision, index) =>(
                                        <div key={index} className="flex gap-3 my-2">

                                            <div className="flex items-center"><CircleIcon sx={{ fontSize: 10}}/></div>
                                            
                                            <div className="grid grid-cols-2 gap-4">
                                                <div className="flex gap-2">
                                                    <div className="font-medium">Equipo: </div>
                                                    <div>{revision.nombreEquipo}</div>
                                                </div>
                                                <div className="flex gap-2">
                                                    <div className="font-medium">Estado: </div>
                                                    <div>
                                                        <span className={`px-2 ${colorChecklistRespuestaPreview(revision.estado)} rounded-xl text-center w-30`}>
                                                        {revision.estado}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        ))}
                                    </td>
                                </tr>

                                </Fragment>
                            ))}
                            </tbody>
                        </Table>
                    </div>

                </div>

                <div className="flex justify-end col-span-2 m-2 gap-2 ">
                    <Link to="/checklist/historial">
                        <Button size="sm" color="neutral" className="w-20 mx-2">Regresar</Button>
                    </Link>
                </div>

            </form>
        </div>
        }</>
    );
}