import { ChartColor, Metrics } from "../../../../types";
import { MetricType } from "../../../../types/metrics";
import { BarChart } from "./BarChart";
import { BarStackedChart } from "./BarStackedChart";

interface Graphics {
    sucursal: boolean;
    categoria: boolean;
    subcategoria: boolean;
    equipo: boolean;
    responsable: boolean;
}

interface Props {
    color: ChartColor
    width: number
    height: number
    dataMetrics: Map<string, Metrics>
    showGraphics: Graphics
    numberType: "Integer" | "Float" | "Porcent"
    metricType: MetricType;
}

export function BarStackedChartContainer({ width = 700, height = 400, dataMetrics, showGraphics, numberType, color, metricType }: Props) { 

    const dataConvert = new Map<string, Metrics>(Object.entries(dataMetrics));
    const data = dataMetrics instanceof Map ? dataMetrics : dataConvert;

    return (
        <div className="flex text-center flex-wrap">

            {showGraphics.sucursal && (
            <div>
                {data.get("Sucursal")?.data.length !== 0
                    ? 
                    <BarStackedChart
                        metricType={metricType}
                        color={color}
                        label="Sucursales"
                        width={width} 
                        height={height - 40} 
                        type={numberType}
                        dataMetrics={data.get("Sucursal")} />
                    : 
                    <div className="flex items-center justify-center w-[400px] h-[260px]">
                        <p className="text-lg my-4 font-semibold text-center items-center">Sin Datos</p>
                    </div> 
                }
                <p className="text-lg mb-4 mt-0 font-semibold text-center items-center">Sucursal</p>
            </div>
            )}

            {showGraphics.categoria && (
            <div>
                {data.get("Categoria")?.data.length !== 0
                ?
                <BarStackedChart 
                    metricType={metricType}
                    color={color}
                    label="Categorias"
                    width={width} 
                    height={height - 40} 
                    type={numberType}
                    dataMetrics={data.get("Categoria")} />
                :
                <div className="flex items-center justify-center w-[400px] h-[260px]">
                    <p className="text-lg my-4 font-semibold text-center items-center">Sin Datos</p>
                </div> 
                }
                <p className="text-lg mb-4 mt-0 font-semibold text-center items-center">Categoría</p>
            </div>
            )}

            {showGraphics.subcategoria && (
            <div>
                {data.get("Subcategoria")?.data.length !== 0
                ?
                <BarStackedChart 
                    metricType={metricType}
                    color={color}
                    label="Subcategorias"
                    width={width}
                    height={height - 40} 
                    type={numberType}
                    dataMetrics={data.get("Subcategoria")} />
                :
                <div className="flex items-center justify-center w-[400px] h-[260px]">
                    <p className="text-lg my-4 font-semibold text-center items-center">Sin Datos</p>
                </div> 
                }
                <p className="text-lg mb-4 mt-0 font-semibold text-center items-center">Subcategoría</p>
            </div>
            )}

            {showGraphics.equipo && (
            <div>
                {data.get("Equipo")?.data.length !== 0
                ?
                <BarStackedChart 
                    metricType={metricType}
                    color={color}
                    label="Equipos"
                    width={width} 
                    height={height - 40} 
                    type={numberType}
                    dataMetrics={data.get("Equipo")} />
                :
                <div className="flex items-center justify-center w-[400px] h-[260px]">
                    <p className="text-lg my-4 font-semibold text-center items-center">Sin Datos</p>
                </div> 
                }
                <p className="text-lg mb-4 mt-0 font-semibold text-center items-center">Equipo</p>
            </div>
            )}

            {showGraphics.responsable && (
            <div>
                {data.get("Responsable")?.data.length !== 0
                ?
                <BarStackedChart 
                    metricType={metricType}
                    color={color}
                    label="Responsables"
                    width={width} 
                    height={height - 40} 
                    type={numberType}
                    dataMetrics={data.get("Responsable")} />
                :
                <div className="flex items-center justify-center w-[400px] h-[260px]">
                    <p className="text-lg my-4 font-semibold text-center items-center">Sin Datos</p>
                </div> 
                }
                <p className="text-lg mb-4 mt-0 font-semibold text-center items-center">Responsable</p>
            </div>
            )}
        </div>
    );
}
