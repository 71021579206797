import axios from "axios";
import { SubcategoryModel } from "../types";
import { BaseService } from "./BaseService";

const urlBase = "/subcategory";

interface UpdateProps {
    id: number
    nombreSubcategoria: string
    categoriaId: number
}

interface SaveTicketProps {
    nombreSubcategoria: string
    categoriaId: number
}

//export const getSubcategories = (token: string) => {
//    return axios.get(urlBase, { headers: {Authorization: `Bearer ${token}`}})
//        .then(response => {
//            const { data } = response;
//            return data;
//        }) 
//}

//export const getSubcategoryById = (id: string | undefined, token: string): Promise<SubcategoryModel> => {
//    return axios.get(urlBase + `/${id}`, { headers: {Authorization: `Bearer ${token}`}})
//                .then(response => {
//                    const { data } = response;
//                    return data;
//                });
//}

//export const saveSubcategory = ({ nombreSubcategoria, categoriaId }: SaveTicketProps, token: string) => {
//    return axios.post(urlBase, {
//        nombreSubcategoria,
//        categoriaId
//    }, { headers: {Authorization: `Bearer ${token}`}});
//}

//export const deleteSubcategoryById = (id: number, token: string) => {
//    return axios.delete(urlBase + `/${id}`, { headers: {Authorization: `Bearer ${token}`}})
//}

//export const updateSubcategory = (subcategory: UpdateProps, token: string) => {
//    return axios.put(urlBase + `/${subcategory.id}`, {
//        nombreSubcategoria: subcategory.nombreSubcategoria,
//        categoriaId: subcategory.categoriaId
//    }, { headers: {Authorization: `Bearer ${token}`}})
//}

export class SubcategoryServices extends BaseService {

    /**
     * @param { string } token - Auth token for getting access to Api Backend.
     */
    constructor(token: string) {
        super(token, "/subcategory");
    }

    /**
     * Get all subcategories.
     * 
     * @returns - List of subcategories.
     */
    public async getSubcategories() {
        const response = await this.axios.get('');
        const { data } = response;
        return data; 
    }

    /**
     * Get a subcategory by its id.
     * 
     * @param { string | undefined } id - Subcategory id.
     * @returns - Subcategory model.
     */
    public async getSubcategoryById(id: string | undefined): Promise<SubcategoryModel> {
        const response = await this.axios.get<SubcategoryModel>(`/${id}`)
        const { data } = response;
        return data;
    }

    /**
     * Save a nuew ticket in database.
     * 
     * @param { SaveTicketProps } ticketData - New ticket data.
     * @returns 
     */
    public async saveSubcategory({ nombreSubcategoria, categoriaId }: SaveTicketProps) {
        return await this.axios.post("", {
            nombreSubcategoria,
            categoriaId
        });
    }

    /**
     * Delete a subcategory from database.
     * 
     * @param { number } id - Subcategory id.
     * @returns 
     */
    public async deleteSubcategoryById(id: number) {
        const response = await this.axios.delete(`/${id}`);
        const { data } = response;
        return data;
    }

    /**
     * Update a subcategory.
     * 
     * @param { UpdateProps } subcategory - New data for the subcategory.
     * @returns 
     */
    public async updateSubcategory(subcategory: UpdateProps) {
        return await this.axios.put(`/${subcategory.id}`, {
            nombreSubcategoria: subcategory.nombreSubcategoria,
            categoriaId: subcategory.categoriaId
        });
    }
}