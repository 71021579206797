import axios from "axios";
import { CategoryModel } from "../types"; 
import { BaseService } from "./BaseService";

interface SaveCategoryProps {
    nombreCategoria: string
}

interface UpdateCategoryProps {
    id: number
    nombreCategoria: string
}

export class CategoriaServices extends BaseService { 

    /**
     * @param { string } token - Auth token for getting access to API Backend.
     */
    constructor(token: string) {
        super(token, "api/category");
    }

    /**
     *  Get list of categories
     * 
     * @returns { Promise<Array<CategoryModel>> } - Returns a promise with an array of CategoryModel
     */
    async getCategories(): Promise<Array<CategoryModel>> {
        const response = await this.axios.get<Array<CategoryModel>>("");
        const { data } = response;
        return data; 
    }

    /**
     *  Get a category by its id
     * 
     * @param { string | undefined } id - Categoria Id
     * @returns { Promise<CategoryModel> } - Returns a promise with the category
     */
    async getCategoryById(id: string | undefined): Promise<CategoryModel> {
        const response = await this.axios.get<CategoryModel>(`/${id}`);
        const { data } = response;
        return data;
    }

    /**
     *  Save a new category register
     * 
     * @param { SaveCategoryProps } nombreCategoria - Name for the new category
     * @returns 
     */
    saveCategory({ nombreCategoria }: SaveCategoryProps) {
        return this.axios.post("", {
            nombreCategoria
        });
    }

    /**
     *  Delete a category by its id
     * 
     * @param { string | undefined } id - Category id
     * @returns 
     */
    deleteCategoryById(id: string | undefined) {
        return this.axios.delete(`/${id}`);
    }

    /**
     *  Update a category by its id
     * 
     * @param { UpdateCategoryProps } updateCategory - New category data
     * @returns 
     */
    updateCategory({id, nombreCategoria}: UpdateCategoryProps) {
        return this.axios.put(`/${id}`, { nombreCategoria });
    }
}