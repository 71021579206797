import { useForm } from "react-hook-form";
import { CodePrefixRequest } from "../../../types";
import { Button } from "@mui/joy";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../../hooks/useUser";
import { EquipoServices } from "../../../services/equipos";
import { LoadingScreen } from "../../UtilsComponents/LoadingScreen";

export function CodeEquipoDetail() {

    const { id } = useParams(); 
    const navigate = useNavigate();

    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const equipoService = new EquipoServices(token);

    const {
        register,
        handleSubmit,
        getValues,
        formState: { 
            errors,
            isDirty,
            isLoading
        }
    } = useForm<CodePrefixRequest>({
        defaultValues: async () => equipoService.getCodePrefixById(id)
    });

    const submitUpdateCode = handleSubmit((data: CodePrefixRequest) => {
        equipoService.updateCodePrefix(id, data)
            .then(response => {
                if(response.status === 200) {
                    navigate("/equipos");
                }
            })
            .catch(error => console.error(error));
    });

    const deleteCodePrefix = () => {
        equipoService.deleteCodePrefix(id)
            .then(response => {
                if(response.status === 204) {
                    navigate("/equipos");
                }
            })
            .catch(error => console.error(error));
    }

    return (
        <>
        {isLoading
        ? <LoadingScreen />
        :
        <div>
            <h2 className="text-3xl my-4 font-semibold">Código {getValues("prefijo")}</h2>
            <form 
                className="grid grid-cols-2" 
                onSubmit={submitUpdateCode} 
                autoComplete="off">

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="prefijo">Prefijo: </label>
                    <input {...register("prefijo", {
                            required: "Debe ingresar un prefijo",
                            maxLength: 10
                        })}
                        id="prefijo"
                        className="inputform"/>
                    {errors.prefijo?.type === "required" && (
                        <p className="valErrorText col-start-2" role="alert">{errors.prefijo.message}</p>
                    )}
                    {errors.prefijo?.type === "maxLength" && (
                        <p className="valErrorText col-start-2" role="alert">Número de caracteres permitidos: 10</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="significado">Significado: </label>
                    <input {...register("significado", {
                            required: "Debe ingresar el significado del prefijo",
                            maxLength: 100
                        })}
                        id="significado"
                        className="inputform"/>
                    {errors.significado?.type === "required" && (
                        <p className="valErrorText col-start-2" role="alert">{errors.significado.message}</p>
                    )}
                    {errors.significado?.type === "maxLength" && (
                        <p className="valErrorText col-start-2" role="alert">Número de caracteres permitidos: 100</p>
                    )}
                </div>

                <div className="flex justify-between col-span-2 m-2 gap-2">
                    <div>
                        <Button 
                            size="sm" 
                            color="danger" 
                            className="w-20 mx-2" 
                            onClick={deleteCodePrefix}>
                            Eliminar
                        </Button>
                    </div>
                    <div className="flex gap-2">
                        <Button 
                            size="sm" 
                            color="success"
                            className="w-20" 
                            disabled={!isDirty}
                            type="submit">
                                Guardar
                        </Button>
                        <Button 
                            onClick={() => navigate(-1)}
                            size="sm" 
                            color="neutral" 
                            className="w-20 mx-2">
                                Cancelar
                        </Button>
                    </div>
                </div>
            </form>
        </div>
        }
        </>
    );
}