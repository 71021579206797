import { Bar, getElementsAtEvent } from 'react-chartjs-2';
import { BarElement, Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chart.js/auto';
import 'chartjs-plugin-datalabels';
import { ChartColor, Metrics } from '../../../../types';
import { GRAPHIC_COLORS } from '../../../../utilities/StateEnums';
import { MouseEvent, useRef, useState } from 'react';
import { ModalContainer } from '../../../UtilsComponents/ModalContainer';
import { ShowBarData } from '../../ShowBarData';
import { MetricType } from '../../../../types/metrics';

type Props = {
    width: number;
    height: number;
    dataMetrics?: Metrics;
    label: string,
    type: "Integer" | "Float" | "Porcent"
    color: ChartColor
    metricType: MetricType;
};

export function BarChart({ dataMetrics, label, width, height, color, type, metricType }: Props) {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [dataIndexNum, setDataIndexNum] = useState(0);

    const data = {
        labels: dataMetrics?.labels,
        datasets: [
        {
            label: label,
            data: dataMetrics?.data,
            backgroundColor: GRAPHIC_COLORS[color].backgroundColor,
            borderColor: GRAPHIC_COLORS[color].borderColor,
            borderWidth: 1,
            anchor: 'end',
            align: 'start',
        },
        ],
    };

    const chartRef = useRef();

    const onClick = (event: MouseEvent<HTMLCanvasElement>) => {
        if(getElementsAtEvent(chartRef.current!, event).length > 0) {
            setDataIndexNum(getElementsAtEvent(chartRef.current!, event)[0].index)
            setShowModal(!showModal);
        }
    }

    const options = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            datalabels: {
                display: true,
                color: 'rgba(17, 24, 39, 1)',
                formatter: function(value: any, _context: any) {
                    if(type === "Float")
                        return value.toFixed(2);
                    
                    if(type === "Porcent")
                        return `${value} %`
                    
                    return value;
                },
        },
        },
    };

    return (
        <>
        <div className={`w-[${width}px] h-[${height}px]`}>
            <Bar 
                onClick={onClick}
                ref={chartRef}
                data={data} 
                options={options} 
                plugins={[ChartDataLabels]}/>
        </div>
        {showModal && (
            <ModalContainer>
                <ShowBarData
                    metricType={metricType}
                    setClose={setShowModal}
                    close={showModal}
                    data={data.datasets[0].data![dataIndexNum]}
                    ticketsIdList={dataMetrics!.listIds[dataIndexNum]} />
            </ModalContainer>
        )}

        </>
    );
};
