import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import { useForm } from "react-hook-form";
import { MantenimientoResponse, UsuarioModel } from "../../types";
import { MantenimientoServices, UserServices } from "../../services";
import { Button, CircularProgress } from "@mui/joy";
import { useEffect, useState } from "react";
//import { getUsersByRole } from "../../services/users";


export function MantenimientoDetailConsultas() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const manService = new MantenimientoServices(token);
    const userService = new UserServices(token);

    const [responsables, setResponsables] = useState<Array<UsuarioModel>>([]);
    const [userLoading, setUserLoading] = useState(true);
 

    const { register, watch, handleSubmit } = useForm<MantenimientoResponse>({
        defaultValues: async () => manService.getRegistroMantenimiento(id)
    });

    const archivo = watch("archivo");

    const responsable = watch("responsableId")

    useEffect(()=> {
        userService.getUsersByRole("Mantenimiento")
            .then(data => {
                setResponsables(data);
                setUserLoading(false);
            });
    }, []);

    return (
        <div>
            <h2 className="text-3xl my-4 font-semibold">Mantenimieto Realizado</h2>
            {responsable !== undefined &&  userLoading === false ?
            <form className="grid grid-cols-2">

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="equipo">Equipo</label>
                    <input {...register("nombreEquipo")}
                        id="equipo"
                        disabled={true}
                        className="inputform"/>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Responsable</label>
                    <select {...register("responsableId")}
                        disabled={userContext.auth?.user?.tipoUsuario !== "Admin"}
                        className="selectform">
                        {responsables.map((user, index) => (
                            <option key={index} value={user.id}>{user.nombre}</option>
                        ))}
                    </select>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="responsable">Fecha del Mantenimiento</label>
                    <input {...register("fechaUltimoMantenimiento")}
                        id="responsable"
                        disabled={true}
                        type="date"
                        className="inputform"/>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="fechaProx">Próximo Mantenimiento</label>
                    <input {...register("fechaProximoMantenimiento")}
                        id="fechaProx"
                        disabled={true}
                        type="date"
                        className="inputform"/>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="comentario">Comentarios</label>
                    <textarea {...register("comentario")}
                        className="textareaform"
                        id="comentario"
                        rows={5}
                        disabled={true}/>
                </div>

                {archivo !== null && (
                    <img src={`${archivo}`} className="w-32 h-32"/>
                )}

                <div className="flex justify-end col-span-2 m-2 gap-2 ">
                    <Button 
                        onClick={() => navigate(-1)} 
                        size="sm" 
                        color="neutral" 
                        className="w-20 mx-2">
                        Regresar
                    </Button>
                </div>
            </form>
            : <div className="flex justify-center w-full max-h-full"><CircularProgress /></div>}
        </div>
    );
}