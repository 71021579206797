import { useEffect, useState } from "react";
import Table from "@mui/joy/Table/Table";
import { EditButton } from "../../utilities/UtilsComponents/EditButton"
import { SearchFilter } from "../../utilities/UtilsComponents/SearchFilter"
import { Filtros } from "../UtilsComponents/Filtros";
import { FiltrosModel, ShowFiltros, TicketResponse } from "../../types";
import { FiltroServices } from "../../services";
import { formatDate } from "../../utilities/showData";
import { useUser } from "../../hooks/useUser";
import { colorUrgenciaPreview, colorEstadoPreview } from "../../utilities/selectColorPreview";
import { TicketServices } from "../../services";
import { LoadingComponent } from "../UtilsComponents/LoadingComponent";
import { ElementsNotFound } from "../UtilsComponents";
import { TicketExportFilters } from "../../types/ticket";

interface Props {
    titulo: boolean
    onlyNonTerminados: boolean
    filtrosShowed: ShowFiltros
    previousURL?: string
}

export function TicketHome({ titulo, onlyNonTerminados, filtrosShowed, previousURL}: Props) {
    const initialDate = !onlyNonTerminados ? formatDate(new Date(new Date().setDate(new Date().getDate() - 7))) : ""; 
    const endDate = !onlyNonTerminados ? formatDate(new Date()) : "";

    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";
    const [tickets, setTickets] = useState<Array<TicketResponse>>([]);
    const [search, setSearch] = useState<string>(""); 
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [filtros, setFiltros] = useState<FiltrosModel>({
        fechaInicial: initialDate,
        fechaFinal: endDate,
        categoriaId: 0,
        sucursalId: 0,
        estado: "",
        responsableId: 0,
        subcategoriaId: 0,
        urgencia: "",
        checklistId: 0,
        especialidad: [],
        doctores: [],
        tipoDoctor: ""
    });

    const ticketService = new TicketServices(token);

    let listTicketsFilters: Array<TicketResponse> = [];

    useEffect(() => {
        if(onlyNonTerminados) {
            setFiltros({
                ...filtros,
                fechaInicial: undefined,
                fechaFinal: undefined,
            })
        }
    }, [])

    useEffect(()=>{
        setDataLoading(true);

        if(onlyNonTerminados) {
            ticketService.getTickets(filtros.fechaInicial, filtros.fechaFinal, true)
                .then(data => {
                    setTickets(data);
                    setDataLoading(false);
                });
        } else {
            ticketService.getTickets(filtros.fechaInicial, filtros.fechaFinal)
                .then(data => {
                    setTickets(data);
                    setDataLoading(false);
                });
        }
    }, [filtros.fechaInicial, filtros.fechaFinal]);


    let alternativeList: Array<TicketResponse> = onlyNonTerminados == false
                        ? tickets
                        : tickets.filter(ticket => {
                            return ticket.estado != "Terminado";
                        });

    if(userContext.auth?.user?.tipoUsuario === "General"){
        alternativeList = alternativeList.filter(ticket => {
            return ticket.sucursalId === userContext.auth?.user?.sucursalId;
        })
    }

    const onClickExportCsv = async () => {
        try {
            const filters: TicketExportFilters = {
                fechaInicial: filtros.fechaInicial,
                fechaFinal: filtros.fechaFinal,
                categoriaId: filtros.categoriaId,
                sucursalId: filtros.sucursalId,
                responsableId: filtros.responsableId,
                urgencia: filtros.urgencia,
                estado: filtros.estado
            } ;

            const response = await ticketService.exportTicketData(filters);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `tickts-data.csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error descargando el archivo', error);
        }
    }

    listTicketsFilters = FiltroServices.filtrosTickets(alternativeList, search, filtros,);

    return (
        <div className="w-full">
            {titulo && 
            <h2 className="text-3xl my-4 font-semibold">Consulta de Tickets</h2>
            }
            <SearchFilter searchValue={search} setSearchValue={setSearch}/>
            <Filtros 
                typeFilter={"Ticket"}
                filtros={filtros} 
                setFiltros={setFiltros} 
                showFiltros={filtrosShowed}/>

            <button className={`w-fit h-[32px] bg-gray-900 text-white py-1 px-3 rounded-lg font-medium my-3`}
                onClick={onClickExportCsv}>
                Exportar tickets
            </button>

            {dataLoading
            ? <LoadingComponent /> 
            :
            <div className="overflow-auto max-h-[600px] rounded-lg border border-slate-200">
                <Table
                    stickyHeader
                    sx={(theme) => ({
                        "th": {bgcolor: "#111827", color: "#ffffff"},
                    })}>

                <table className="w-full">
                    <thead>
                        <tr>
                            <th>Folio</th>
                            <th>Urgencia</th>
                            <th>Estado</th>
                            <th>Sucursal</th>
                            <th>Titulo</th>
                            <th>Responsable</th>
                            <th>Equipo</th>
                            <th>Fecha de Creacion</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {listTicketsFilters.map((ticket, index)=> {
                            return (
                            <tr key={index} className={`hover:bg-slate-100`}>
                                <td>{ticket.id}</td>
                                <td>
                                    <div className="flex items-center">
                                        <div className={`${colorUrgenciaPreview(ticket.urgencia)} w-2  h-2 rounded-full mr-1`}></div>
                                        <p className={`text-center w-2/4 rounded-lg font-semibold text-gray-900 opacity-85`}>{ticket.urgencia}</p>
                                    </div>
                                </td>
                                <td>
                                        <span className={`px-2 ${colorEstadoPreview(ticket.estado)} rounded-xl text-center w-30`}>
                                            {ticket.estado}
                                        </span>
                                </td>
                                <td>{ticket.sucursalNombre}</td>
                                <td>{ticket.titulo}</td>
                                <td>{ticket.responsableNombre}</td>
                                <td>{ticket.equipoNombre}</td>
                                <td>{ticket.fechaInforma}</td>
                                <td>
                                    <EditButton url={`/ticket/${ticket.id}`} previousUrl={previousURL}/>
                                </td>
                            </tr>
                        )})}
                    </tbody>
                </table>

                </Table>

                {listTicketsFilters.length == 0 && (
                    <ElementsNotFound />
                )}

            </div>
            }
        </div>
    );
}