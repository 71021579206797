import { useState} from 'react';
import { Link } from 'react-router-dom';
import { MenuAccount } from "../utilities/UtilsComponents/MenuAccount";
import './NavMenu.css';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import HistoryIcon from '@mui/icons-material/History';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { useUser } from '../hooks/useUser';
import { DropMenuContainer } from './UtilsComponents/SideBarComponents/DropMenuContainer';
import ConstructionIcon from '@mui/icons-material/Construction';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import TodayIcon from '@mui/icons-material/Today';
import CheckIcon from '@mui/icons-material/Check';
import { ROLES } from '../utilities/StateEnums';

export function NavMenu () {
    const [showNavMenu, setShowNavMenu] = useState(true);
    const { userContext } = useUser();

    return (
      <header className="max-h-screen">
        <div className={`hidden w-full h-auto fixed sm:flex sm:flex-col sm:items-center sm:h-full sm:overflow-hidden text-gray-400 bg-gray-900 sm:rounded-r ${showNavMenu ? "sm:w-[180px]" : "sm:w-8" } duration-300 relative`}>

            <div className='hidden sm:contents'>
                <div className={`flex flex-col items-center w-full pr-3 mr-4 mt-3 ${!showNavMenu && "opacity-0"}`} >
                    <img 
                        className="w-72"
                        src="/logo_regiodental.png" 
                        alt="Logo Regiodental"/>
                    <span className="text-center text-sm font-semibold w-11/12">Tickets</span>
                </div>
                <button className={"absolute right-0 top-4 cursor-pointer z-40 "} onClick={() => setShowNavMenu(!showNavMenu)}>
                { showNavMenu 
                ? <MenuOpenIcon className="w-8 h-8 fill-current" sx={{ fontSize: 27}}/>
                : <MenuIcon className="w-8 h-8 fill-current" sx={{ fontSize: 27}}/>
                }
                </button>
            </div>


            <div className={`h-auto w-full flex relative sm:block sm:w-full px-2 ${!showNavMenu && "opacity-0 hidden"} overflow-y-hidden hover:overflow-auto`}>
                <div className="flex flex-row justify-center sm:flex sm:flex-col items-center w-full sm:mt-3 sm:border-t border-gray-700">
                    {/* Drop Menu */}

                    {/* Tickets items */}
                    {[ROLES.ADMIN, ROLES.GENERAL, ROLES.RESPONSABLE_MAN, ROLES.MANTENIMIENTO].find(role => role === userContext.auth?.user?.tipoUsuario) && (
                    <>
                    <DropMenuContainer items={[
                        {
                            component: 
                            <> 
                                <svg className="w-[20px] stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                </svg>
                                <span className="sm:block text-xs sm:ml-2 sm:text-[12px] font-medium align-text-bottom">Dasboard</span>
                            </>,
                            url: "/"
                        },
                        {
                            component: 
                            <> 
                                <svg className="w-[20px] stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                                <span className="hidden sm:block text-xs sm:ml-2 sm:text-[12px] font-medium">Consulta de tickets</span>
                            </>,
                            url: "/ticket"
                        },
                        {
                            component: 
                            <> 
                                <svg className="w-[20px] stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg>
                                <span className="hidden sm:block ml-2 text-[12px] font-medium">Metricas</span>
                            </>,
                            url: "/metrics-mantenimiento"
                        },
                        {
                            component: 
                            <> 
                                <HistoryIcon sx={{ width: 20}}/>
                                <span className="hidden sm:block ml-2 text-[12px] font-medium">Bitácora</span>
                            </>,
                            url: "/bitacora"
                        },
                    ]}>
                            <ConstructionIcon sx={{
                                widows: "20px",
                                height: "20px"
                            }} />
                            <span className="sm:block text-xs sm:text-sm font-medium">Mantenimiento</span>
                    </DropMenuContainer>
                    </>
                    )}

                    {/* Compras items */}
                    {[ROLES.ADMIN, ROLES.GENERAL, ROLES.COMPRAS, ROLES.RESPONSABLE_COMP].find(role => role === userContext.auth?.user?.tipoUsuario) && (
                    <>
                    <DropMenuContainer items={[
                        {
                            component: 
                            <> 
                                <AttachMoneyIcon sx={{ height: '100%', width: 21}}/>
                                <span className="hidden sm:block ml-2 text-[12px] font-medium">Dashboard</span>
                            </>,
                            url: "/compras"
                        },
                        {
                            component: 
                            <> 
                                <AttachMoneyIcon sx={{ height: '100%', width: 21}}/>
                                <span className="hidden sm:block ml-2 text-[12px] font-medium sm:text-left">Consulta de Solicitudes</span>
                            </>,
                            url: "/compras/consulta"
                        },
                        {
                            component: 
                            <> 
                                <svg className="w-6 h-full stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg>
                                <span className="hidden sm:block ml-2 text-[12px] font-medium">Metricas</span>
                            </>,
                            url: "/metrics-compras"
                        },
                        {
                            component: 
                            <> 
                                <HistoryIcon sx={{ height: 28, width: 21}}/>
                                <span className="ml-2 text-xs sm:text-[12px] font-medium">Bitácora</span>
                            </>,
                            url: "/bitacora-compras"
                        },
                    ]}>
                        <ShoppingBagIcon sx={{
                            width: "20px",
                            height: "20px"
                        }}/>
                        <span className="sm:block text-xs sm:text-sm font-medium">Compras</span>
                    </DropMenuContainer>
                    </>
                    )}

                    {/* Mantenimiento items */}
                    {[ROLES.ADMIN, ROLES.RESPONSABLE_MAN, ROLES.MANTENIMIENTO].find(role => role === userContext.auth?.user?.tipoUsuario) && (
                    <>
                    <DropMenuContainer items={[
                        {
                            component: 
                            <> 
                                <AttachMoneyIcon sx={{ height: '100%', width: 21}}/>
                                <span className="hidden sm:block ml-2 text-[12px] font-medium">Dashboard</span>
                            </>,
                            url: "/preventivo"
                        },
                        {
                            component: 
                            <> 
                                <svg className="w-[20px] stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                                <span className="hidden sm:block ml-2 text-[12px] font-medium sm:text-left">Consulta de Mantenimientos</span>
                            </>,
                            url: "/preventivo/consulta"
                        },
                    ]}>
                        <TodayIcon sx={{
                            width: "20px",
                            height: "20px"
                        }} />
                        <span className="text-center sm:block text-xs sm:text-sm font-medium">Mantenimientos Preventivos</span>
                    </DropMenuContainer>

                    <div className={`sm:hidden flex-row cursor-pointer sm:w-full sm:h-8 px-3 sm:pl-0 sm:pr-0 sm:mt-2 rounded hover:bg-gray-700 hover:text-gray-300`}>
                        <MenuAccount className={`${!showNavMenu && "opacity-0"}`}/>
                    </div>
                    </>
                    )}

                    {/* Checklist items */}
                    {[ROLES.ADMIN, ROLES.RESPONSABLE_MAN, ROLES.MANTENIMIENTO, ROLES.GENERAL].find(role => role === userContext.auth?.user?.tipoUsuario) && (
                    <>
                    <DropMenuContainer items={[
                        {
                            component:
                            <>
                                <CheckIcon sx={{ height: '100%', width: 21}}/>
                                <span className="ml-2 text-sm font-medium max-[400px]:hidden">Listas</span>
                            </>,
                            url: "/checklist"

                        },
                        {
                            component:
                            <>
                                <svg className="w-[20px] stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                                <span className="ml-2 text-sm font-medium max-[400px]:hidden">Historial</span>
                            </>,
                            url: "/checklist/historial"
                        }
                    ]} >
                        <ChecklistIcon />
                        <span className="ml-2 text-sm font-medium">Checklist</span>
                    </DropMenuContainer>
                    </>
                    )}
                </div>

                {[ROLES.ADMIN, ROLES.RESPONSABLE_MAN]
                    .find(role => role === userContext.auth?.user?.tipoUsuario) && (
                <div className="hidden sm:flex sm:flex-col items-center w-full mt-2 sm:border-t border-gray-700">
                <div to="/" className="flex items-center w-full h-12 px-3 mt-2 rounded ">
                    <svg className="w-6 h-6 stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2" />
                    </svg>
                    <span className="ml-2 text-sm font-medium">Catalogos</span>
                </div>

                {[ROLES.ADMIN]
                    .find(role => role === userContext.auth?.user?.tipoUsuario) && (
                <Link to="/revision" className="flex items-center w-full h-6 px-3 mt-2 hover:bg-gray-700 hover:text-gray-300 rounded" >
                    <span className="ml-2 text-sm font-medium max-[400px]:hidden">Revisiones</span>
                </Link>
                )}
                {[ROLES.ADMIN]
                    .find(role => role === userContext.auth?.user?.tipoUsuario) && (
                <Link to="/categories" className="flex items-center w-full h-6 px-3 mt-2 hover:bg-gray-700 hover:text-gray-300 rounded" >
                    <span className="ml-2 text-sm font-medium max-[400px]:hidden">Categorias</span>
                </Link>
                )}
                {[ROLES.ADMIN]
                    .find(role => role === userContext.auth?.user?.tipoUsuario) && (
                <Link to="/subcategories" className="flex items-center w-full h-6 px-3 mt-2 hover:bg-gray-700 hover:text-gray-300 rounded">
                    <span className="ml-2 text-sm font-medium">Subcategorias</span>
                </Link>
                )}
                {[ROLES.ADMIN]
                    .find(role => role === userContext.auth?.user?.tipoUsuario) && (
                <Link to="/sucursales" className="flex items-center w-full h-6 px-3 mt-2 hover:bg-gray-700 hover:text-gray-300 rounded">
                    <span className="ml-2 text-sm font-medium">Sucursales</span>
                </Link>
                )}
                {[ROLES.ADMIN, ROLES.RESPONSABLE_MAN]
                    .find(role => role === userContext.auth?.user?.tipoUsuario) && (
                <Link to="/equipos" className="flex items-center w-full h-6 px-3 mt-2 hover:bg-gray-700 hover:text-gray-300 rounded">
                    <span className="ml-2 text-sm font-medium">Equipos</span>
                </Link>
                )}
                {[ROLES.ADMIN]
                    .find(role => role === userContext.auth?.user?.tipoUsuario) && (
                <Link to="/usuarios" className="flex items-center w-full h-6 px-3 mt-2 hover:bg-gray-700 hover:text-gray-300 rounded">
                    <span className="ml-2 text-sm font-medium">Usuarios</span>
                </Link>
                )}
                </div>
                )}

            </div>
            <div className={`hidden sm:flex items-center justify-center w-full h-16 mt-auto bg-gray-800 `} >
                <MenuAccount className={`${!showNavMenu && "opacity-0"}`}/>
            </div>
        </div>

      </header>
    );
  }
