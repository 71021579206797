import { useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button } from "@mui/joy";
import { CategoryModel } from "../../types";
import { CategoriaServices } from "../../services/categorias";
import { useUser } from "../../hooks/useUser";
import { LoadingScreen } from "../UtilsComponents/LoadingScreen";
import { LoadingRequest, ErrorMessage } from "../UtilsComponents";
import { errorParser } from "../../utilities";


export function CategoryDetail() {
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";
    const categoriaService = new CategoriaServices(token);

    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [requestLoading, setRequestLoading] = useState<boolean>(false);

    const { id } = useParams();
    const navigate = useNavigate();
    const { 
        register,
        handleSubmit,
        formState: { errors, isLoading, isDirty } 
    } = useForm<CategoryModel>({
        defaultValues: async () => categoriaService.getCategoryById(id)
    });

    const submitUpdate = handleSubmit(async (data: CategoryModel) =>{
        try {
            setHasError(false);
            setRequestLoading(true);

            const response = await categoriaService.updateCategory(data);
            if(response.status === 200) {
                setRequestLoading(false);
                navigate("/categories");
            }

        } catch(error) {
            const message = errorParser(error);
            setRequestLoading(false);
            setErrorMessage(message);
            setHasError(true);
        }
    })

    const deleteCategory = async (id: string | undefined) => {
        categoriaService.deleteCategoryById(id)
            .then(_response => navigate("/categories"))
            .catch(error => console.error(error));
    }

    return (
        <>
        {isLoading 
        ? <LoadingScreen />
        :
        <div>
            <h2 className="text-3xl my-4 font-semibold">Detalles</h2>
            <form onSubmit={submitUpdate} className="grid grid-cols-2">

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Categoria</label>
                    <input {...register("nombreCategoria", {
                                required: "El nombre de la categoría es requerido",
                                maxLength: 70
                            })}
                            aria-invalid={errors.nombreCategoria ? "true" : "false"}
                            className="inputform"
                            type="text" 
                            placeholder="nombre de la categoria"/>
                    {errors.nombreCategoria?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.nombreCategoria.message}</p>
                    )}
                    {errors.nombreCategoria?.type === "maxLength" && (
                        <p className="col-start-2 valErrorText" role="alert">Maximo numero de caracteres es 70</p>
                    )}
                </div>

                <div className="flex justify-end col-span-2 m-2 gap-2 ">
                    {requestLoading
                    ? <LoadingRequest />
                    : <Button size="sm" 
                            disabled={!isDirty}
                            color="success" 
                            className="w-20" 
                            type="submit">
                        Actualizar
                    </Button>
                    }
                    <Button size="sm" color="danger" className="w-20 mx-2" onClick={() => deleteCategory(id)}>Eliminar</Button>
                    <Link to="/categories">
                        <Button size="sm" color="neutral" className="w-20 mx-2">Regresar</Button>
                    </Link>
                </div>

                {hasError && (
                    <ErrorMessage
                        errorMessage={errorMessage} />
                )}

            </form>
        </div>
        }
        </>
    );
}