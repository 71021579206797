import { AddButon } from "../../utilities/UtilsComponents/AddButon";
import { UsersTable, UserTypesTable } from "../UserComponents";

export function UsuarioHome() {


    return (
        <div className="w-full">
            <h2 className="text-3xl my-4 font-semibold">Usuarios</h2>

            <AddButon url="/usuarios/create" />

            <UsersTable />

            <br />
            <br />
            <br />

            <h2 className="text-3xl my-4 font-semibold">Tipos de usuario</h2>

            <AddButon url="/usuarios/newtype"/>

            <UserTypesTable />

        </div>
    );
}