import { Link, Outlet } from "react-router-dom";
import "./MobileNavBar.css"
import { DropMenuContainer } from "./DropMenuContainer";
import { ROLES } from "../../../utilities/StateEnums";
import { useUser } from "../../../hooks/useUser";
import { useState } from "react";
import { ShowSideBar } from "../../../utilities/UtilsComponents/ShowSideBar";
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import HistoryIcon from '@mui/icons-material/History';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ConstructionIcon from '@mui/icons-material/Construction';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import TodayIcon from '@mui/icons-material/Today';
import ChecklistIcon from '@mui/icons-material/Checklist';
import CheckIcon from '@mui/icons-material/Check';
import { MenuAccount } from "../../../utilities/UtilsComponents/MenuAccount";

export function MobileNavBar() {

    const [showNavMenu, setShowNavMenu] = useState(true);
    const { userContext } = useUser();

    return (
    <div className="content sm:hidden">
        <ShowSideBar>
        <nav role="navigation">
            <div id="menuToggle">
                <input type="checkbox" />
                    <span></span>
                    <span></span>
                    <span></span>
                <ul id="menu">
                <li>
                    {[ROLES.ADMIN, ROLES.GENERAL, ROLES.RESPONSABLE_MAN, ROLES.MANTENIMIENTO].find(role => role === userContext.auth?.user?.tipoUsuario) && (
                    <>
                    <DropMenuContainer items={[
                        {
                            component: 
                            <> 
                                <svg className="w-[20px] stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                </svg>
                                <p className="sm:block text-xs sm:ml-2 sm:text-[12px] font-medium align-text-bottom">Dasboardh</p>
                            </>,
                            url: "/"
                        },
                        {
                            component: 
                            <> 
                                <svg className="w-[20px] stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                                <p className="sm:block text-xs sm:ml-2 sm:text-[12px] font-medium">Consulta de tickets</p>
                            </>,
                            url: "/ticket"
                        },
                        {
                            component: 
                            <> 
                                <svg className="w-[20px] stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg>
                                <p className="sm:block ml-2 text-[12px] font-medium">Metricas</p>
                            </>,
                            url: "/metrics-mantenimiento"
                        },
                        {
                            component: 
                            <> 
                                <HistoryIcon sx={{ width: 20}}/>
                                <p className="sm:block ml-2 text-[12px] font-medium">Bitácora</p>
                            </>,
                            url: "/bitacora"
                        },
                    ]}>
                            <ConstructionIcon sx={{
                                widows: "20px",
                                height: "20px"
                            }} />
                            <div>
                                <p className="sm:block text-xs sm:text-sm font-medium">Mantenimiento</p>
                            </div>
                    </DropMenuContainer>
                    </>
                    )}
                </li>
                <li>
                    {/* Compras items */}
                    {[ROLES.ADMIN, ROLES.GENERAL, ROLES.COMPRAS, ROLES.RESPONSABLE_COMP].find(role => role === userContext.auth?.user?.tipoUsuario) && (
                    <>
                    <DropMenuContainer items={[
                        {
                            component: 
                            <> 
                                <AttachMoneyIcon sx={{ height: '100%', width: 21}}/>
                                <p className="sm:block ml-2 text-[12px] font-medium">Dashboard</p>
                            </>,
                            url: "/compras"
                        },
                        {
                            component: 
                            <> 
                                <AttachMoneyIcon sx={{ height: '100%', width: 21}}/>
                                <p className="sm:block ml-2 text-[12px] font-medium">Consulta de Solicitudes</p>
                            </>,
                            url: "/compras/consulta"
                        },
                        {
                            component: 
                            <> 
                                <svg className="w-6 h-full stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg>
                                <p className="sm:block ml-2 text-[12px] font-medium">Metricas</p>
                            </>,
                            url: "/metrics-compras"
                        },
                        {
                            component: 
                            <> 
                                <HistoryIcon sx={{ height: 28, width: 21}}/>
                                <p className="sm:block ml-2 text-[12px] font-medium">Bitácora</p>
                            </>,
                            url: "/bitacora-compras"
                        },
                    ]}>
                        <ShoppingBagIcon sx={{
                            width: "20px",
                            height: "20px"
                        }}/>
                        <p className="sm:block text-xs sm:text-sm font-medium">Compras</p>
                    </DropMenuContainer>
                    </>
                    )}
                </li>
                

                <li>
                    {/* Mantenimiento items */}
                    {[ROLES.ADMIN, ROLES.RESPONSABLE_MAN, ROLES.MANTENIMIENTO].find(role => role === userContext.auth?.user?.tipoUsuario) && (
                    <>
                    <DropMenuContainer items={[
                        {
                            component: 
                            <> 
                                <AttachMoneyIcon sx={{ height: '100%', width: 21}}/>
                                <p className="sm:block ml-2 text-[12px] font-medium">Dashboard</p>
                            </>,
                            url: "/preventivo"
                        },
                        {
                            component: 
                            <> 
                                <svg className="w-[20px] stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                                <p className="sm:block ml-2 text-[12px] font-medium">Consulta de Mantenimientos</p>
                            </>,
                            url: "/preventivo/consulta"
                        },
                    ]}>
                        <TodayIcon sx={{
                            width: "20px",
                            height: "20px"
                        }} />
                        <p className="text-right sm:block text-xs sm:text-sm font-medium w-full">Mantenimientos Preventivos</p>
                    </DropMenuContainer>

                    </>
                    )}
                </li>

                <li>
                    {/* Checklist items */}
                    {[ROLES.ADMIN, ROLES.RESPONSABLE_MAN, ROLES.MANTENIMIENTO].find(role => role === userContext.auth?.user?.tipoUsuario) && (
                    <>
                    <DropMenuContainer items={[
                        {
                            component:
                            <>
                                <CheckIcon sx={{ height: '100%', width: 21}}/>
                                <p className="ml-2 text-sm font-medium max-[400px]:hidden">Listas</p>
                            </>,
                            url: "/checklist"

                        },
                        {
                            component:
                            <>
                                <svg className="w-[20px] stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                                <p className="ml-2 text-sm font-medium max-[400px]:hidden">Historial</p>
                            </>,
                            url: "/checklist/historial"
                        }
                    ]} >
                        <ChecklistIcon />
                        <p className="ml-2 text-sm font-medium">Checklist</p>
                    </DropMenuContainer>
                    </>
                    )}
                </li>

                <li className="text-gray-400 w-full">
                    {[ROLES.ADMIN, ROLES.RESPONSABLE_MAN]
                        .find(role => role === userContext.auth?.user?.tipoUsuario) && (
                    <div className="flex flex-col items-center w-full mt-2 border-t border-gray-700">

                        <div className="flex mt-2">
                            <svg className="w-6 h-6 stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2" />
                            </svg>
                            <p className="ml-2 text-lg font-medium">Catalogos</p>
                        </div>

                        {[ROLES.ADMIN]
                            .find(role => role === userContext.auth?.user?.tipoUsuario) && (
                        <Link to="/revision" className="flex items-center h-10 mt-2 w-full" >
                            <div className="w-full h-10">
                                <p className="w-full text-center inline-block align-middle">Revisiones</p>
                            </div>
                        </Link>
                        )}
                        {[ROLES.ADMIN]
                            .find(role => role === userContext.auth?.user?.tipoUsuario) && (
                        <Link to="/categories" className="flex items-center h-10 mt-2 w-full" >
                            <div className="w-full h-10">
                                <p className="w-full text-center inline-block align-middle">Categorias</p>
                            </div>
                        </Link>
                        )}
                        {[ROLES.ADMIN]
                            .find(role => role === userContext.auth?.user?.tipoUsuario) && (
                        <Link to="/subcategories" className="flex items-center h-10 mt-2 w-full">
                            <div className="w-full h-10">
                                <p className="text-sm font-medium text-center">Subcategorias</p>
                            </div>
                        </Link>
                        )}
                        {[ROLES.ADMIN]
                            .find(role => role === userContext.auth?.user?.tipoUsuario) && (
                        <Link to="/sucursales" className="flex h-10 mt-2 w-full">
                            <div className="w-full h-10">
                                <p className="h-10 text-sm font-medium text-center">Sucursales</p>
                            </div>
                        </Link>
                        )}
                        {[ROLES.ADMIN, ROLES.RESPONSABLE_MAN]
                            .find(role => role === userContext.auth?.user?.tipoUsuario) && (
                        <Link to="/equipos" className="flex h-10 mt-2 w-full">
                            <div className="w-full h-10">
                                <p className="h-10 text-sm font-medium text-center">Equipos</p>
                            </div>
                        </Link>
                        )}
                        {[ROLES.ADMIN]
                            .find(role => role === userContext.auth?.user?.tipoUsuario) && (
                        <Link to="/usuarios" className="flex h-10 mt-2 w-full">
                            <div className="w-full h-10">
                                <p className="h-10 text-sm font-medium text-center">Usuarios</p>
                            </div>
                        </Link>
                        )}
                    </div>
                    )}
                </li>
                <li>
                    <div className={`flex flex-col sm:flex-row cursor-pointer w-full sm:h-10 px-3 sm:pl-0 sm:pr-0 sm:mt-2 rounded py-2`}>
                        <div className="flex flex-col sm:flex-row items-center w-full justify-between">
                            <MenuAccount />
                        </div>
                    </div>
                </li>
                </ul>
            </div>
        </nav>
        </ShowSideBar>
        <div className='p-4 w-full overflow-y-auto max-h-screen'>
            <Outlet />
        </div>
    </div>
    );
}