import { CategoryModel } from "../../types";
import { useState, useEffect } from "react";
import { AddButon } from "../../utilities/UtilsComponents/AddButon";
import { EditButton } from "../../utilities/UtilsComponents/EditButton";
import Table from "@mui/joy/Table";
import { CategoriaServices } from "../../services";
import { useUser } from "../../hooks/useUser";
import { LoadingComponent } from "../UtilsComponents/LoadingComponent";


export function Category() {
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const categoriaService = new CategoriaServices(token);
    const [categories, setCategories] = useState<Array<CategoryModel>>([]);
    const [dataLoading, setDataLoading] = useState<boolean>(true);

    useEffect(() => {
        categoriaService.getCategories()
            .then(data => {
                setCategories(data);
                setDataLoading(false);
            })
    },[]);

    return (
        <div>
            <h2 className="text-3xl my-4 font-semibold">Categorias</h2>

            <AddButon url="/categories/create"/>

            {dataLoading
            ? <LoadingComponent />
            :
            <div className="overflow-auto max-h-[600px] rounded-lg border border-slate-200 my-4">
            <Table
                stickyHeader
                sx={(theme) => ({
                    "th": {bgcolor: "#111827", color: "#ffffff"}
                })}>

            <table className="w-full">
                <thead>
                    <tr>
                        <th>Categoria</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {categories.map((categoria, index)=> {
                        return (
                        <tr key={index} className={`hover:bg-slate-100`}>
                            <td>{categoria.nombreCategoria}</td>
                            <td>
                                <EditButton url={`/categories/${categoria.id}`}/>
                            </td>
                        </tr>
                    )})}
                </tbody>
            </table>

            </Table>
            </div>
            }
        </div>
    );
}