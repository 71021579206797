import { useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CategoryModel, SubcategoryModel } from "../../../types";
import { CategoriaServices } from "../../../services/categorias";
import { SubcategoryServices } from "../../../services/subcategorias";
import Button from "@mui/joy/Button/Button";
import { useUser } from "../../../hooks/useUser";
import { LoadingScreen } from "../../UtilsComponents/LoadingScreen";
import { errorParser } from "../../../utilities";
import { ErrorMessage, LoadingRequest } from "../../UtilsComponents";


export function SubcategoryDetail() {

    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [requestLoading, setRequestLoading] = useState<boolean>(false);

    const categoriaService = new CategoriaServices(token);
    const subcategoryService = new SubcategoryServices(token);

    const { id } = useParams();
    const navigate = useNavigate();
    const { register,
            handleSubmit, 
            watch,
            formState: { 
                errors,
                isDirty,
            }
        } = useForm<SubcategoryModel>({
        defaultValues: async () => {
            const subcategoiras = await subcategoryService.getSubcategoryById(id)
            const categorias = categoriaService.getCategories()
                .then(data => setCategories(data));

            Promise.all([subcategoiras, categorias])
                .then(_ => setDataLoading(false))

            return subcategoiras;
        }
    });
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [categories, setCategories] = useState<Array<CategoryModel>>([]);

    const deleteSubcategory = (id: string | undefined) => {
        if(id === undefined)
            return;

        subcategoryService.deleteSubcategoryById(parseInt(id))
            .then(response => {
                if(response.status === 204 ){
                    navigate("/subcategories");
                }
            });
    }

    const submitUpdate = handleSubmit(async (data: SubcategoryModel) => {
        try {
            setHasError(false);
            setRequestLoading(true);

            const response = await subcategoryService.updateSubcategory(data);

            if(response.status === 200) {
                setRequestLoading(false);
                navigate("/subcategories");
            }

        } catch(error) {
            const message = errorParser(error);
            setRequestLoading(false);
            setErrorMessage(message);
            setHasError(true);
        }
    });

    return (
        <>
        {dataLoading 
        ? <LoadingScreen />
        :
        <div>
            <h2 className="text-3xl my-4 font-semibold">Detalles</h2>
            <form onSubmit={submitUpdate}  className="grid grid-cols-2">

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Nombre</label>
                    <input  {...register("nombreSubcategoria", {
                                required: "El nombre de la subcategoría es requerido",
                                maxLength: 30
                            })}
                            aria-invalid={errors.nombreSubcategoria ? "true": "false"}
                            className="inputform"
                            type="text" 
                            placeholder="Subcategoria"/>
                    {errors.nombreSubcategoria?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.nombreSubcategoria.message}</p>
                    )}
                    {errors.nombreSubcategoria?.type === "maxLength" && (
                        <p className="col-start-2 valErrorText" role="alert">Maximo numero de caracteres es 30</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Categoria</label>
                    <select {...register("categoriaId")}
                            className="h-8 my-2 border font-normal text-sky-900 border-cyan-300 hover:border-cyan-900 rounded-lg bg-transparent"
                            value={watch("categoriaId")}
                            name="categoriaId">
                            {categories.map((category, index) => (
                                <option key={index} value={category.id}>{category.nombreCategoria}</option>
                            ))}
                    </select>
                </div>
                <div className="flex justify-end col-span-2 m-2 gap-2">
                    {requestLoading
                    ? <LoadingRequest />
                    : <Button size="sm" 
                            disabled={!isDirty}
                            color="success" 
                            className="w-20" 
                            type="submit">
                        Actualizar
                    </Button>
                    }
                    <Button size="sm" color="danger" className="w-20 mx-2" onClick={() => deleteSubcategory(id)}>Eliminar</Button>
                    <Link to="/subcategories">
                        <Button size="sm" color="neutral" className="w-20 mx-2">Cancelar</Button>
                    </Link>
                </div>

                {hasError && (
                    <ErrorMessage 
                        errorMessage={errorMessage} />
                )}

            </form>
        </div>
        }
        </>
    );
}