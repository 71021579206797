import { Link } from "react-router-dom";
import Button from "@mui/joy/Button";
import Add from "@mui/icons-material/Add";

interface Props {
    url: string
    previousUrl?: string
}

export function EditButton({ url, previousUrl }: Props) {
    return (
        <Link to={url} state={{ previousUrl }}>
            <Button size="sm" 
                    variant="plain" 
                    color="success">
                    Editar
            </Button>
        </Link>
    );
}