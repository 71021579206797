import { Table } from "@mui/joy";
import { EditButton } from "../../utilities/UtilsComponents/EditButton";
import { colorUrgenciaPreview, colorEstadoPreview } from "../../utilities/selectColorPreview";
import { TicketResponse } from "../../types";

interface Props {
    ticketsList: Array<TicketResponse>;
}

export function MetricsTicketsTable({ ticketsList }: Props) {
    return (
        <div className="overflow-auto max-h-[600px] rounded-lg border border-slate-200">
            <Table
                stickyHeader
                sx={(theme) => ({
                    "th": {bgcolor: "#111827", color: "#ffffff"},
                    "table": { width: "100%" },
                })}>
                <table className="w-full">
                <thead>
                    <tr>
                        <th>Folio</th>
                        <th>Urgencia</th>
                        <th>Estado</th>
                        <th>Sucursal</th>
                        <th>Titulo</th>
                        <th>Responsable</th>
                        <th>Equipo</th>
                        <th>Fecha de Creacion</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {ticketsList.map((ticket, index)=> {
                        return (
                        <tr key={index} className={`hover:bg-slate-100`}>
                            <td>{ticket.id}</td>
                            <td>
                                <div className="flex items-center">
                                    <div className={`${colorUrgenciaPreview(ticket.urgencia)} w-2  h-2 rounded-full mr-1`}></div>
                                    <p className={`text-center w-2/4 rounded-lg font-semibold text-gray-900 opacity-85`}>{ticket.urgencia}</p>
                                </div>
                            </td>
                            <td>
                                    <span className={`px-2 ${colorEstadoPreview(ticket.estado)} rounded-xl text-center w-30`}>
                                        {ticket.estado}
                                    </span>
                            </td>
                            <td>{ticket.sucursalNombre}</td>
                            <td>{ticket.titulo}</td>
                            <td>{ticket.responsableNombre}</td>
                            <td>{ticket.equipoNombre}</td>
                            <td>{ticket.fechaInforma}</td>
                            <td>
                                <EditButton url={`/ticket/${ticket.id}`} previousUrl={"/"}/>
                            </td>
                        </tr>
                    )})}
                </tbody>
                </table>
            </Table>
        </div>
    );
}