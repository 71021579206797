import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { MantenimientoServices } from "../../services";
import { useUser } from "../../hooks/useUser";
import { MantenimientoResponse, MantenimientoRequest, UsuarioModel } from "../../types";
import { useForm } from "react-hook-form";
import { Button, CircularProgress } from "@mui/joy";
import { UserServices } from "../../services";
import { LoadingRequest, ErrorMessage } from "../UtilsComponents";
import { errorParser } from "../../utilities";

export function MantenimientoDetail() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const userService = new UserServices(token);

    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [requestLoading, setRequestLoading] = useState<boolean>(false);

    const [responsables, setResponsables] = useState<Array<UsuarioModel>>([]);
    const [userLoading, setUserLoading] = useState(false);
    const [imagen, setImagen] = useState<File>();

    const manService = new MantenimientoServices(token);

    const { 
        register,
        watch,
        handleSubmit,
        setValue, 
        formState: { 
            isLoading,
            errors
        } 
    } = useForm<MantenimientoResponse>({
        defaultValues: async () => manService.getMantenimientoByEquipoId(id)
    });

    const submitRegister = handleSubmit(async (data: MantenimientoResponse) => {

        try {
            setHasError(false);
            setRequestLoading(true);

            if(userContext.auth?.user?.id !== undefined) {
                const request: MantenimientoRequest = {
                    equipoId: data.id,
                    responsableId: data.responsableId,
                    comentario: data.comentario,
                    fecha: new Date().toISOString(),
                    imagen: imagen
                };

                const response = await manService.saveNewMantenimientoRegistro(request);

                if(response.status === 201) {
                    setRequestLoading(false);
                    navigate(-1);
                }
            }
        } catch(error) {
            const message = errorParser(error);
            setRequestLoading(false);
            setErrorMessage(message);
            setHasError(true);
        }
    });


    useEffect(()=> {
        userService.getUsersByRole("Mantenimiento")
            .then(data => {
                setResponsables(data);
                setUserLoading(false);

                if(userContext.auth?.user?.id !== undefined)
                    setValue("responsableId", userContext.auth?.user?.id);
            });
    }, []);

    return (
        <div>
            <h2 className="text-3xl my-4 font-semibold">Mantenimiento del equipo: {`${watch("nombreEquipo")}`}</h2>
            {userLoading === false && isLoading === false ?
            <form className="grid grid-cols-2" onSubmit={submitRegister}>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="equipo">Equipo</label>
                    <input {...register("nombreEquipo")}
                        id="equipo"
                        disabled={true}
                        className="inputform"/>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Responsable</label>
                    <select {...register("responsableId", {
                            required: "Debe seleccionar un responsable del mantenimiento.",
                            min: 1
                        })}
                        aria-invalid={errors.responsableId ? "true" : "false"}
                        disabled={userContext.auth?.user?.tipoUsuario !== "Admin"}
                        defaultValue={userContext.auth?.user?.id}
                        className="selectform">
                        <option value={0} hidden></option>
                        {responsables.map((user, index) => (
                            <option key={index} value={user.id}>{user.nombre}</option>
                        ))}
                    </select>
                    {errors.responsableId?.type === "required" && (
                        <p className="valErrorText col-start-2" role="alert">{errors.responsableId.message}</p>
                    )}
                    {errors.responsableId?.type === "min" && (
                        <p className="valErrorText col-start-2" role="alert">{errors.responsableId.message}</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="fechaUlt">Último Mantenimiento</label>
                    <input {...register("fechaUltimoMantenimiento")}
                        id="fechaUlt"
                        disabled={true}
                        type="date"
                        className="inputform"/>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="fechaProx">Próximo Mantenimiento</label>
                    <input {...register("fechaProximoMantenimiento")}
                        id="fechaProx"
                        disabled={true}
                        type="date"
                        className="inputform"/>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="comentarios">Comentarios</label>
                    <textarea {...register("comentario")}
                        id="comentarios"
                        className="textareaform"
                        rows={5}/>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" htmlFor="archivo">Archivo</label>
                    <input 
                        onChange={(event) => setImagen(event.target.files![0])}
                        id="archivo"
                        type="file"
                        className="inputform"/>
                </div>

                <div className="flex justify-end col-span-2 m-2 gap-2 ">
                    {requestLoading
                    ? <LoadingRequest />
                    : <Button size="sm" 
                            color="success" 
                            disabled={isLoading}
                            className="w-32" 
                            type="submit">
                        Realizar Mantenimiento
                    </Button>
                    }
                    <Button 
                        onClick={() => navigate(-1)} 
                        size="sm" 
                        color="neutral" 
                        className="w-20 mx-2">
                        Regresar
                    </Button>
                </div>

                {hasError && (
                    <ErrorMessage
                        errorMessage={errorMessage} />
                )}

            </form>
            : <div className="flex justify-center w-full max-h-full"><CircularProgress /></div>}

        </div>
    );
}