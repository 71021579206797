import { Table } from "@mui/joy";
import { ChecklistModel, TareaRequest } from "../../types";
import { Dispatch, SetStateAction } from "react";
import { ItemChecklistV2 } from "./ItemChecklistV2";
import { ItemChecklist } from "./ItemChecklist";

interface Props {
    checklist?: ChecklistModel;
    listTareas: TareaRequest[];
    setListTareas: Dispatch<SetStateAction<Array<TareaRequest>>>;
}
export function LayoutChecklistV2({ checklist, listTareas, setListTareas }: Props) {
    let highesttotalEqupos = 0;

    checklist?.tareas.map((tarea, index) => {
        if(!tarea.version2)
            return;

        if(tarea.equipos.length > highesttotalEqupos)
            highesttotalEqupos = tarea.equipos.length;
    });

    return (
        <div className="overflow-auto max-w-screen rounded-lg border border-slate-200">
            <Table>
                <thead>
                    <tr>
                        <th className="w-[250px]">No. De equipo</th>
                        <th className="w-[150px] text-center">Tipo de Respuesta</th>
                         {Array.from({ length: highesttotalEqupos }).map((_, index) => (
                            <th 
                                className="w-[100px]" 
                                key={index}>
                                {index + 1}
                            </th>
                        ))}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {checklist?.tareas.map((tarea, index) => (
                        <>
                        {tarea.version2
                        ?
                        <ItemChecklistV2 
                            key={index}
                            listTareas={listTareas}
                            setListTareas={setListTareas}
                            totalEquipos={highesttotalEqupos}
                            tarea={tarea}/>
                        :
                        <ItemChecklist 
                            key={index}
                            idTarea={tarea.id}
                            text={tarea.nombre}
                            listTareas={listTareas}
                            setListTareas={setListTareas}/>
                        }
                        </>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}