import { createContext, Dispatch, ReactNode, SetStateAction, useState } from "react";

interface Checklist {
    textFromItem: string;
    setText: Dispatch<SetStateAction<string>> | null
}

export const ChecklistContext = createContext<Checklist>({
    textFromItem: "",
    setText: null
});

interface Props {
    children: ReactNode;
}

export function ChecklistProvider({ children }: Props) {
    const [text, setText] = useState<string>("");

    return (
        <ChecklistContext.Provider value={{
            textFromItem: text,
            setText
        }}>
            {children}
        </ChecklistContext.Provider>
    );
}