import { useState } from "react";
import { ModalImagePreview } from ".";
import { ModalContainer } from "../ModalContainer";

interface Props {
    urlImage: string | undefined;
}

export function ImagePreview({ urlImage }: Props) {

    const [showPreview, setShowPreview] = useState<boolean>(false);

    const openPreview = () => {
        setShowPreview(!showPreview);
    }

    return (
        <>
            <div className="flex justify-center sm:block my-4">
                <img 
                    className="w-[240px] h-[180px] cursor-pointer"
                    src={urlImage} 
                    onClick={openPreview}/>
            </div>

            {showPreview && (
                <ModalContainer>
                    <ModalImagePreview
                        urlImage={urlImage}
                        openPreview={openPreview}/>
                </ModalContainer>
            )}
        </>
    );
}