import { Table } from "@mui/joy";
import { EditButton } from "../../utilities/UtilsComponents/EditButton";
import { colorUrgenciaPreview, colorEstadoSolicitudPreview } from "../../utilities/selectColorPreview";
import { useUser } from "../../hooks/useUser";
import { SolicitudResponse } from "../../types";
import { EstadosSolicitud } from "../../utilities/StateEnums";

interface Props {
    solicitudList: Array<SolicitudResponse>;
}

export function MetricsSolicitudesTable({ solicitudList }: Props) {

    const { userContext } = useUser();

    // TODO: Pasar esta funcion a un otro archivo.
    const selectStatus = (statusValidation: boolean, estado: string): Array<string> => {
        let status = statusValidation ? "Aprobado" : "Pendiente";
        let color = statusValidation ? "bg-green-700" : "bg-amber-400"

        if(estado === EstadosSolicitud.Rechazado) {
            status = "Rechazado";
            color = "bg-red-700";
        }

        return [status, color];
    }

    return (
        <div className="overflow-auto max-h-[600px] rounded-lg border border-slate-200">
            <Table 
                stickyHeader
                sx={(theme) => ({
                    "th": {bgcolor: "#111827", color: "#ffffff"}
                })}>
                        <thead>
                            <tr>
                                <th className="w-12">Folio</th>
                                <th className="w-20">Prioridad</th>
                                <th className="w-28">Estado</th>
                                <th className="w-96">Titulo</th>
                                <th className="w-28">Responsable</th>
                                <th className="w-40">Fecha de Creación</th>
                                <th className="w-40">Fecha de Vencimiento</th>
                                {["Admin", "General"].find(role => role === userContext.auth?.user?.tipoUsuario) && (
                                    <th className="w-24">Validación</th>
                                )}
                                <th className="w-24"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {solicitudList.map((solicitud, index) => {

                                const [status, color] = selectStatus(solicitud.aprobada, solicitud.estado);

                                return (
                                <tr key={index} className={`hover:bg-slate-100`}>
                                    <td>{solicitud.id}</td>
                                    <td>
                                        <div className="flex items-center">
                                            <div className={`${colorUrgenciaPreview(solicitud.urgencia)} w-2  h-2 rounded-full mr-1`}></div>
                                            <p className={`text-center w-2/4 rounded-lg font-semibold text-gray-900 opacity-85`}>{solicitud.prioridad}</p>
                                        </div>
                                    </td>
                                    <td className="text-center w-28">
                                        <span className={`px-2 ${colorEstadoSolicitudPreview(solicitud.estado)} rounded-xl text-center w-30`}>
                                            {solicitud.estado}
                                        </span>
                                    </td>
                                    <td>{solicitud.titulo}</td>
                                    <td>{solicitud.responsableName}</td>
                                    <td>{solicitud.fechaCreacion}</td>
                                    <td>{solicitud.fechaVencimiento}</td>
                                    {["Admin", "General"].find(role => role === userContext.auth?.user?.tipoUsuario) && (
                                        <td>
                                            <div className="flex items-center">
                                                <div className={`${color} w-2  h-2 rounded-full mr-1`}></div>
                                                
                                                <p className={`text-center w-3/4 rounded-lg font-semibold text-gray-900 opacity-85 text-xs`}>{status}</p>
                                            </div>
                                        </td>
                                    )}
                                    <td>
                                        <EditButton url={`/compras/${solicitud.id}`} previousUrl={"/"}/>
                                    </td>
                                </tr>
                            )})
                            }
                        </tbody>
            </Table>
        </div>
    );
}