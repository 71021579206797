import { BaseService } from "./BaseService";
import { CorreoAdjuntoResponse, SolicitudRequest, SolicitudResponse } from "../types";
import { EstadosSolicitud } from "../utilities/StateEnums";
import { TicketExportFilters } from "../types/ticket";


export class ComprasServices extends BaseService {

    /**
     * @param { string } token - Auth token for getting access to API Backend.
     */
    constructor(token: string) {
        super(token, "/api/compras")
    }

    /**
     * Save a new solicitud in db.
     * 
     * @param {SolicitudRequest} data - New solicitud data 
     * @returns 
     */
    public saveSolicitud(data: SolicitudRequest) {
        return this.axios.post("", data, { headers: {'Content-Type': 'multipart/form-data'} });
            
    }

    /**
     * Update Estado field of a solicitud by its id
     * 
     * @param { string | undefined } id - Solicitud id
     * @param { EstadosSolicitud } newStatus - New Estado value
     * @returns
     */
    public updateStateSolicitud(id: string | undefined, newStatus: EstadosSolicitud) {
        return this.axios.post(`/${id}/update-state`, { estadoNuevo: newStatus });
    }

    /**
     *  Get list of Solicitudes
     * 
     * @param { string } [fechaInicial] - Optional filter for initial date range
     * @param { string } [fechaFinal] - Optional filter for end date range
     * @returns { Array<SolicitudResponse> } - Retruns a promise with a SolicitudResponse array 
     */
    public async getSolicitudes(fechaInicial?: string, fechaFinal?: string, onlyOpen?: boolean): Promise<Array<SolicitudResponse>> {
        const response = await this.axios.get<Array<SolicitudResponse>>("", {
            params: { fechaInicial, fechaFinal, onlyOpen }
        });
        const { data } = response;
        return data;
    }

    /**
     * Get list of solicitudes by list of id.
     * 
     * @param { Array<number> } solicitudIdList - List of solicitud ids.
     * @returns { Promise<SolicitudResponse> } - List of solicitudes.
     */
    public async getSolicitudesByIds(solicitudIdList: Array<number>): Promise<Array<SolicitudResponse>> {
        const response = await this.axios.post<Array<SolicitudResponse>>("by-ids", { solicitudIdList });
        const { data } = response;
        return data;
    }

    /**
     *  Get a Solicitud by its id
     * 
     * @param { string | undefined } id - Solicitud id
     * @returns { Promise<SolicitudResponse> } - Returns Promise with a SolicitudResponse
     */
    public async getSolicitudById(id: string | undefined): Promise<SolicitudResponse> {
        const response = await this.axios.get<SolicitudResponse>(`/${id}`);
        const { data } = response;
        return data;
    }

    /**
     * Update validate status and responsable user.
     * Only admin user can use this function.
     * 
     * @param { string | undefined } id - Solicitud id.
     * @param { SolicitudRequest } data - New solicitud data.
     * @returns - Return a promise.
     */
    public updateSolicitud(id: string | undefined, data: SolicitudRequest) {
        return this.axios.put(`/${id}`, data);
    }

    /**
     * Get a list of CorreoAdjunto from usuarios and CorreoAdjunto table.
     * 
     * @returns -List of CorreoAdjunto.
     */
    public async getCorreosAdjuntos(): Promise<Array<CorreoAdjuntoResponse>> {
        const response = await this.axios.get<Array<CorreoAdjuntoResponse>>("adjuntos")
        const { data } = response;
        return data;
    }

    /**
     * Export solicitudes de compra data
     * 
     * @param { TicketExportFilters } filters - Filters.
     * @returns 
     */
    public async exportSolicitudesData(filters: TicketExportFilters) {
        return await this.axios("/export", {
            params: {
                categoriaId: filters.categoriaId,
                sucursalId: filters.sucursalId,
                responsableId: filters.responsableId,
                urgencia: filters.urgencia,
                estado: filters.estado,
                fechaInicial: filters.fechaInicial,
                fechaFinal: filters.fechaFinal
            }
        })
    }
}