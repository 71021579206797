import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { ChangePasswordRequest } from "../../types/user";
import { Button } from "@mui/joy";
import { useUser } from "../../hooks/useUser";
import { AuthServices } from "../../services";
import { errorParser } from "../../utilities";
import { useState } from "react";
import { ErrorMessage, LoadingRequest } from "../UtilsComponents";

export function ChangePasswordForm() {
    const navigate = useNavigate();
    const { id } = useParams();

    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const authService = new AuthServices(token);

    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [requestLoading, setRequestLoading] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        watch,
        formState: {
            errors,
        }
    } = useForm<ChangePasswordRequest>();

    const submitChangePassword = handleSubmit(async (data: ChangePasswordRequest) => {
        try {
            setHasError(false);
            setRequestLoading(true);

            const userId = parseInt(id ?? "0");
            const response = await authService.changePassword(userId, data);

            if(response.status === 200)
                navigate(-1);

        } catch(error) {
            const message = errorParser(error);
            setRequestLoading(false);
            setErrorMessage(message);
            setHasError(true);
        }
    });

    return (
        <div>
            <h2 className="text-3xl my-4 font-semibold">Nueva contraseña</h2>

            <form
                onSubmit={submitChangePassword}
                className="grid grid-cols-2">

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Contraseña</label>
                    <input {...register("password", {
                                required: "La contraseña es requerido",
                                minLength: 8 
                            })}
                            aria-invalid={errors.password ? "true": "false"}
                            className="inputform"
                            placeholder="Contraseña"/>
                    {errors.password?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.password.message}</p>
                    )}
                    {errors.password?.type === "minLength" && (
                        <p className="col-start-2 valErrorText" role="alert">La contraseña tiene que ser de más de 8 caracteres.</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Confirmar contraseña</label>
                    <input {...register("repeatedPassword", 
                            {
                                validate: (val: string ) => {
                                    if(watch("password") != val) {
                                        return "Las contraseñas no coinciden."
                                    }
                                },
                                required: "La contraseña es requerido",
                                minLength: 8 
                            })}
                            aria-invalid={errors.repeatedPassword ? "true": "false"}
                            className="inputform"
                            placeholder="Confirmar contraseña"/>
                    {errors.repeatedPassword?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.repeatedPassword.message}</p>
                    )}
                    {errors.repeatedPassword?.type === "minLength" && (
                        <p className="col-start-2 valErrorText" role="alert">La contraseña tiene que ser de más de 8 caracteres.</p>
                    )}
                    {errors.repeatedPassword?.type === "validate" && (
                        <p className="col-start-2 valErrorText" role="alert">Las contraseñas no coinciden.</p>
                    )}
                </div>

                {hasError && (
                    <ErrorMessage 
                        errorMessage={errorMessage} />
                )}

                <div className="flex justify-end col-span-2 m-2 gap-2">
                    {requestLoading
                    ? <LoadingRequest />
                    :<Button size="sm" 
                            color="success" 
                            className="w-20" 
                            type="submit">
                        Confirmar
                    </Button> 
                    }
                    <Button 
                        size="sm"
                        color="neutral" 
                        className="w-20 mx-2"
                        onClick={() => navigate(-1)}>
                        Regresar
                    </Button>
                </div>

            </form>
        </div>
    );
}