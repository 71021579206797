import { Home } from "./components/Home";
import { Category } from "./components/category/Category";
import { Subcategory } from "./components/category/Subcategory";
import { SucursalesHome } from "./components/HomeCompnents/SucursalesHome";
import { EquipoHome } from "./components/HomeCompnents/EquipoHome";
import { UsuarioHome } from "./components/HomeCompnents/UsuarioHome";
import { BitacoraHome } from "./components/BitacoraComponents/BitacoraHome";
import { TicketHome } from "./components/TicketComponents/TicketHome";
import { MetricsMantenimiento } from "./components/MetricsComponents/MetricsMantenimiento";
import { MetricsCompras } from "./components/MetricsComponents/MetricsCompras";
// Dashboard
import { SolicitudDashboard } from "./components/SolicitudComponents/SolicitudDashboard";
import { SolicitudConsultas } from "./components/SolicitudComponents/SolicitudConsultas";
// Create forms
import { SucursalCreateForm } from "./components/SucursalComponents/SucursalCreatForm";
import { UserCreateForm } from "./components/UserComponents/UserCreateForm";
import { UserTypeCreateForm } from "./components/UserComponents/UserTypeCreateForm";
import { CategoryCreateForm } from "./components/category/CategoryCreateForm";
import { SubcategoryCreateForm } from "./components/category/SubcategoryComponents/SubcategoryCreateForm";
import { EquipoCreateForm } from "./components/EquipoComponents/EquipoCreateForm";
import { TicketCreateForm } from "./components/TicketComponents/TicketCreateForm";
// details
import { EquipoDetail } from "./components/EquipoComponents/EquipoDetail";
import { SucursalDetail } from "./components/SucursalComponents/SucursalDetail";
import { CategoryDetail } from "./components/category/CategoryDetail";
import { UserDetails } from "./components/UserComponents/UserDetail";
import { SubcategoryDetail } from "./components/category/SubcategoryComponents/SubcategoryDetail";
import { UserTypeDetail } from "./components/UserComponents/UserTypeDetail";
import { TicketDetail } from "./components/TicketComponents/TicketDetail";
import { Login } from "./components/UserComponents/Login";
import { Unauthorizate } from "./components/AuthComponents/Unauthorizate";
import { SolicitudCreateForm } from "./components/SolicitudComponents/SolicitudCreateForm";
import { SolicitudDetail } from "./components/SolicitudComponents/SolicitudDetail";
import { BitacoaComprasHome } from "./components/BitacoraComponents/BitacoraComprasHome";
import { MantenimientoDashboard } from "./components/MantenimientoComponents/MantenimientoDashboard";
import { MantenimientoConsultas } from "./components/MantenimientoComponents/MantenimientoConsultas";
import { MantenimientoDetail } from "./components/MantenimientoComponents/MantenimientoDetail";
import { MantenimientoDetailConsultas } from "./components/MantenimientoComponents/MantenimientoDetailConsultas";
import { CodeEquipoCreateForm } from "./components/EquipoComponents/CodeEquipoComponents/CodeEquipoCreateForm";
import { CodeEquipoDetail } from "./components/EquipoComponents/CodeEquipoComponents/CodeEquipoDetail";
import { ChecklistHome, HistorialDetail, HistorialHome, ListCreateForm, ListDetail, ListHome, SeleccionChecklist } from "./components/ChecklistComponents";
import { ChangePasswordForm } from "./components/UserComponents/ChangePasswordForm";

const AppRoutes = {

  home: {
        index: true,
        element: <Home />
        },
  category: {
      home: {
          path: 'categories',
          element: <Category />
      },
      nested: [
          {
            path: ':id',
            element: <CategoryDetail />
          },
          {
            path: 'create',
            element: <CategoryCreateForm />
          },
      ]

  } ,
  subcategory:{
      home: {
          path: 'subcategories',
          element: <Subcategory />
      },
      nested: [
        {
          path: ':id',
          element: <SubcategoryDetail />
        },
        {
          path: 'create',
          element: <SubcategoryCreateForm />
        },
      ],
  },
  sucursal: {
      home: {
          path: 'sucursales',
          element: <SucursalesHome />
        },
      nested: [
        {
          path: ':id',
          element: <SucursalDetail />
        },
        {
          path: 'create',
          element: <SucursalCreateForm />
        },
      ],
  },
  equipo: {
    home:
      {
        path: 'equipos',
        element: <EquipoHome />
      },
    nested: [
      {
        path: ':id',
        element: <EquipoDetail />
      },
      {
        path: 'create',
        element: <EquipoCreateForm />
      },
      {
        path: 'codes/create',
        element: <CodeEquipoCreateForm />
      },
      {
        path: 'codes/:id',
        element: <CodeEquipoDetail />
      },
    ],
  },
  usuario: {
    home:
      {
        path: 'usuarios',
        element: <UsuarioHome />
      },
    nested: [
      {
        path: ':id',
        element: <UserDetails />
      },
      {
        path: ':id/change-password',
        element: <ChangePasswordForm />
      },
      {
        path: 'create',
        element: <UserCreateForm />
      },
      {
        path: 'newtype',
        element: <UserTypeCreateForm />
      },
      {
        path: 'type/:id',
        element: <UserTypeDetail />
      },
    ],
  },
  ticket:{
    home: {
      path: 'ticket',
      element: <TicketHome titulo={true} 
                           onlyNonTerminados={false}
                           previousURL="/ticket"
                           filtrosShowed={{
                                fechas: true, 
                                categoria: true,
                                estado: true,
                                sucursal: true,
                                responsable: true,
                                urgencia: true,
                           }}/>
    },
    nested:[
    {
      path: 'create',
      element: <TicketCreateForm />
    },
    {
      path: ':id',
      element: <TicketDetail redirect="/" />
    },
  ], 
  }, 
  bitacora: {
    home:
      {
        path: 'bitacora',
        element: <BitacoraHome />
      },
    nested: [],
  },
  bitacoraCompras: {
    home:
      {
        path: 'bitacora-compras',
        element: <BitacoaComprasHome />
      },
    nested: [],
  },
  solicitudes: {
    home: 
    {
      path: 'compras',
      element: <SolicitudDashboard />
    },
    nested: [
      {
        path: 'consulta',
        element: <SolicitudConsultas /> 
      },
      {
        path: 'create',
        element: <SolicitudCreateForm /> 
      },
      {
        path: ':id',
        element: <SolicitudDetail />
      },
    ]
  },
  auth: {
    login: {
        path: 'login',
        element: <Login />
      },
    unauth: {
        path: "unauthorizate",
        element: <Unauthorizate />
    }
  },
  metrics: {
    mantenimiento: {
      path: "metrics-mantenimiento",
      element: <MetricsMantenimiento />
    },
    compras: {
      path: "metrics-compras",
      element: <MetricsCompras />
    }
  },
  mantenimiento: {
    home: {
      path: 'preventivo',
      element: <MantenimientoDashboard />
    },
    nested: [
      {
        path: 'consulta',
        element: <MantenimientoConsultas />
      },
      {
        path: 'consulta/:id',
        element: <MantenimientoDetailConsultas />
      },
      {
        path: ':id',
        element: <MantenimientoDetail />
      }
    ]
  },
  checklist: {
    home: {
      path: 'checklist',
      element: <SeleccionChecklist />
    },
    nested: [
      {
        path: ':id',
        element: <ChecklistHome />
      },
      {
        path: 'historial',
        element: <HistorialHome />
      },
      {
        path: 'historial/:id',
        element: <HistorialDetail />
      }
    ]
  },
  revision: {
    home: {
      path: 'revision',
      element: <ListHome />
    },
    nested: [
      {
        path: 'create',
        element: <ListCreateForm />
      },
      {
        path: ':id',
        element: <ListDetail />
      }
    ]
  }
};

export default AppRoutes;
