import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { TareaRequest } from "../../types";

interface Props {
    tareaId: number;
    tareaData: TareaRequest;
    setTareaData: Dispatch<SetStateAction<TareaRequest>>;    
}

export function CheckBoxItem({ tareaId, setTareaData, tareaData }: Props) {
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const comentario = event.target.value == "Sin Problema" ? null : tareaData.comentario;
        setTareaData({
            ...tareaData,
            comentario,
            estado: event.target.value
        });
    }

    return (
        <>
        <div className="flex gap-2">
            <input 
                className="
                    styled-checkbox w-5 h-5 rounded-full border-2 border-gray-300 
                    appearance-none checked:bg-gray-400 checked:border-gray-600 
                    cursor-pointer transition-colors duration-300
                    checkbox-checklist"
                type="radio"
                onChange={onChange}
                value="Sin Problema"
                id={`estado-sinproblema-${tareaId}`}
                name={`estado-tarea-${tareaId}`} />
            <label className="text-sm" htmlFor={`estado-sinproblema-${tareaId}`}>Sin Problema</label>
        </div>


        <div className="flex gap-2">
            <input 
                className="
                    styled-checkbox w-5 h-5 rounded-full border-2 border-gray-300 
                    appearance-none checked:bg-red-400 checked:border-red-600 
                    cursor-pointer transition-colors duration-300
                    checkbox-checklist"
                type="radio"
                onChange={onChange}
                value="Problema Encontrado"
                id={`estado-problema-${tareaId}`}
                name={`estado-tarea-${tareaId}`} />
            <label className="text-sm" htmlFor={`estado-problema-${tareaId}`}>Problema Encontrado</label>
        </div>
        </>
    )
}