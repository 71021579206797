import { Link, useLocation } from "react-router-dom";
import Button from "@mui/joy/Button";
import CreateIcon from '@mui/icons-material/Create';
import { TicketHome } from "./TicketComponents/TicketHome";
import { ShowFiltros } from "../types";

export function Home() {
    const location = useLocation();
    const filtrosShowed: ShowFiltros = {
        fechas: true, 
        categoria: true,
        estado: true,
        sucursal: true,
        responsable: true,
        urgencia: true,
        checklist: false,
        especialidad: false,
        doctores: false,
        tipoDoctor: false,
    }

    return (
      <div>
        <h1 className="text-3xl my-4 font-semibold">Dashboard</h1>
        <Link to={"/ticket/create"}>
        <Button startDecorator={<CreateIcon />} 
                size='sm'
                color='success'
                variant='solid'>
                  Crear ticket
        </Button>
        </Link>
        <TicketHome titulo={false} 
                    onlyNonTerminados={true}
                    filtrosShowed={filtrosShowed}
                    previousURL={location.pathname}/>
      </div>
    );
}
