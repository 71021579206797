import ErrorIcon from '@mui/icons-material/Error';
import { useState } from 'react';

export function ErrorIconMessage() {
    const [mostrarMensaje, setMostrarMensaje] = useState<boolean>(false);

    return (
        <div className="flex relative">
            <ErrorIcon 
                className="text-red-400"
                onMouseEnter={() => setMostrarMensaje(true)}
                onMouseLeave={() => setMostrarMensaje(false)}/>
            <p className="valErrorText text-sm">Error</p>

            {mostrarMensaje && (
                <div className="absolute top-8 right-0 sm:left-0 bg-white p-2 rounded-md shadow-md border border-gray-200 w-[135px] z-10">
                    Intente más tarde.
                </div>
            )}

        </div>
    );
}