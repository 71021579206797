import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form"; 
import { TipoUsuarioModel } from "../../types";
import { Button } from "@mui/joy";
import { UserServices } from "../../services"; 
import { useUser } from "../../hooks/useUser";
import { LoadingRequest, ErrorMessage } from "../UtilsComponents";
import { errorParser } from "../../utilities";

export function UserTypeCreateForm(){
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [requestLoading, setRequestLoading] = useState<boolean>(false);

    const userService = new UserServices(token);

    const navigate = useNavigate();
    const { register,
            handleSubmit,
            formState: { errors } 
        } = useForm<TipoUsuarioModel>();
    
    const submitUserType = handleSubmit(async (data: TipoUsuarioModel) => {
        try {
            setHasError(false);
            setRequestLoading(true);

            const response = await userService.saveUserType(data)

            if(response.status === 201) {
                setRequestLoading(true);
                navigate("/usuarios");
            }

        } catch(error) {
            const message = errorParser(error);
            setRequestLoading(false);
            setErrorMessage(message);
            setHasError(true);
        }
    });
    
    return (
        <div>
            <h2 className="text-3xl my-4 font-semibold">Nuevo Tipo de Usuario</h2>
            <form onSubmit={submitUserType} className="grid grid-cols-2" autoComplete="off">

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Nombre</label>
                    <input {...register("tipo", {
                            required: "Nombre del tipo de usuario es requerido",
                            maxLength: 20
                        })}
                        aria-invalid={errors.tipo ? "true" : "false"}
                        className="inputform"
                        placeholder="Nombre del tipo"/>
                    {errors.tipo?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.tipo.message}</p>
                    )}
                    {errors.tipo?.type === "maxLength" && (
                        <p className="col-start-2 valErrorText" role="alert">Maximo numero de caracteres es 20</p>
                    )}
                </div>

                <div className="flex justify-end col-span-2 m-2 gap-2">
                    {requestLoading 
                    ? <LoadingRequest />
                    : <Button size="sm" color="success" className="w-20" type="submit">Registrar</Button>
                    }
                    <Link to="/usuarios">
                        <Button size="sm" color="neutral" className="w-20 mx-2">Cancelar</Button>
                    </Link>
                </div>

                {hasError && (
                    <ErrorMessage
                        errorMessage={errorMessage} />
                )}

            </form>
        </div>
    );
}