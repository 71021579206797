import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../../hooks/useUser"; 
import { TicketResponse, UsuarioModel } from "../../types";
import { Button, Divider } from "@mui/joy/";
import { ComentarioTypes, Estados, ROLES, Urgencia } from "../../utilities/StateEnums";
import { ChangeEstado } from "./ChangeEstado";
import { Comentarios } from "./Comentarios";
import { TicketServices, UserServices } from "../../services";
import { LoadingScreen } from "../UtilsComponents/LoadingScreen";
import { ImagePreview } from "../UtilsComponents/ImageComponents";

interface Props {
    redirect: string
}

export function TicketDetail({ redirect }: Props) {
    const navigate = useNavigate();
    const location = useLocation();
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";
    const { id } = useParams();
    const urgenciaList = Object.values(Urgencia);

    const ticketService = new TicketServices(token);
    const userService = new UserServices(token);

    const [respUser, setRespUser] = useState<Array<UsuarioModel>>([]);
    const [dataLoading, setDataLoading] = useState<boolean>(true);

    const { 
        register, 
        setValue, 
        watch, 
        handleSubmit,
        getValues,
        formState: { isDirty }
    } = useForm<TicketResponse>({
        defaultValues: async () => {
            const tickets = await ticketService.getTicketById(id);
            const users = userService.getUsersByRole("Mantenimiento")
                .then(data => setRespUser(data))
            
            Promise.all([tickets, users])
                .then(_ => setDataLoading(false));

            return tickets;
        }
    });

    const allowRolesCreateSol = [ROLES.ADMIN, ROLES.RESPONSABLE_MAN, ROLES.RESPONSABLE_COMP, ROLES.GENERAL, ROLES.MANTENIMIENTO];
    const estado = watch("estado", "default");
    const fechaAtencion = watch("fechaAtencion", "");
    const archivo = watch("archivo");
    const solicitudId = getValues("solicitudId");

    // nueva implementacion
    const [atendidoState, setAtendidoState] = useState<boolean>(estado != "Abierto" && estado != "Vencido");
    const [cerradoState, setCerradoState] = useState<boolean>(estado === "Terminado");

    const submit = handleSubmit((data: TicketResponse) => {
        ticketService.updateTicket(id, data)
            .then(response => {
                if(response.status === 200)
                    navigate("/")
            })
            .catch(error => console.error(error))
    })

    return (
        <>
        {dataLoading
        ? <LoadingScreen /> 
        :
        <div>
            <h2 className="text-3xl my-4 font-semibold">Ticket Folio {id}</h2>
            <form className="grid grid-cols-2" onSubmit={submit}>
                <label className="font-medium">Titulo</label>
                <input {...register("titulo")}
                        className="inputform"
                        disabled={true}/>

                <label className="font-medium">Descripcion</label>
                <textarea {...register("descripcion")}
                        className="textareaform"
                        rows={5}
                        disabled={true}/>

                <label className="font-medium">Informado por</label>
                <input  {...register("informadoNombre")}
                        className="inputform"
                        disabled={true}/>

                <label className="font-medium">Responsable</label>
                <select {...register("responsableId")}
                    disabled={userContext.auth?.user?.tipoUsuario !== "Admin"}
                    className="selectform">
                    <option value={0} hidden></option>
                    {respUser.map((user, index) => (
                        <option key={index} value={user.id}>{user.nombre}</option>
                    ))}
                </select>

                <label className="font-medium">Sucursal</label>
                <input {...register("sucursalNombre")}
                        className="inputform"
                        disabled={true}/>

                <label className="font-medium">Categoria</label>
                <input {...register("categoriaNombre")}
                        className="inputform"
                        disabled={true}/>

                <label className="font-medium">Subcategoria</label>
                <input {...register("subcategoriaNombre")}
                        className="inputform"
                        disabled={true}/>

                <label className="font-medium">Equipo</label>
                <input {...register("equipoNombre")}
                        className="inputform"
                        disabled={true}/>
                
                <label className="font-medium">Estado</label>
                <input {...register("estado")}
                        className="inputform" 
                        disabled={true}/>

                <label className="font-medium">Prioridad</label>
                <input {...register("prioridad")}
                        className="inputform"
                        disabled={true}/>

                <label className="font-medium">Urgencia</label>
                <select {...register("urgencia")}
                        disabled={true}
                        className="selectform">
                    {urgenciaList.map((urgencia, index) => (
                        <option key={index} value={urgencia}>{urgencia}</option>
                    ))}
                </select>


                <label className="font-medium">Fecha de Informe</label>
                <input {...register("fechaInforma")}
                        className="inputform"
                        disabled={true}
                        type="datetime-local" />

                <label className="font-medium">Fecha de Atencion</label>
                <input {...register("fechaAtencion")}
                        className="inputform"
                        disabled={true}
                        type="datetime-local" />

                <label className="font-medium">Fecha de Vencimiento</label>
                <input  {...register("fechaVencimiento")}
                        className="inputform"
                        type="datetime-local"
                        disabled={true}/>

                <label className="font-medium" htmlFor="fechaCierre">Fecha de Cierre</label>
                <input  {...register("fechaCierre")}
                        id="fechaCierre"
                        className="inputform"
                        color="primary"
                        disabled={true}
                        type="datetime-local" />

                {/*<Comentarios control={control} />*/}
                { userContext.auth?.user?.tipoUsuario !== "General" && (
                    <ChangeEstado className={"flex flex-col col-span-2 my-3 w-28 gap-y-1"}
                            previousUrl={location.state?.previousUrl ?? "/"}
                            estado={estado}
                            fechaAtencion={fechaAtencion}
                            setValue={setValue}
                            atendidoState={{atendidoState: atendidoState, setAtendidoState: setAtendidoState}}
                            cerradoState={{cerradoState: cerradoState, setCerradoState: setCerradoState}}/>
                )}

                <div className="flex justify-between col-span-2 my-2 gap-2 ">

                    <div>
                    {solicitudId
                    ? <Link to={`/compras/${solicitudId}`}> 
                        <p>Ir a la solicitud de compra</p>
                    </Link>
                    : <>
                        { allowRolesCreateSol.find(role => role === userContext.auth?.user?.tipoUsuario) 
                            && [Estados.Proceso, Estados.Vencido].find(state => state === estado )
                            && fechaAtencion.length > 0
                            && (
                            <Link to="/compras/create" state={{ticketStatus: getValues(), from: location, ticketId: id }}>
                                <Button size="sm" 
                                        color="success" 
                                        className="w-auto" 
                                        type="button">
                                    Generar Solicitud de Compra
                                </Button>
                            </Link>
                        )}
                    </>

                    }
                    </div>

                    <div className="flex gap-2">
                        { !(estado === "Terminado") && (
                            <Button size="sm" 
                                    color="success" 
                                    disabled={!isDirty}
                                    className="w-20" 
                                    type="submit">
                                Guardar
                            </Button>
                        )}

                        <Button 
                            className="w-20 mx-2"
                            size="sm"
                            color="neutral"
                            onClick={() => navigate(-1)}>
                            Regresar
                        </Button>
                    </div>
                </div>

            </form>

            {archivo !== null && (
                <ImagePreview
                    urlImage={archivo}/>
            )}

            <Divider />
            <Comentarios 
                id={id} 
                comentarioType={ComentarioTypes.TICKETS}
                usuarioId={userContext.auth?.user?.id ?? 2}/>
        </div>
        }
        </>
    );
}