import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { TareaModel, TareaRequest } from "../../types";
import { EquipoRevision, TareaEquipoData } from "../../types/checklist";

interface Props {
    tarea: TareaModel
    totalEquipos: number;
    listTareas: TareaRequest[];
    setListTareas: Dispatch<SetStateAction<Array<TareaRequest>>>
}

enum RespuestasChecklist {
    OK = "OK",
    NO_OK = "NO OK"
}

type ListEquipoRevision = {
    tarea_id: number;
    list: Array<EquipoRevision>;
}

type EquipoSelected = {
    id: number;
    equipoDefault: boolean;
}

export function ItemChecklistV2({ tarea, totalEquipos, listTareas, setListTareas }: Props) {
    let extraCells = totalEquipos - tarea.equipos.length;

    // lista de las revisiones de cada equipo por tarea
    const [listEquipoRevisiones, setListEquipoRevisiones] = useState<ListEquipoRevision>({
        tarea_id: tarea.id,
        list: []
    });

    const [showComentarios, setShowComentarios] = useState<boolean>(false);
    const [currentEquipoId, setCurrentEquipoId] = useState<EquipoSelected>();

    // maneja el estado de la revision de la tarea
    const [revisionTarea, setRevisionTarea] = useState<TareaRequest>({
        tareaId: 0,
        estado: "",
        comentario: null,
        revisionesEquipo: []
    });

    // maneja el estado de la revision de los equipos
    const [revisionEquipo, setRevisionEquipo] = useState<EquipoRevision>({
        equipoId: 0,
        estado: "",
        comentario: "",
        equipoName: "",
        tareaId: tarea.id,
        isEquipoDefault: false,
        subcategoriaId: tarea.subcategoriaId
    });

    const onCheckTarea = (event: ChangeEvent<HTMLSelectElement>, equipoIndex?: number) => {
        const estado = event.target.value;

        //let equipoId: number | null = null;
        let equipo: TareaEquipoData | null = null;

        if(equipoIndex != null) {
            equipo = tarea.equipos.at(equipoIndex) ?? null;

            if(equipo != null)
                setCurrentEquipoId({
                    id: equipo.id, 
                    equipoDefault: equipo.equipoDefault
                });
        }

        if(!tarea)
            return;

        // creamos la revision del equipo
        if(equipo != null) {
            const equipoInfo = tarea.equipos.find(equipoInf => equipoInf.id == equipo.id);
            const equipoName = equipoInfo?.nombre ?? "Equipo Por Defecto";
            setRevisionEquipo({
                equipoId: equipo.id,
                estado: estado,
                comentario: "",
                equipoName: equipo.equipoDefault ? tarea.nombre : equipoName,
                tareaId: revisionTarea.tareaId ?? tarea.id,
                isEquipoDefault: equipoInfo?.equipoDefault ?? false,
                subcategoriaId: tarea.subcategoriaId
            });
        }

    }

    const onChangeComentario = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRevisionTarea({
            ...revisionTarea,
            comentario: event.target.value
        })
    }

    // Busca la revision de un equipo basandose en el id del mismo equipo.
    const searchRevisionEquipobyId = (equipo: EquipoSelected) => {
        const revisionEquipoRequest = listEquipoRevisiones.list.find(revision => revision.equipoId == equipo.id || equipo.equipoDefault == true);

        if (revisionEquipoRequest == null) {
            // si no encontramos una revision ya hecha para ese equipo, la creamos.
            if (revisionEquipo.estado.length > 0) {
                setListEquipoRevisiones({
                    ...listEquipoRevisiones,
                    list: [...listEquipoRevisiones.list, revisionEquipo]
                })
            }
        } else {
            const indexRevisionModified = listEquipoRevisiones.list.findIndex(
                revisionInfo => revisionInfo.equipoId == revisionEquipo.equipoId
            );
            listEquipoRevisiones.list[indexRevisionModified] = revisionEquipo;
            setListEquipoRevisiones(listEquipoRevisiones);
        }

    }

    const storeListRevisionEquiposInTarea = (tareaId: number) => {
        // crear el objeto revisionTarea
        const nuevaRevisionTarea: TareaRequest = {
            tareaId: revisionTarea.tareaId == tareaId ? revisionTarea.tareaId : tareaId,
            comentario: revisionTarea.comentario,
            estado: "",
            revisionesEquipo: listEquipoRevisiones.list
        };


        setRevisionTarea(nuevaRevisionTarea)
    }

    const verifyIfCleanComentarioValue = () => {
        if(!revisionTarea.revisionesEquipo.find(revision => revision.estado == RespuestasChecklist.NO_OK)) {
            setRevisionTarea({
                ...revisionTarea,
                comentario: null
            })
        }
    }

    // actualiza la lista de revisiones de equipos
    useEffect(() => {
        if(currentEquipoId != null) {
            searchRevisionEquipobyId(currentEquipoId);
            verifyIfCleanComentarioValue();
        }

    }, [revisionEquipo]);

    // actualiza la lista de revision de equpos de la tarea
    useEffect(() => {
        // almacenar la lista de revision de los equipos en el objeto
        // de la revision de la tarea.
        storeListRevisionEquiposInTarea(tarea.id);

    }, [listEquipoRevisiones]);

    useEffect(() => {
        // buscar la tarea por su id
        const tareaSearch = listTareas.find(tareaInfo => tareaInfo.tareaId == tarea.id);

        // si no hay un objeto revisionTarea ya almacenado en la lista de tareas
        // entonces agregar el objeto reacien creado.
        if (tareaSearch == null) {
            if (revisionTarea.tareaId != 0) {
                setListTareas([...listTareas, revisionTarea])
            }

        } else {
            // si ya existe una objeto revisionTarea con ese id, entonces remplazarlo con 
            // el nuevo objeto revisionTarea modificado.
            const modifiedList = listTareas.map(
                tareaInfo => tareaInfo.tareaId == revisionTarea.tareaId ? revisionTarea : tareaInfo
            );
            setListTareas(modifiedList);
        }

    }, [revisionTarea]);

    // busca en la lista de revisiones de equipo de la tarea si hay
    // una revision NO OK, si la hay renderiza el input de comentarios.
    useEffect(() => {
        const show = listEquipoRevisiones.list.find(revision =>
            revision.estado == RespuestasChecklist.NO_OK
        ) ? true: false;

        setShowComentarios(show);

    }, [listEquipoRevisiones, revisionEquipo]);

    return (
        <tr>
            <td>{tarea.nombre}</td>
            <td className="text-center">OK/NO OK</td>
            {Array.from({ length: tarea.equipos.length }).map((_, index) => (
            <td key={index}>
                <select 
                    className="selectfilter"
                    onChange={(event) => onCheckTarea(event, index)}>
                    <option value="" hidden defaultChecked></option>
                    <option value={RespuestasChecklist.OK}>OK</option>
                    <option value={RespuestasChecklist.NO_OK}>NO OK</option>
                </select>
            </td>
            ))}
            {Array.from({ length: extraCells }).map((_, index) => (
            <td key={index}></td>
            ))}
            <td>
                {/* Verifica en la lista de revisiones de equipos de esta
                tarea si hay una revision con NO OK, si la hay, se renderiza
                el input para escribir un comentario. */}
                {showComentarios && (
                    <div className="grid grid-cols-1 sm:flex sm:gap-2">
                        <label className="w-[176px]">Descipción del problema</label>
                        <textarea
                            className="
                                px-1 h-[26px] rounded sm:w-[600px] border border-red-300 
                                focus:border-red-500 focus:outline-none"
                            onChange={onChangeComentario} >
                        </textarea>
                    </div>
                )}
            </td>
        </tr>
    );
}