import { Button, Table } from "@mui/joy";
import { useUser } from "../../hooks/useUser";
import { ChecklistServices, SucursalServices } from "../../services";
import { useState, useEffect } from "react";
import { ChecklistModel, SucursalModel } from "../../types";
import { Link } from "react-router-dom";
import { ElementsNotFound, LoadingComponent } from "../UtilsComponents";
import { ROLES } from "../../utilities/StateEnums";

export function SeleccionChecklist() {
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const checklistService = new ChecklistServices(token);
    const sucursalService = new SucursalServices(token);

    const [checklist, setChecklist] = useState<Array<ChecklistModel>>([]);
    const [sucursalList, setSucursallist] = useState<Array<SucursalModel>>([]);
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [sucursalSelected, setSucursalSelected] = useState<number>(userContext.auth?.user?.sucursalId ?? 0);

    useEffect(() => {
        sucursalService.getSucursales()
            .then(data => setSucursallist(data));
    }, []);

    useEffect(() => {
        const sucursalId = sucursalSelected != 0 ? sucursalSelected : undefined;
        checklistService.getListChecklist(sucursalId)
            .then(data => {
                setChecklist(data);
                setDataLoading(false);
            });
    }, [sucursalSelected]);

    return (
        <div>
            <div className="flex items-center justify-between">
                <h2 className="text-3xl my-4 font-semibold">Seleccionar Lista de Revisiones</h2>

                {userContext.auth?.user?.tipoUsuario === ROLES.ADMIN && (
                <div className="w-[300px]">
                    <label className="font-medium mr-4" htmlFor="sucursal">Sucursal</label>
                    <select
                        onChange={(event) => setSucursalSelected(parseInt(event.target.value))}
                        value={sucursalSelected}
                        className="inputform">
                        <option value={0}>Todas las Sucursales</option>
                        {sucursalList.map((sucursal, index) => (
                        <option key={index} value={sucursal.id}>{sucursal.smallDir}</option>
                        ))}
                    </select>
                </div>
                )}
            </div>

            {dataLoading
            ? <LoadingComponent />
            : 
            <div className="overflow-auto max-h-[600px] rounded-lg border border-slate-200">

            <Table
                stickyHeader
                sx={(theme) => ({
                    "th": {bgcolor: "#111827", color: "#ffffff"}
                })}>
                <thead>
                    <tr>
                        <th>Lista</th>
                        <th className="w-28">
                            <p className="text-center">Sucursal</p>
                        </th>
                        <th className="w-28">
                            <p className="text-center">Hora Limite</p>
                        </th>
                        <th className="w-32">
                            <p className="text-center">Ultima Revision</p>
                        </th>
                        <th className="w-28">
                            <p className="text-center">Estatus</p>
                        </th>
                        <th className="w-32">
                            <p className="text-center">Acciones</p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {checklist.map((lista, index) => (
                        <tr key={index} className="hover:bg-slate-100">
                            <td>{lista.nombre}</td>
                            <td>{lista.sucursal}</td>
                            <td>
                                {lista.horaLimite && (
                                <input 
                                    className="bg-inherit"
                                    type="time" 
                                    value={lista.horaLimite} 
                                    disabled/>
                                )}
                            </td>
                            <td>{lista.ultimaRevision}</td>
                            <td className="text-center">
                                <span className={`px-2 ${lista.checkedToday ? "bg-emerald-200" : "bg-blue-200"} rounded-xl text-center w-30`}>
                                    {lista.checkedToday ? "Terminada" : "Sin Realizar"}
                                </span>
                            </td>
                            <td>
                                <Link to={{
                                    "pathname": `/checklist/${lista.id}`,
                                    "search": userContext.auth?.user?.tipoUsuario == ROLES.ADMIN 
                                        ? `?sucursal=${sucursalSelected}` 
                                        : undefined
                                }}>
                                    <Button size="sm" 
                                            variant="plain" 
                                            color="success">
                                            Realizar revision
                                    </Button>
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {checklist.length == 0 && (
                <ElementsNotFound />
            )}

            </div>
        }
        </div>
    );
}