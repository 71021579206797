import { ChangeEvent, Dispatch, MouseEvent, SetStateAction, useContext, useEffect, useState } from "react";
import { ChecklistContext } from "../../context/ChecklistContext";
import { CheckBoxItem } from "./CheckBoxItem";
import { TareaRequest } from "../../types";

interface Props {
    idTarea: number;
    text: string;
    listTareas: Array<TareaRequest>;
    setListTareas: Dispatch<SetStateAction<Array<TareaRequest>>>;
}

export function ItemChecklist({ idTarea, text, listTareas, setListTareas }: Props) {
    const { setText } = useContext(ChecklistContext);
    const [tareaData, setTareaData] = useState<TareaRequest>({
        tareaId: idTarea,
        estado: "",
        comentario: null,
        revisionesEquipo: []
    });

    const onClick = (event: MouseEvent<HTMLDivElement>) => {
        // abrir los detalles de la tarea
        if (!(event.target as HTMLDivElement).classList.contains("checkbox-checklist")) {
            if (setText != null) {
                setText(text);
            }
        }
    }

    const onChangeComentario = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setTareaData({
            ...tareaData,
            comentario: event.target.value
        })
    }

    useEffect(() => {
        const tarea = listTareas.find(tarea => tarea.tareaId == idTarea);
        if (tarea == null) {

            if (tareaData.tareaId != 0 && tareaData.estado.length > 0) {
                setListTareas([...listTareas, tareaData])
            }

        } else {
            const modifiedList = listTareas.map(tarea => tarea.tareaId == tareaData.tareaId && tareaData.estado.length > 0 ? tareaData : tarea)
            setListTareas(modifiedList);
        }
    }, [tareaData]);

    return (
        <div>
            <div 
                className="checklist-container flex flex-col sm:flex-col gap-3 bg-gray-100 border border-gray-200 p-3 rounded-md my-1 shadow"
                onClick={onClick}>

                <p className="my-1 w-full break-all font-[450]">{text}</p>
                <div className="flex flex-col sm:flex-row justify-start sm:gap-4">

                    <div className="flex justify-start gap-2 sm:items-start">
                        <CheckBoxItem
                            tareaData={tareaData}
                            setTareaData={setTareaData}
                            tareaId={idTarea} />
                    </div>

                    {tareaData?.estado === "Problema Encontrado" && (
                        <div className="grid grid-cols-1 sm:flex sm:gap-2">
                            <label className="w-[176px]">Descipción del problema</label>
                            <textarea
                                className="
                                    px-1 h-[26px] rounded sm:w-[600px] border border-red-300 
                                    focus:border-red-500 focus:outline-none"
                                onChange={onChangeComentario} >
                            </textarea>
                        </div>
                    )}
                </div>


            </div>
        </div>
    );
}
