import { ReactNode, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

interface Props {
    children?: ReactNode
}

export function ShowSideBar({ children }: Props){
    const location = useLocation();
    const [showSideBar, setShowSideBar] = useState(false);

    useEffect(()=> {
        if(location.pathname === "/login"){
            setShowSideBar(false);
        }else {
            setShowSideBar(true);
        }
    }, [location]);
    
    return (
        <>
            {showSideBar && children}
        </>
    );
}