import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button } from "@mui/joy";
import { CategoryModel } from "../../types";
import { CategoriaServices } from "../../services/categorias"; 
import { useUser } from "../../hooks/useUser";
import { LoadingRequest, ErrorMessage } from "../UtilsComponents";
import { errorParser } from "../../utilities";


export function CategoryCreateForm() {
    const navigate = useNavigate();

    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";
    const categoriaService = new CategoriaServices(token);

    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [requestLoading, setRequestLoading] = useState<boolean>(false);

    const { register, 
            handleSubmit, 
            formState: { errors }
        } = useForm<CategoryModel>();

    const submitCategory = handleSubmit(async (data: CategoryModel) =>{
        try {
            setHasError(false);
            setRequestLoading(true);

            const response = await categoriaService.saveCategory(data)

            if(response.status === 201) {
                setRequestLoading(false);
                navigate("/categories");
            }

        } catch(error) {
            const message = errorParser(error);
            setRequestLoading(false);
            setErrorMessage(message);
            setHasError(true);
        }
    });

    return (
        <div>
            <h2 className="text-3xl my-4 font-semibold">Nueva Categoria</h2>
            <form onSubmit={submitCategory} className="grid grid-cols-2">

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Categoria</label>
                    <input {...register("nombreCategoria", {
                                required: "El nombre de la categoría es requerido",
                                maxLength: 70
                            })} 
                            aria-invalid={errors.nombreCategoria ? "true" : "false"}
                            className="inputform"
                            type="text" 
                            placeholder="nombre de la categoria"/>
                    {errors.nombreCategoria?.type === "required" && (
                        <p className="col-start-2 valErrorText" role="alert">{errors.nombreCategoria.message}</p>
                    )}
                    {errors.nombreCategoria?.type === "maxLength" && (
                        <p className="col-start-2 valErrorText" role="alert">Maximo numero de caracteres es 70</p>
                    )}
                </div>

                <div className="flex justify-end col-span-2 m-2 gap-2">
                    {requestLoading
                    ? <LoadingRequest />
                    : <Button size="sm" color="success" className="w-20" type="submit">Registrar</Button>
                    }
                    <Link to="/categories">
                        <Button size="sm" color="neutral" className="w-20 mx-2">Cancelar</Button>
                    </Link>
                </div>

                {hasError && (
                    <ErrorMessage 
                        errorMessage={errorMessage} />
                )}
            </form>
        </div>
    );
}