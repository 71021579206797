import { SubcategoryModel } from "../../types";
import { useState, useEffect } from "react";
import { AddButon } from "../../utilities/UtilsComponents/AddButon";
import { EditButton } from "../../utilities/UtilsComponents/EditButton";
import Table from "@mui/joy/Table";
import { useUser } from "../../hooks/useUser";
import { SubcategoryServices } from "../../services";
import { LoadingComponent } from "../UtilsComponents/LoadingComponent";

export function Subcategory() {
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const [subcategories, setSubcategories] = useState<Array<SubcategoryModel>>([]);
    const [dataLoading, setDataLoading] = useState<boolean>(true);

    const subcategoryService = new SubcategoryServices(token);

    useEffect(() => {
        subcategoryService.getSubcategories()
            .then(data => {
                setSubcategories(data);
                setDataLoading(false);
            });
    }, []);

    return (
        <div>

            <h2 className="text-3xl my-4 font-semibold">Subcategorias</h2>

            <AddButon url="/subcategories/create"/>

            {dataLoading
            ? <LoadingComponent />
            :
            <div className="overflow-auto max-h-[600px] rounded-lg border border-slate-200 my-4">
            <Table
                stickyHeader
                sx={(theme) => ({
                    "th": {bgcolor: "#111827", color: "#ffffff"}
                })}>

            <table className="w-full">
                <thead>
                    <tr>
                        <th>Nombre Subcategoria</th>
                        <th>Categoria</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {subcategories.map((subcategoria, index)=> {
                        return (
                        <tr key={index} className={`hover:bg-slate-100`}>
                            <td>{subcategoria.nombreSubcategoria}</td>
                            <td>{subcategoria.nombreCategoria}</td>
                            <td>
                                <EditButton url={`/subcategories/${subcategoria.id}`} />
                            </td>
                        </tr>
                    )})}
                </tbody>
            </table>

            </Table>
            </div>
            }
        </div>
    );
}