import { ReactNode } from "react";

interface Props {
    state: string;
}

export function ColorEquipoState({ state }: Props) {
    const colorPreview = state === "Activo" ? "bg-blue-200" : "bg-rose-200";
    return (
        <span className={`px-2 ${colorPreview} rounded-xl text-center w-30 flex justify-center`}>
            {state}
        </span>
    )
}