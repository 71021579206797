import { Link } from "react-router-dom";
import Button from "@mui/joy/Button";
import Add from "@mui/icons-material/Add";

interface Props {
    url: string
}

export function AddButon({ url }: Props) {
    return (
        <Link to={url}>
            <Button size="sm" variant="soft" startDecorator={<Add />}>Agregar</Button>
        </Link>
    );
}