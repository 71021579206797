import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ComentarioModel } from "../../types";
import { useUser } from "../../hooks/useUser";
import { ComentarioTypes } from "../../utilities/StateEnums";
import { ComentarioServices } from "../../services";
import { ImagePreview } from "../UtilsComponents/ImageComponents";
import AddCommentRoundedIcon from '@mui/icons-material/AddCommentRounded';
import { ErrorMessage, LoadingRequest } from "../UtilsComponents";
import { errorParser } from "../../utilities";


interface Props {
    id: string | undefined
    comentarioType: ComentarioTypes
    usuarioId: number
}

type defaultValues = {
    id: string | undefined;
    texto: string;
    usuarioId: number;
    imagen: File | null
}

export function Comentarios({ id, comentarioType, usuarioId}: Props) {
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";
    const [comentarios, setComentarios] = useState<Array<ComentarioModel>>([]);
    const { register, handleSubmit, setValue, formState: { errors } } = useForm<defaultValues>({
        defaultValues: {
            "id": id,
            "texto": "",
            "usuarioId": userContext.auth?.user?.id ?? usuarioId,
            "imagen": null
        }
    });

    // send request managing
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const comentarioService = new ComentarioServices(token);

    const submitComentario = handleSubmit(async (data: defaultValues) => {
        try {
            setHasError(false);
            setSaveLoading(true);

            if(comentarioType === ComentarioTypes.TICKETS) {
                const response = await comentarioService.saveComentarioInTicket(data);

                if(response.status === 201) {
                    setValue("texto", "");
                    setValue("imagen", null);
                    setSaveLoading(false);
                    const comments = await comentarioService.getComentariosByTicketId(id);
                    setComentarios(comments);
                }
            }

            if(comentarioType === ComentarioTypes.SOLICITUD) {
                const response = await comentarioService.saveComentarioInSolicitud(data);

                if(response.status === 201) {
                    setValue("texto", "");
                    setValue("imagen", null);
                    setSaveLoading(false);
                    setHasError(false);
                    const comments = await comentarioService.getComentarioBySolicitudId(id);
                    setComentarios(comments);
                }
            }
        } catch(error) {
            const message = errorParser(error);
            setSaveLoading(false);
            setHasError(true);
            setErrorMessage(message);
        }
    });

    useEffect(() => {
        if(comentarioType === ComentarioTypes.TICKETS) {
            comentarioService.getComentariosByTicketId(id)
                .then(data => {
                    setComentarios(data);
                })
                .catch(error => console.error(error));
        }

        if(comentarioType === ComentarioTypes.SOLICITUD) {
            comentarioService.getComentarioBySolicitudId(id)
                .then(data => {
                    setComentarios(data);
                })
                .catch(error => console.error(error));
        }
    }, []);

    return (
        <div>
            <form className="grid grid-cols-4 gap-2 sm:flex sm:row" onSubmit={submitComentario}>
                <input {...register("texto", {
                        required: "El comentario no puede estar vacio"
                    })} 
                    className={`
                        p-2 my-2 mx-1 w-full h-8 col-span-3 order-1 
                        border border-gray-400 ${ errors.texto ? "focus:border-red-400" : "focus:border-slate-600"} 
                        focus:outline-none focus:bg-slate-300
                        bg-slate-200 rounded-md text-gray-800
                        `}
                    placeholder="Escribir comentario..." 
                    aria-invalid={errors.texto ? "true" : "false"}
                    autoComplete="off"
                    type="text"/>

                <label className="order-3 sm:order-2">
                    <input
                        onChange={(event) => {
                            if(event.target.files !== null)
                                setValue("imagen", event.target.files[0])
                            }}
                        type="file"
                        className="text-sm text-grey-500 p-2
                            file:mr-5 file:py-2 file:px-6
                            file:rounded-full file:border-0
                            file:text-sm file:font-medium
                            file:bg-blue-50 file:text-blue-700
                            hover:file:cursor-pointer hover:file:bg-amber-50
                            hover:file:text-amber-700"/>
                </label>
                
                {saveLoading
                ? <div className="flex justify-end order-2 sm:order-3"><LoadingRequest /></div>
                :
                <button className="p-1 my-2 rounded-lg bg-gray-700 font-semibold text-slate-200 order-2 sm:order-3">
                    <p className="hidden sm:block">Comentar</p>
                    <div className="sm:hidden">
                        <AddCommentRoundedIcon />
                    </div>
                </button>
                }
            </form>

            {hasError && (
                <ErrorMessage 
                    errorMessage={errorMessage}/>
            )}

            <div className="my-4 p-0 sm:p-2 w-full rounded-md overflow-auto max-h-[600px]">
            { comentarios.length > 0
                       ? <table className="w-full border border-gray-200 rounded-md overflow-hidden">
                            <thead className="font-light bg-gray-700 text-slate-200">
                                <tr className="font-light">
                                    <th className="w-1/5">Fecha</th>
                                    <th className="w-1/5">Responsable</th>
                                    <th className="w-2/5">Comentario</th>
                                    <th className="w-fit"></th>
                                </tr>
                            </thead>

                            <tbody className="text-md ">

                            {comentarios.map((comentario, index) => {
                            return (
                                <tr key={index} className="text-center font-normal border border-gray-300 h-28">
                                    <td>{comentario.fecha}</td>
                                    <td>{comentario.userName}</td>
                                    <td className="text-left">{comentario.texto}</td>
                                    <td >
                                        {comentario.archivo !== null && (
                                            <ImagePreview 
                                                urlImage={`${comentario.archivo}`}/>
                                        )}
                                    </td>
                                </tr>
                            )
}
                            )}

                            </tbody>
                        </table>
                : <div className="h-20 w-full flex justify-center m-4">
                    <p className="font-medium">Sin Comentarios</p>
                </div> 
            }
            </div>
        </div>
    );
}