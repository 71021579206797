import { TipoUsuarioModel, UsuarioCreate, UsuarioModel } from "../types"; 
import { BaseService } from "./BaseService";


export class UserServices extends BaseService {

    /**
     * @param { string } token - Auth token for getting access to API Backend.
     */
    constructor(token: string) {
        super(token, "/usuario");
    }
    
    /**
     * Save a new User in data base.
     * 
     * @param { UsuarioCreate } data - New user data. 
     * @returns 
     */
    public async saveUser(data: UsuarioCreate) {
        const response = await this.axios.post("", data);
        return response;
    }

    /**
     * Get a user by its id.
     * 
     * @param { string | undefined } id - User's id.
     * @returns { Promise<UsuarioModel> } - User data.
     */
    public async getUserById(id: string | undefined): Promise<UsuarioModel> {
        const response = await this.axios.get<UsuarioModel>(`/${id}`)
        const { data } = response;
        return data;
    }

    /**
     * Get list of users.
     * 
     * @returns { Promise<Array<UsuarioModel>> } - List of users. 
     */
    public async getUsers(): Promise<Array<UsuarioModel>> {
        const response = await this.axios.get<Array<UsuarioModel>>("")
        const { data } = response;
        return data;
    }

    /**
     * Get list of user types.
     * 
     * @returns { Promise<Array<TipoUsuarioModel>> } - List of user types.
     */
    public async getUserTypes(): Promise<Array<TipoUsuarioModel>> {
        const response = await this.axios.get<Array<TipoUsuarioModel>>(`/types`)
        const { data } = response;
        return data;
    }

    /**
     * Update user data.
     * 
     * @param { UsuarioModel } data - Updated data user.
     * @returns 
     */
    public async updateUser(data: UsuarioModel) {
        const response = await this.axios.put(`/${data.id}`, data);
        return response;
    }

    /**
     * Deactivate a user.
     * 
     * @param { string | undefined } id - User's id. 
     * @returns 
     */
    public async deactivateUser(id: string | undefined) {
        const response = await this.axios.delete(`/${id}`);
        return response;
    }

    /**
     * Get a user type by its id.
     * 
     * @param { string | undefined } id - User type id.
     * @returns { Promise<TipoUsuarioModel> } - User type data.
     */
    public async getUserTypeById(id: string | undefined): Promise<TipoUsuarioModel> {
        const response = await this.axios.get<TipoUsuarioModel>(`/types/${id}`)
        const { data } = response;
        return data;
    }

    /**
     * Save a new user type in data base.
     * 
     * @param { TipoUsuarioModel } newUserType - New user type data.
     * @returns 
     */
    public async saveUserType({ tipo }: TipoUsuarioModel) {
        const response = await this.axios.post(`/types`, { tipo });
        return response;
    }

    /**
     * Update user type data.
     * 
     * @param { TipoUsuarioModel } updatedUserType - Updated user type data.
     * @returns 
     */
    public async updateUserType({ id, tipo }:TipoUsuarioModel) {
        const response = await this.axios.put(`/types/${id}`, { tipo: tipo });
        return response;
    }

    /**
     * Delete a user type.
     * 
     * @param { string | undefined } id - User type id.
     * @returns 
     */
    public async deleteUserType(id: string | undefined) {
        const response = await this.axios.delete(`/types/${id}`);
        return response;
    }

    /**
     * Get list of user by role filter.
     * 
     * @param { string } role - User role.
     * @returns { Promise<Array<UsuarioModel>> } - List of Users. 
     */
    public async getUsersByRole(role: string): Promise<Array<UsuarioModel>> {
        const response = await this.axios.get<Array<UsuarioModel>>(`/role?role=${role}`)
        const { data } = response;
        return data;
    }
}