import DeleteIcon from '@mui/icons-material/Delete';

import { useState } from "react";

interface Props {
    deleteTarea: (tareaId: number, index: number) => Promise<void>;
    tareaId: number;
    index: number;
}

export function DeleteTarea({ deleteTarea, tareaId, index }: Props) {

    const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);

    return (
        <>
        <div 
            className="bg-[#C41C1C] hover:bg-[#a51818] w-[28px] h-full flex justify-center items-center rounded-md cursor-pointer"
            onClick={() => setConfirmationDelete(true)}>
            <DeleteIcon sx={{ "color": "white", "width": "20px", "height": "20px" }}/>

        </div>

        <div className="relative">
            {confirmationDelete && (
            <div className="absolute left-full ml-2 bg-white p-4 rounded-lg shadow-lg z-50 w-80">
                <p className="mb-2 font-medium col-span-2">¿Seguro que quiere eliminar la tarea?</p>
                <p className="mb-2 font-medium text-sm col-span-2">(El historial de revisiones se conservará)</p>

                <div className="flex justify-between col-span-2 gap-2 mt-4">
                    <button 
                        type="button"
                        className="py-1 text-sm font-medium bg-red-700 text-gray-50 w-20 rounded-md disabled:bg-red-300"
                        onClick={() => {
                            deleteTarea(tareaId, index)
                            setConfirmationDelete(false)
                        }}>
                        Eliminar
                    </button>
                    <button 
                        className="py-1 text-sm font-medium bg-gray-700 text-gray-50 w-20 rounded-md"
                        onClick={() => setConfirmationDelete(false)}>
                        Cancelar
                    </button>
                </div>

            </div>
            )}

        </div>
        </>
    );

}