import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { useUser } from "../../hooks/useUser";
import { SucursalServices, SubcategoryServices, CategoriaServices } from "../../services";
import { CategoryModel, FiltrosModel, SubcategoryModel, SucursalModel } from "../../types";
import { useForm } from "react-hook-form";

interface Props {
    filtros: FiltrosModel
    setFiltros: Dispatch<SetStateAction<FiltrosModel>>
}

export function EquipoFilters({ filtros, setFiltros }: Props) {
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const { register, setValue } = useForm<FiltrosModel>({ defaultValues: {...filtros}})

    const sucursalService = new SucursalServices(token);
    const categoryService = new CategoriaServices(token);

    const [sucursales, setSucursales] = useState<Array<SucursalModel>>([]);
    const [categories, setCategories] = useState<Array<CategoryModel>>([]);
    const [subcategorias, setSubcategorias] = useState<Array<SubcategoryModel>>([]);
    const [dataLoading, setDataLoading] = useState<boolean>(true);

    const onFilterChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFiltros({
            ...filtros,
            [event.target.name]: event.target.value
        });

        if(event.target.name == "categoriaId") {

            const value  = parseInt(event.target.value);

            if(value != 0) {
                const subcategories = categories.find(c => c.id == value)?.subcategoria;
                setSubcategorias(subcategories!);
                setValue("subcategoriaId", 0)
                setFiltros({
                    ...filtros,
                    "categoriaId": value,
                    "subcategoriaId": 0
                });
            }
        }
    }

    useEffect(() => {
        const sucursales = sucursalService.getSucursales()
            .then(data => setSucursales(data));

        const categories = categoryService.getCategories()
            .then(data => {
                setCategories(data)
                setSubcategorias(data.find(c => c.id == filtros.categoriaId)?.subcategoria!);
            });
        
        Promise.all([sucursales, categories])
            .then(_ => setDataLoading(false));
    }, []);

    return (
        <div className="w-full p-2">
            <form className="grid grid-cols-4 w-full gap-y-3 sm:gap-y-2 gap-x-3 text-left">

                    {!dataLoading && (

                    <>

                    <div className="col-span-2 flex flex-col sm:flex-row">
                        <label className="w-full sm:w-1/3" htmlFor="fechaInicio">Sucursal:</label>
                        <select 
                            {...register("sucursalId")}
                            className="selectfilter w-11/12 sm:w-2/3"
                            name="sucursalId"
                            onChange={onFilterChange}>
                                <option value={0}>Todas las Sucursales</option>
                                {sucursales.map((sucursal, index) => (
                                 <option key={index} value={sucursal.id}>{sucursal.smallDir}</option>
                                ))
                                }
                        </select>
                    </div>

                    <div className="col-span-2 flex flex-col sm:flex-row">
                        <label className="w-full sm:w-1/3" htmlFor="fechaInicio">Categoría:</label>
                        <select 
                            {...register("categoriaId")}
                            className="selectfilter w-11/12 sm:w-2/3"
                            name="categoriaId"
                            onChange={onFilterChange}>
                                <option value={0}>Todas las Categorías</option>
                                {categories.map((category, index) => (
                                <option key={index} value={category.id}>{category.nombreCategoria}</option>
                                ))
                                }
                        </select>
                    </div>

                    <div className="col-span-2 flex flex-col sm:flex-row">
                        <label className="w-full sm:w-1/3" htmlFor="fechaInicio">Subcategoría:</label>
                        <select 
                            {...register("subcategoriaId")}
                            className="selectfilter w-11/12 sm:w-2/3"
                            name="subcategoriaId"
                            onChange={onFilterChange}>
                                <option value={0}>Todas las Subcategorías</option>
                                {subcategorias.map((subcategoria, index) => (
                                 <option key={index} value={subcategoria.id}>{subcategoria.nombreSubcategoria}</option>
                                ))
                                }
                        </select>
                    </div>
</>
                    )
                    }
            </form>
        </div>
    )
}