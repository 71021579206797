import { useState, useEffect, ChangeEvent } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CategoriaServices } from "../../services/categorias";
import { SucursalModel, CategoryModel, EquipoRequest, CodePrefixResponse } from "../../types";
import { Button } from "@mui/joy";
import { useUser } from "../../hooks/useUser";
import { EquipoServices, SucursalServices } from "../../services";
import { EquipoCodeParser } from "../../utilities/equipoCodeParser";
import { LoadingRequest, ErrorMessage } from "../UtilsComponents";
import { errorParser } from "../../utilities";

export function EquipoCreateForm() {

    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [requestLoading, setRequestLoading] = useState<boolean>(false);
    const [sucursales, setSucursales] = useState<Array<SucursalModel>>([]);
    const [categories, setCategories] = useState<Array<CategoryModel>>([]);
    const [categorySelected, setCategorySelected] = useState<number | null>(null);
    const [codePrefixList, setCodePrefixList] = useState<Array<CodePrefixResponse>>([]);
    // data for equipo's code
    const [prefijo, setPrefijo] = useState<string>("");
    const [number, setNumber] = useState<string>("");

    const categoriaService = new CategoriaServices(token);
    const equipoService = new EquipoServices(token);
    const sucursalService = new SucursalServices(token);

    const selectStyle = "h-8 my-1 border font-normal text-sky-900 border-cyan-300 hover:border-cyan-900 rounded-lg bg-transparent";
    const navigate = useNavigate();

    const { 
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors }
    } = useForm<EquipoRequest>(); 

    // Manage category selected
    const handleChanges = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        if(event.target.name === "categoryId"){
            setCategorySelected(parseInt(event.target.value));
        }
    }

    // Manage changes in state for number-code and prefijo.
    const onCodeChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

        if(event.target.name === "number-code") {
            setNumber(event.target.value);
        }

        if(event.target.name === "prefijo") {
            setPrefijo(event.target.value);
        }

    }

    let CategorySelected: CategoryModel | undefined;
    if(categorySelected != null) {
        CategorySelected = categories.find((category) => category.id === categorySelected);
    }

    const submitSaveEquipo = handleSubmit(async (data: EquipoRequest) => {
        try {
            setHasError(false);
            setRequestLoading(true);

            const response = await equipoService.saveEquipo(data)

            if(response.status === 201) {
                setRequestLoading(false);
                navigate("/equipos");
            }

        } catch(error) {
            setRequestLoading(false);
            const message = errorParser(error);
            setErrorMessage(message);
            setHasError(true);
        }
    });

    useEffect(()=> {
        // Initialize dropdown data.
        sucursalService.getSucursales()
            .then(data => setSucursales(data));

        categoriaService.getCategories()
            .then(data => setCategories(data));
        
        equipoService.getCodePrefixList()
            .then(data => setCodePrefixList(data));
        
    }, []);

    useEffect(() => {
        // Toma el prefijo y el numero identificador para crear
        const code = EquipoCodeParser.parse(prefijo, number);

        if(code !== null) {
            const codeId = codePrefixList.find(code => code.prefijo == prefijo)?.id;
            setValue("codigo", code);

            if(codeId != null) {
                setValue("codePrefixId", codeId);
            }
        }

    }, [prefijo, number]);

    return (
        <div>
            <h2 className="text-3xl my-4 font-semibold">Registrar equipo</h2>
            <form onSubmit={submitSaveEquipo} className="grid grid-cols-2" autoComplete="off">


                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Nombre</label>
                    <input {...register("nombre", {
                            required: "El equipo debe tener un nombre",
                            maxLength: 50
                        })}
                        aria-invalid={errors.nombre ? "true" : "false"}
                        className="inputform"
                        placeholder="Nombre"/>
                    {errors.nombre?.type === "required" && (
                        <p className="valErrorText col-start-2" role="alert">{errors.nombre.message}</p>
                    )}
                    {errors.nombre?.type === "maxLength" && (
                        <p className="valErrorText col-start-2" role="alert">Maximo numero de caracteres es 50</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Numero de Serie</label>
                    <input {...register("numeroSerie", {
                                required: "El equipo debe tener un numero de serie",
                                maxLength: 100
                            })}
                        aria-invalid={errors.numeroSerie ? "true" : "false"}
                        className="inputform"
                        placeholder="Numero de Serie"/>
                    {errors.numeroSerie?.type === "required" && (
                        <p className="valErrorText col-start-2" role="alert">{errors.numeroSerie.message}</p>
                    )}
                    {errors.numeroSerie?.type === "maxLength" && (
                        <p className="valErrorText col-start-2" role="alert">Maximo numero de caracteres es 100</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Código</label>
                    <div className="grid grid-cols-3 gap-2">
                        <select
                            {...register("prefijo", {
                                validate: (value: string | undefined) => {
                                    console.log(prefijo.length)
                                    console.log(number.length)
                                    if(prefijo.length === 0 || number.length === 0)
                                        return false;

                                    return true;
                                }
                            })}
                            onChange={onCodeChange}
                            className="selectform col-span-2"
                            name="prefijo"
                            placeholder="Prefijo">
                            <option hidden value={""} defaultChecked></option>
                            {codePrefixList.map((code, index) => (
                                <option key={index} value={code.prefijo}>{`${code.prefijo} - ${code.significado}`}</option>
                            ))}
                        </select>
                        <input 
                            onChange={onCodeChange}
                            className="inputform col-span-1"
                            name="number-code"
                            type="text" />
                    </div>
                    {errors.prefijo?.type === "validate" && (
                        <p className="valErrorText col-start-2" role="alert">Tiene que proporcionar un codigo completo</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Sucursal</label>
                    <select {...register("sucursalId", {
                                required: "Debe seleccionar una sucursal"
                            })}
                            aria-invalid={errors.sucursalId ? "true" : "false"}
                            className={selectStyle}>
                        {sucursales.map((sucursal, index) => (
                            <option key={index} value={sucursal.id}>{sucursal.smallDir}</option>
                        ))}
                        <option hidden value={""} defaultChecked></option>
                    </select>
                    {errors.sucursalId?.type === "required" && (
                        <p className="valErrorText col-start-2" role="alert">{errors.sucursalId.message}</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" id="subcat_id">Categoria</label>
                    <select className={selectStyle}
                            onChange={handleChanges}
                            name="categoryId">
                            {categories.map((category, index) => (
                                <option key={index} value={category.id}>{category.nombreCategoria}</option>
                            ))}
                            <option hidden value={0} defaultChecked></option>
                    </select>
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium" id="subcat_id">Subcategoria</label>
                    <select {...register("subcategoriaId", {
                                required: "Debe seleccionar una subcategoría"
                            })}
                            aria-invalid={errors.subcategoriaId ? "true" : "false"}
                            className={selectStyle}>
                            {CategorySelected?.subcategoria?.map((subcategory, index) => (
                                    <option key={index} value={subcategory.id}>{subcategory.nombreSubcategoria}</option>
                            ))}
                        <option hidden value={""} defaultChecked></option>
                    </select>
                    {errors.subcategoriaId?.type === "required" && (
                        <p className="valErrorText col-start-2" role="alert">{errors.subcategoriaId.message}</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Departamento</label>
                    <input {...register("departamento", {
                            required: "El equipo debe tener un departamento",
                            maxLength: 30
                        })}
                        aria-invalid={errors.departamento ? "true" : "false"}
                        className="inputform"
                        placeholder="Departamento"/>
                    {errors.departamento?.type === "required" && (
                        <p className="valErrorText col-start-2" role="alert">{errors.departamento.message}</p>
                    )}
                    {errors.departamento?.type === "maxLength" && (
                        <p className="valErrorText col-start-2" role="alert">Maximo numero de caracteres es 30</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Frecuencia de Mantenimiento</label>
                    <input {...register("frecuenciaMan", {
                            //required: "Debe escribir la frecuencia de mantenimiento de equipo",
                            min: 1,
                            validate: (value: number) => {
                                if(value != null && value != 0) {
                                    const ultimoMan = watch("ultimoMan");
                                    if(ultimoMan == null || ultimoMan.length == 0)
                                        return false;
                                }

                                return true;
                            }
                        })}
                        aria-invalid={errors.departamento ? "true" : "false"}
                        className="inputform"
                        type="number"
                        placeholder="Dias"/>
                    {errors.frecuenciaMan?.type === "validate" && (
                        <p className="valErrorText col-start-2" role="alert">
                            Debe proporcionar valores para ambos campos: frecuencia de mantenimiento y último mantenimiento
                        </p>
                    )}
                    {errors.frecuenciaMan?.type === "min" && (
                        <p className="valErrorText col-start-2" role="alert">Número minimo de dias es 1 día</p>
                    )}
                </div>

                <div className="grid grid-cols-2 justify-between col-span-2">
                    <label className="font-medium">Fecha del Ultimo Mantenimiento</label>
                    <input {...register("ultimoMan", {
                            //required: "Debe la fecha del ultimo mantenimiento"
                            validate: (value: string) => {
                                if(value != null && value.length != 0) {
                                    const frecMan = watch("frecuenciaMan");
                                    if(frecMan == null || frecMan == 0)
                                        return false;
                                }

                                return true;
                            }
                        })}
                        aria-invalid={errors.departamento ? "true" : "false"}
                        className="inputform"
                        type="date"
                        placeholder="Dias"/>
                    {errors.ultimoMan?.type === "validate" && (
                        <p className="valErrorText col-start-2" role="alert">
                            Debe proporcionar valores para ambos campos: frecuencia de mantenimiento y último mantenimiento
                        </p>
                    )}
                </div>

                <div className="flex justify-end col-span-2 m-2 gap-2">
                    {requestLoading
                    ? <LoadingRequest />
                    : <Button size="sm" color="success" className="w-20" type="submit">Registrar</Button>
                    }
                    <Link to="/equipos">
                        <Button size="sm" color="neutral" className="w-20 mx-2">Cancelar</Button>
                    </Link>
                </div>

                {hasError && (
                    <ErrorMessage 
                        errorMessage={errorMessage} />
                )}

            </form>
        </div>
    );
}