import { useState, useEffect } from "react";
import { Table } from "@mui/joy";
import { FiltrosModel, ShowFiltros, SolicitudResponse } from "../../types";
import { useUser } from "../../hooks/useUser";
import { ComprasServices } from "../../services/compras";
import { EditButton } from "../../utilities/UtilsComponents/EditButton";
import { useLocation } from "react-router-dom";
import { colorEstadoSolicitudPreview, colorUrgenciaPreview } from "../../utilities/selectColorPreview";
import { formatDate } from "../../utilities/showData";
import { FiltroServices } from "../../services";
import { SearchFilter } from "../../utilities/UtilsComponents/SearchFilter";
import { Filtros } from "../UtilsComponents/Filtros";
import { EstadosSolicitud } from "../../utilities/StateEnums";
import { LoadingComponent } from "../UtilsComponents/LoadingComponent";
import { ElementsNotFound } from "../UtilsComponents";
import { TicketExportFilters } from "../../types/ticket";


interface Props {
    titulo: boolean
    onlyNonTerminados: boolean
    filtrosShowed: ShowFiltros
    previousURL?: string
}

export function SolicitudHome({onlyNonTerminados, filtrosShowed}: Props) {
    const location = useLocation();
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";
    const comprasServices = new ComprasServices(token);

    const initialDate = !onlyNonTerminados ? formatDate(new Date(new Date().setDate(new Date().getDate() - 7))) : ""; 
    const endDate = !onlyNonTerminados ? formatDate(new Date()) : "";

    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [solicitudesList, setSolicitudesList] = useState<Array<SolicitudResponse>>([])
    const [search, setSearch] = useState<string>(""); 
    const [filtros, setFiltros] = useState<FiltrosModel>({
        fechaInicial: initialDate,
        fechaFinal: endDate,
        categoriaId: 0,
        sucursalId: 0,
        estado: "",
        responsableId: 0,
        subcategoriaId: 0,
        urgencia: "",
        checklistId: 0,
    });


    const selectStatus = (statusValidation: boolean, estado: string): Array<string> => {
        let status = statusValidation ? "Aprobado" : "Pendiente";
        let color = statusValidation ? "bg-green-700" : "bg-amber-400"

        if(estado === EstadosSolicitud.Rechazado) {
            status = "Rechazado";
            color = "bg-red-700";
        }


        return [status, color];
    }

    let listSolicitudFilter: Array<SolicitudResponse> = [];

    useEffect(() => {
        if(onlyNonTerminados) {
            setFiltros({
                ...filtros,
                fechaInicial: undefined,
                fechaFinal: undefined,
            })
        }
    }, [])

    useEffect(() => {
        if(onlyNonTerminados) {
            comprasServices.getSolicitudes(filtros.fechaInicial, filtros.fechaFinal, true)
                .then(data => {
                    setSolicitudesList(data);
                    setDataLoading(false);
                });

        } else {
            comprasServices.getSolicitudes(filtros.fechaInicial, filtros.fechaFinal)
                .then(data => {
                    setSolicitudesList(data);
                    setDataLoading(false);
                });
        }
    }, [filtros.fechaInicial, filtros.fechaFinal]);

    const baseList: Array<SolicitudResponse> = onlyNonTerminados === false
        ? solicitudesList
        : solicitudesList.filter(solicitud => {
            return [
                EstadosSolicitud.Abierto,
                EstadosSolicitud.Aprobado,
                EstadosSolicitud.Proceso,
                EstadosSolicitud.Vencido,
            ].find(estado => estado === solicitud.estado);
        });

    const onClickExportCsv = async () => {
        try {
            const filters: TicketExportFilters = {
                fechaInicial: filtros.fechaInicial,
                fechaFinal: filtros.fechaFinal,
                categoriaId: filtros.categoriaId,
                sucursalId: filtros.sucursalId,
                responsableId: filtros.responsableId,
                urgencia: filtros.urgencia,
                estado: filtros.estado
            } ;

            const response = await comprasServices.exportSolicitudesData(filters);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `solicitudes-data.csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error descargando el archivo', error);
        }
    }

    listSolicitudFilter = FiltroServices.filtrosSolicitud(baseList, search, filtros)

    return (
        <div className="w-full">

            <SearchFilter searchValue={search} setSearchValue={setSearch}/>
            <Filtros 
                typeFilter={"Solicitud"}
                filtros={filtros} 
                setFiltros={setFiltros} 
                showFiltros={filtrosShowed}/>

            <button className={`w-fit h-[32px] bg-gray-900 text-white py-1 px-3 rounded-lg font-medium my-3`}
                onClick={onClickExportCsv}>
                Exportar solicitudes
            </button>

            {dataLoading
            ? <LoadingComponent />
            :
            <div className="overflow-auto max-h-[600px] rounded-lg border border-slate-200">
            <Table 
                stickyHeader
                sx={(theme) => ({
                    "th": {bgcolor: "#111827", color: "#ffffff"}
                })}>
                    <table className="w-full table-fixed">
                        <thead>
                            <tr>
                                <th className="w-12">Folio</th>
                                <th className="w-20">Prioridad</th>
                                <th className="w-28">Estado</th>
                                <th className="w-96">Titulo</th>
                                <th className="w-28">Responsable</th>
                                <th className="w-28">Sucursal</th>
                                <th className="w-40">Fecha de Creación</th>
                                <th className="w-40">Fecha de Vencimiento</th>
                                {["Admin", "General"].find(role => role === userContext.auth?.user?.tipoUsuario) && (
                                    <th className="w-24">Validación</th>
                                )}
                                <th className="w-24"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {listSolicitudFilter.map((solicitud, index) => {

                                const [status, color] = selectStatus(solicitud.aprobada, solicitud.estado);

                                return (
                                <tr key={index} className={`hover:bg-slate-100`}>
                                    <td>{solicitud.id}</td>
                                    <td>
                                        <div className="flex items-center">
                                            <div className={`${colorUrgenciaPreview(solicitud.urgencia)} w-2  h-2 rounded-full mr-1`}></div>
                                            <p className={`text-center w-2/4 rounded-lg font-semibold text-gray-900 opacity-85`}>{solicitud.prioridad}</p>
                                        </div>
                                    </td>
                                    <td className="text-center w-28">
                                        <span className={`px-2 ${colorEstadoSolicitudPreview(solicitud.estado)} rounded-xl text-center w-30`}>
                                            {solicitud.estado}
                                        </span>
                                    </td>
                                    <td>{solicitud.titulo}</td>
                                    <td>{solicitud.responsableName}</td>
                                    <td>{solicitud.sucursalName}</td>
                                    <td>{solicitud.fechaCreacion}</td>
                                    <td>{solicitud.fechaVencimiento}</td>
                                    {["Admin", "General"].find(role => role === userContext.auth?.user?.tipoUsuario) && (
                                        <td>
                                            <div className="flex items-center">
                                                <div className={`${color} w-2  h-2 rounded-full mr-1`}></div>
                                                
                                                <p className={`text-center w-3/4 rounded-lg font-semibold text-gray-900 opacity-85 text-xs`}>{status}</p>
                                            </div>
                                        </td>
                                    )}
                                    <td>
                                        <EditButton url={`/compras/${solicitud.id}`} previousUrl={location.pathname}/>
                                    </td>
                                </tr>
                            )})
                            }
                        </tbody>
                    </table>
            </Table>

            {listSolicitudFilter.length == 0 && (
                <ElementsNotFound />
            )}

            </div>
            }
        </div>
    );
}